import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import { Box, IconButton, LinearProgress, Typography } from "@mui/material";
import { AddCircleOutline, DeleteOutline } from "@mui/icons-material";
import { StyledTypographySmallText } from "./StyledTypography";
import { useDispatch } from "react-redux";
import { setErrorMessage, setMessage } from "../actions/message";
import fileService from "../services/file.service";

export const StyledDropzone = ({
  error = "",
  onChange = () => {},
  value = [],
}) => {
  const max_size_limit = 1024 * 1024 * 10;
  const dispatch = useDispatch();
  const [uploading, setUploading] = useState(false);
  const [percent, setPercent] = useState(0);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (acceptedFiles) => {
      const uniqueFiles = acceptedFiles.filter(
        (file) => !value.some((f) => f.name === file.name)
      );

      let i;

      for (i = 0; i < uniqueFiles.length; i++) {
        if (uniqueFiles[i].size > max_size_limit) {
          dispatch(
            setMessage({
              message: "La limite de taille de fichier est dépassée !",
              severity: "error",
            })
          );

          return;
        }
      }

      setUploading(true);
      setPercent(0);

      fileService
        .uploadFiles(
          { files: uniqueFiles, file_type: "files" },
          (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            console.log(percentCompleted);
            setPercent(percentCompleted);
          }
        )
        .then((res) => {
          if (res?.data?.urls?.files) {
            let updateFiles = [...value, ...res.data.urls.files];

            // setFiles(updateFiles);
            onChange(updateFiles);
            setUploading(false);
          }
        })
        .catch((err) => {
          dispatch(setErrorMessage(err));
          setUploading(false);
        });
    },
    maxSize: max_size_limit,
  });

  const handleRemoveFile = (filename) => {
    let updateFiles = value.filter((file) => file !== filename);
    // setFiles(updateFiles);
    onChange(updateFiles);
  };

  function formatFileSize(size) {
    if (size < 1024) {
      return size + " B";
    } else if (size < 1024 * 1024) {
      return (size / 1024).toFixed(2) + " KB";
    } else if (size < 1024 * 1024 * 1024) {
      return (size / (1024 * 1024)).toFixed(2) + " MB";
    } else {
      return (size / (1024 * 1024 * 1024)).toFixed(2) + " GB";
    }
  }

  return (
    <div>
      <Box
        {...getRootProps()}
        className="flex flex-col items-center justify-center"
        sx={{
          border: "2px dashed",
          borderColor: isDragActive ? "#2574A9" : "#4f7793",
          borderRadius: "8px",
          p: 2,
          textAlign: "center",
          cursor: "pointer",
          background: "white",
        }}
      >
        <input {...getInputProps()} />
        <AddCircleOutline
          sx={{ fontSize: 40, color: "#4f7793", paddingLeft: "5px" }}
        />
        <div className="mt-4 mb-2">
          {isDragActive ? (
            <StyledTypographySmallText>
              Déposez les fichiers ici
            </StyledTypographySmallText>
          ) : (
            <StyledTypographySmallText>
              Déposer un document
            </StyledTypographySmallText>
          )}
        </div>
        <StyledTypographySmallText sx={{ fontWeight: "300 !important" }}>
          Taille maximale: {formatFileSize(max_size_limit)}
        </StyledTypographySmallText>
        <Box className="w-full h-[5px] py-[2px]">
          {uploading && (
            <LinearProgress
              variant="determinate"
              sx={{
                backgroundColor: "#E6E8EC",
                "& .MuiLinearProgress-bar": {
                  backgroundColor: "#2574A9",
                },
              }}
              value={percent}
            />
          )}
        </Box>
      </Box>
      {value && value.length > 0 && (
        <Box
          sx={{
            mt: 2,
            border: "2px solid #E6E8EC",
            borderRadius: "8px",
            padding: "9px 13px",
            background: "white",
          }}
        >
          {/* <Typography variant="subtitle1">Uploaded Files:</Typography> */}
          <ul>
            {value.map((file, _i) => (
              <li key={_i} className="flex justify-between items-center">
                <StyledTypographySmallText>
                  {file.substring(1)}
                </StyledTypographySmallText>
                <IconButton onClick={() => handleRemoveFile(file)} size="small">
                  <DeleteOutline sx={{ color: "#2574a9" }} />
                </IconButton>
              </li>
            ))}
          </ul>
        </Box>
      )}
      <Typography sx={{ color: "#EB4C37" }}>{error}</Typography>
    </div>
  );
};
