import { SET_MESSAGE, CLEAR_MESSAGE, SET_NOTIFY } from "../actions/types";

const initialState = {};

export default function messageReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_MESSAGE:
      return {
        ...state,
        ...payload,
      };

    case CLEAR_MESSAGE:
      return { ...state, message: "" };
    case SET_NOTIFY:
      return { ...state, notify: payload };

    default:
      return state;
  }
}
