import axios from '../axios-orders';

class ClientService {
  getClientList(page = 1) {
    return axios.get(`/api/client-list?page=${page}`);
  }

  addClient(userData) {
    return axios.post('/api/client', userData);
  }

  updateClient(userData) {
    return axios.post(`/api/client-update/${userData.id}`, userData);
  }

  removeClient(userId) {
    return axios.delete(`/api/client/${userId}`);
  }

  getClient(userId) {
    return axios.get(`/api/client/${userId}`);
  }
}

const clientService = new ClientService();

export default clientService;
