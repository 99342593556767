import { Typography } from "@mui/material";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";

const StyledFooterContent = styled(Typography)`
  color: #4f7793;

  text-align: center;
  font-family: Segoe UI;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
const Footer = () => {
  const navigate = useNavigate();
  return (
    <div className="relative">
      <StyledFooterContent>
        Groupe AGEO © 2023 |&nbsp;
        <u className="cursor-pointer font-segoe hover:text-myerror">
          <a
            href="https://www.linkedin.com/company/ageo-assurances"
            target="_blank"
            rel="noreferrer"
          >
            LinkedIn
          </a>
        </u>
        <span>&nbsp;|&nbsp;</span>
        <u
          className="cursor-pointer font-segoe hover:text-myerror"
          sx={{ color: "#EB4C37" }}
          onClick={() => navigate("/legalinformation")}
        >
          Mentions légales
        </u>
        <span>&nbsp;|&nbsp;</span>
        <u
          className="cursor-pointer font-segoe hover:text-myerror"
          sx={{ color: "#EB4C37" }}
          onClick={() => navigate("/cookiepolicy")}
        >
          Politique d’utilisation des cookies
        </u>
        <span>&nbsp;|&nbsp;</span>
        <u
          className="cursor-pointer font-segoe hover:text-myerror"
          sx={{ color: "#EB4C37" }}
          onClick={() => navigate("/termsofuse")}
        >
          Politique de confidentialité
        </u>
      </StyledFooterContent>
    </div>
  );
};

export default Footer;
