import { Typography } from "@mui/material";
import styled from "@emotion/styled";

export const StyledTypographyTitle = styled(Typography)`
  color: #eb4c37;
  text-align: center;
  font-family: Segoe UI Light;
  font-size: 40px;
  font-style: normal;
  font-weight: 300;
  line-height: 50px;
`;
export const StyledTypographyInputLabel = styled(Typography)`
  color: #4f7793;

  font-family: Segoe UI Semibold;
  font-size: 18px;
  font-style: normal;
  line-height: normal;
  font-weight: 600;
`;

export const StyledTypographyContentText = styled(Typography)`
  color: #4f7793;

  font-family: Segoe UI Light;
  font-size: 20px;
  font-style: normal;
  line-height: normal;
  font-weight: 300;
`;

export const StyledTypographySidebarList = styled(Typography)`
  font-family: Segoe UI;
  font-size: 20px;
  font-style: normal;
  line-height: 70px;
  font-weight: 400;
`;

export const StyledTypographyClientList = styled(Typography)`
  color: #4f7793;

  font-feature-settings: "clig" off, "liga" off;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 46px; /* 558.857% */
  text-transform: uppercase;
`;

export const StyledTypographySmallText = styled(Typography)`
  color: #2574a9;

  font-family: Segoe UI Semibold;
  font-size: 16px;
  font-style: normal;
  line-height: normal;
  font-weight: 600;
`;
////////////////////////////////
export const StyledTypographyMenuText = styled(Typography)`
  text-align: center;
  font-family: Segoe UI;
  font-size: 20px;
  font-style: normal;
  line-height: normal;
  font-weight: 400;
`;
export const StyledTypographyButtonText = styled(Typography)`
  color: #fff;

  text-align: center;
  font-family: Segoe UI Bold;
  font-size: 16px;
  font-style: normal;
  line-height: normal;
  text-transform: uppercase;
  font-weight: 700;
`;
export const StyledTypographyAccordionSummary = styled(Typography)`
  color: #4f7793;

  font-family: Segoe UI Semibold;
  font-size: 18px;
  font-style: normal;
  line-height: normal;
  text-decoration-line: underline;
  font-weight: 600;
`;

export const StyledTypographyAccordionOption = styled(Typography)`
  color: #37434b;

  font-family: Segoe UI;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  font-weight: 400;
`;
export const StyledTypographyAccordionFilter = styled(Typography)`
  color: #2574a9;

  font-family: Segoe UI Semibold;
  font-size: 22px;
  font-style: normal;
  line-height: normal;
  width: 250px;
  font-weight: 600;
`;

export const StyledTypographyContent = styled(Typography)`
  font-family: Segoe UI;
  font-size: 20px;
  font-style: normal;
  line-height: normal;
  font-weight: 400;
`;

export const StyledTypographyAmount = styled(Typography)`
  text-align: center;
  font-family: Segoe UI Semibold;
  font-size: 50px;
  font-style: normal;
  line-height: normal;
  font-weight: 600;
`;

export const StyledTypographySubTitle = styled(Typography)`
  text-align: center;
  font-family: Segoe UI;
  font-size: 22px;
  font-style: normal;
  line-height: normal;
  font-weight: 400;
`;

export const StyledTypographyTableCellContent = styled(Typography)`
  color: #37434b;

  text-align: center;
  font-family: Segoe UI;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const StyledTypographyTableHeaderContent = styled(Typography)`
  color: #2574a9;

  text-align: center;
  font-family: Segoe UI Bold;
  font-size: 20px;
  font-style: normal;
  line-height: normal;
  font-weight: 700;
`;

export const StyledTypographyDashboardInfo = styled(Typography)`
  color: #7d7d7d;
  font-family: Century Gothic;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.16px;
`;

export const StyledTypographyModifiedPercentage = styled(Typography)`
  color: #37434b;
  font-family: Century Gothic;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.12px;
`;

export const StyledTypographyDashboardAmount = styled(Typography)`
  color: #2574a9;
  font-family: Century Gothic;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.28px;
`;

export const StyledShipmentTitle = styled(Typography)`
  color: #37434b;
  font-family: Century Gothic;
  font-size: 22.402px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.224px;
`;

export const StyledShipmentTypePercent = styled(Typography)`
  color: #2574a9;

  text-align: right;
  font-family: Century Gothic;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.18px;
`;

export const StyledTypographyStatisticsTableBody = styled(Typography)`
  color: #37434b;

  text-align: center;
  font-family: Century Gothic;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

export const StyledTypographyStatisticsTableHead = styled(Typography)`
  color: #949494;
  text-align: center;
  font-family: Century Gothic;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.16px;
`;
