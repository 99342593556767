import React from "react";

import Footer from "../layout/Footer";

const WelcomeTemplate = ({ displayScroll = false, children }) => {
  return (
    <div className="flex flex-row w-full">
      <div
        className={`flex-1 h-full bg-originBack`}
      >
        <div className="min-h-[90vh] flex flex-col items-center">
          {children}
        </div>

        <div className="min-h-[10vh] flex flex-row justify-center items-center">
          <Footer />
        </div>
      </div>
      <div className="flex-1 h-full hidden lg:flex flex-col">
        <div className="lg:flex flex-col w-[50vw] h-full fixed bg-droiteBack overflow-hidden">
          <div className="flex w-full flex-col">
            <div className="text-paperColor text-[32px] font-segoeLight w-full text-center mt-[61px] leading-[24px]">
              Pour une transition emploi retraite réussie,
            </div>
            <div className="text-paperColor text-[32px] font-segoeSemi w-full text-center mb-[42px] leading-[40px]">
              faites confiance à AGEO Assurances !
            </div>
          </div>
          <div className="flex-grow relative flex justify-center">
            <div className="h-[100%] aspect-[960/835] relative">
              <div
                className="h-[100%] aspect-[1252/852] absolute"
                style={{
                  backgroundImage: "url(/Connexion_002.png)",
                  backgroundPosition: "center",
                  backgroundSize: "auto 100%",
                  backgroundRepeat: "no-repeat",
                  zIndex: 10,
                }}
              ></div>
              <div
                className="h-[111%] aspect-[742/928] absolute top-[-7.2%] left-[-5.79%]"
                style={{
                  backgroundImage: "url(/Connexion_001.png)",
                  backgroundPosition: "center",
                  backgroundSize: "auto 100%",
                  backgroundRepeat: "no-repeat",
                  zIndex: 9,
                }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WelcomeTemplate;
