import React from "react";
import {
  StyledTypographyInputLabel,
  StyledTypographyTitle,
} from "../../Styled/StyledTypography";
import { ReturnToIcon } from "../../utilities/icons/ReturnToIcon";
import { StyledInputTextField } from "../../Styled/StyledInput";
import { StyledButton } from "../../Styled/StyledButton";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import clientService from "../../services/client.service";
import { useDispatch } from "react-redux";
import { setErrorMessage, setSuccessMessage } from "../../actions/message";
import { StyledCompanyLogoPicker } from "../../Styled/StyledCompanyLogoPicker";
import AdminTemplate from "../../components/AdminTemplate";

const schema = yup.object().shape({
  companyName: yup.string().required("Le nom de l'entreprise est requis"),
  companyCode: yup.string().required("Le code de l'entreprise est requis"),
  address: yup.string().required("L'adresse est requise"),
});

export const AddClientInfo = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const { companyName } = watch();

  const onSubmit = (data) => {
    const clientInfo = {
      ...data,
    };
    clientService
      .addClient(clientInfo)
      .then((res) => {
        dispatch(setSuccessMessage(res));

        navigate("/manage-client");
      })
      .catch((err) => {
        dispatch(setErrorMessage(err));
      });
  };

  return (
    <AdminTemplate active={2}>
      <div className="h-[63px]"></div>
      <div className="px-[5vw]">
        <StyledTypographyTitle
          sx={{
            fontWeight: "300 !important",
            display: "flex",
            flexWrap: "wrap",
            textAlign: "center",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Fiche&nbsp;:&nbsp;
          <span className="font-segoeSemi">{companyName?.toUpperCase()}</span>
        </StyledTypographyTitle>
      </div>
      <div
        className="flex justify-center items-center hover:cursor-pointer mt-[80px]"
        onClick={() => navigate("/manage-client")}
      >
        <ReturnToIcon />
        <StyledTypographyInputLabel
          sx={{
            color: "#2574A9 !important",
            fontWeight: "700 !important",
            paddingLeft: "5px",
          }}
        >
          Retour au listing
        </StyledTypographyInputLabel>
      </div>
      <div className={`w-full mt-[52px] max-w-[640px] p-[20px] m-auto`}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex justify-center">
            <Controller
              name="avatar"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <StyledCompanyLogoPicker
                  id="avatar"
                  onChange={field.onChange}
                  value={field.value}
                />
              )}
            />
          </div>
          <div className="flex flex-col space-y-4">
            <Controller
              name="companyName"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <StyledInputTextField
                  id="companyName"
                  label="Nom de l’entreprise :"
                  fontWeight={700}
                  color="#2574A9"
                  value={field.value}
                  onChange={field.onChange}
                  error={errors.companyName?.message}
                />
              )}
            />
            <Controller
              name="address"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <StyledInputTextField
                  id="address"
                  label="Adresse :"
                  fontWeight={700}
                  color="#2574A9"
                  value={field.value}
                  onChange={field.onChange}
                  error={errors.address?.message}
                />
              )}
            />
            <div className="w-[50%]">
              <Controller
                name="companyCode"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <StyledInputTextField
                    id="companyCode"
                    label="Code entreprise :"
                    placeholder="Votre code entreprise"
                    fontWeight={700}
                    color="#2574A9"
                    value={field.value}
                    onChange={field.onChange}
                    error={errors.companyCode?.message}
                  />
                )}
              />
            </div>
          </div>

          <div className="mt-[5.4vh] mb-[9vh] mx-auto w-full sm:w-[318px]">
            <StyledButton type="submit">MODIFIER MES INFORMATIONS</StyledButton>
          </div>
        </form>
      </div>
    </AdminTemplate>
  );
};
