import { useEffect, useState } from "react";
import { StyledDashboardItemBox } from "../../Styled/StyledBox";
import { StyledTypographyTitle } from "../../Styled/StyledTypography";
import { useNavigate } from "react-router-dom";
import AdminTemplate from "../../components/AdminTemplate";

export const RetirementDashboard = (props) => {
  const [scrWidth, setScrWidth] = useState(window.innerWidth);
  const navigate = useNavigate();
  useEffect(() => {
    const handleResize = () => {
      setScrWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <AdminTemplate active={1}>
      <div className="h-[63px]"></div>
      <div className="px-[5vw]">
        <StyledTypographyTitle
          sx={{
            fontWeight: "300 !important",
            display: "flex",
            flexWrap: "wrap",
            textAlign: "center",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Tableau de bord&nbsp;
          <span className="font-segoeSemi">AGEO Retraite</span>
        </StyledTypographyTitle>
      </div>
      <div
        className={`flex justify-center gap-x-[5.2vw] gap-y-[2.6vh] flex-wrap mt-[71px] ${
          scrWidth > 1700 ? "px-[15vw]" : "px-[5vw]"
        }`}
      >
        <StyledDashboardItemBox
          width={scrWidth < 640 ? "90%" : "350px"}
          content="Listing compte Clients"
          onClick={() => navigate("/manage-client")}
        />
        <StyledDashboardItemBox
          width={scrWidth < 640 ? "90%" : "350px"}
          content="Ajouter un client"
          onClick={() => navigate("/manage-client/add-client")}
        />
        <StyledDashboardItemBox
          width={scrWidth < 640 ? "90%" : "350px"}
          content="Listing compte AGEO"
          onClick={() => navigate("/manage-admin")}
        />
        <StyledDashboardItemBox
          width={scrWidth < 640 ? "90%" : "350px"}
          content="Ajouter un admin AGEO"
          onClick={() => navigate("/manage-admin/add-super-admin")}
        />
        <StyledDashboardItemBox
          width={scrWidth < 640 ? "90%" : "350px"}
          content="Mon profil"
          onClick={() => navigate("/my-profile")}
        />
        <StyledDashboardItemBox
          width={scrWidth < 640 ? "90%" : "350px"}
          content="Se déconnecter"
          onClick={() => navigate("/logout")}
        />
      </div>
    </AdminTemplate>
  );
};
