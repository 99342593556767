import React from "react";
import Footer from "../layout/Footer";
import Header from "../layout/Header";

const UserTemplate = (props) => {
  return (
    <div className="bg-originBack w-full h-[100vh] customScroll">
      <div className="h-[100px]">
        <Header active={props.active}/>
      </div>
      <div
        className="relative"
        style={{ minHeight: "calc(100vh - 200px)" }}
      >
        {props.children}
      </div>
      <div className="min-h-[100px] flex items-center justify-center">
        <Footer />
      </div>
    </div>
  );
};

export default UserTemplate;
