import { KeyboardArrowDown } from "@mui/icons-material";
import {
  Autocomplete,
  MenuItem,
  Paper,
  Select,
  TextField,
} from "@mui/material";
import {
  StyledTypographySmallText,
  StyledTypographyInputLabel,
} from "./StyledTypography";

export const StyledSelect = ({
  placeholder = "",
  options = [],
  label,
  error = "",
  value,
  onChange,
  ...fields
}) => {
  return (
    <div className="w-full flex flex-col space-y-2">
      {label === "" ? (
        []
      ) : (
        <StyledTypographyInputLabel
          sx={{
            textAlign: "left",
            color: error === "" ? "#4f7793 !important" : "#EB4C37 !important",
          }}
        >
          {label}
        </StyledTypographyInputLabel>
      )}
      <Select
        displayEmpty
        IconComponent={KeyboardArrowDown}
        MenuProps={{
          sx: {
            "& .MuiList-root.MuiMenu-list": {
              paddingTop: 0,
              paddingBottom: 0,
            },
            "& .MuiButtonBase-root.MuiMenuItem-root": {
              minHeight: "60px !important",
              fontSize: "20px !important",
            },
          },
        }}
        sx={{
          "& .MuiTypography-root": {
            color: "#2574A9",
            fontSize: "18px",
            fontFamily: "Segoe UI Bold",
            overflow: "hidden",
          },
          "& .MuiInputBase-input.MuiOutlinedInput-input": {
            paddingLeft: "20px",
          },
          "& .MuiSvgIcon-root.MuiSelect-icon": {
            fill: error === "" ? "#2574A9 !important" : "#EB4C37 !important",
          },
          "& .MuiFormControl-root.MuiFormLabel": {
            color: "black !important",
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            border:
              error === "" ? "2px solid #e6e8ec" : "2px solid red !important",
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            border:
              error === "" ? "2px solid #e6e8ec" : "2px solid red !important",
          },
          "& .MuiOutlinedInput-notchedOutline": {
            border:
              error === ""
                ? "2px solid #e6e8ec"
                : "2px solid #EB4C37 !important",
            borderRadius: "8px",
          },
          "& .MuiInputBase-input.MuiOutlinedInput-input.MuiSelect-select": {
            background: "#fff",
          },
          "& .MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input":
            {},
        }}
        value={value}
        onChange={onChange}
        error={!!error}
        {...fields}
      >
        <MenuItem value="">
          <StyledTypographySmallText
            sx={{
              fontFamily: "Segoe UI Light !important",
              fontWeight: "300 !important",
              color: error === "" ? "#4f7793 !important" : "#EB4C37 !important",
            }}
          >
            {placeholder}
          </StyledTypographySmallText>
        </MenuItem>
        {options.map((opt, index) => {
          if (opt.label === undefined) {
            return (
              <MenuItem key={index} value={opt}>
                <StyledTypographySmallText>{opt}</StyledTypographySmallText>
              </MenuItem>
            );
          } else {
            return (
              <MenuItem key={index} value={opt.value}>
                <StyledTypographySmallText>
                  {opt.label}
                </StyledTypographySmallText>
              </MenuItem>
            );
          }
        })}
      </Select>
    </div>
  );
};

export const StyledAutoComplete = ({
  value,
  onChange,
  options,
  error = "",
  placeholder = "",
  label,
}) => {
  return (
    <div className="w-full flex flex-col space-y-2">
      <StyledTypographyInputLabel
        sx={{
          textAlign: "left",
          color: error === "" ? "#4f7793 !important" : "#EB4C37 !important",
        }}
      >
        {label}
      </StyledTypographyInputLabel>
      <Autocomplete
        popupIcon={<KeyboardArrowDown />}
        disableClearable
        onKeyDown={(e) => {
          if (e.target.value.length > 10 && e.key === "Enter") {
            e.defaultMuiPrevented = true;
          }
        }}
        disablePortal
        id="combo-box-demo"
        options={[{ label: placeholder, value: "" }, ...options]}
        isOptionEqualToValue={(option, value) => option.value === value}
        value={value}
        onChange={(e, option) => {
          onChange(option.value);
        }}
        PaperComponent={(props) => (
          <Paper
            sx={{
              fontSize: "25px",
              paddingTop: 0,
              paddingBottom: 0,
              color: error === "" ? "#2574A9 !important" : "#EB4C37 !important",
            }}
            {...props}
          />
        )}
        sx={{
          "& .MuiFormLabel-root.MuiInputLabel-root": {
            color: error === "" ? "#4f7793 !important" : "#EB4C37 !important",
          },
          "& .MuiInputBase-input.MuiOutlinedInput-input": {
            color: error === "" ? "#4f7793 !important" : "#EB4C37 !important",
          },
          "& .MuiOutlinedInput-notchedOutline": {
            border:
              error === ""
                ? "2px solid #e6e8ec !important"
                : "2px solid red !important",
            borderRadius: "8px",
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            border:
              error === ""
                ? "2px solid #e6e8ec !important"
                : "2px solid red !important",
            borderRadius: "8px",
          },
          "&:Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: "2px red solid !important",
            borderRadius: "8px",
            outline: "transparent !important",
          },
          "& .MuiInputBase-root": {
            background: "white !important",
            borderRadius: "10px",
          },
          "& .MuiSvgIcon-root": {
            fill: error === "" ? "#2574A9 !important" : "#EB4C37 !important",
          },
        }}
        renderInput={(params) => (
          <TextField {...params} placeholder={placeholder} />
        )}
      >
        {label}
      </Autocomplete>
    </div>
  );
};
