import React, { useEffect, useState } from "react";
import { StyledTypographyTitle } from "../../Styled/StyledTypography";
import { Step1 } from "./Step1";
import { StepButton } from "./StepButton";
import { Step2 } from "./Step2";
import { Step3 } from "./Step3";
import { Step4 } from "./Step4";
import { Step5 } from "./Step5";
import { Step6 } from "./Step6";
import { Step7 } from "./Step7";
import userService from "../../services/user.service";
import { useDispatch } from "react-redux";
import { setErrorMessage, setSuccessMessage } from "../../actions/message";
import { useNavigate } from "react-router-dom";

import { Box, Typography, Modal } from "@mui/material";
import { StyledButton } from "../../Styled/StyledButton";
import { Loading } from "../Loading";
import UserTemplate from "../../components/UserTemplate";

export const EmploymentForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dataFromServer, setDataFromServer] = useState(null);
  const [dataFromStorage, setDataFromStorage] = useState(null);
  const [openSelectData, setOpenSelectData] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    clearLocalStorage();
    navigate(`/`);
  };

  const [form1, setForm1] = useState({});
  const [form2, setForm2] = useState({});
  const [form3, setForm3] = useState({});
  const [form4, setForm4] = useState({});
  const [form5, setForm5] = useState({});
  const [form6, setForm6] = useState({});
  const [form7, setForm7] = useState({});

  const [step, setStep] = useState(1);

  const goToPrevStep = (data) => {
    if (step === 1) {
      setForm1(data);
      setStep(1);
    } else if (step === 2) {
      setForm2(data);
      setStep(1);
    } else if (step === 3) {
      setForm3(data);
      setStep(2);
    } else if (step === 4) {
      setForm4(data);
      setStep(3);
    }
    setStep(step - 1);
  };

  const saveToLocalStorage = (data) => {
    sessionStorage.setItem("employmentData", JSON.stringify(data));
  };

  const clearLocalStorage = () => {
    sessionStorage.removeItem("employmentData");
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await userService.getEmploymentForm();
        setDataFromServer(res.data.userData);
        setOpenSelectData(true);
      } catch (err) {
        // Handle error if needed
      }

      let formData = sessionStorage.getItem("employmentData");
      if (!!formData) {
        setDataFromStorage(formData);
        setOpenSelectData(true);
      }

      setLoading(false);
    };

    setLoading(true);
    fetchData();

    return () => {};
  }, []);

  const loadDataFromString = (formData) => {
    let data = JSON.parse(formData);
    setForm1(data.form1);
    setForm2(data.form2);
    setForm3(data.form3);
    setForm4(data.form4);
    setForm5(data.form5);
    setForm6(data.form6);
    setForm7(data.form7);
    setStep(data.step || 1);
  };

  const onSubmit = (data) => {
    let finalData = {
      form1,
      form2,
      form3,
      form4,
      form5,
      form6,
      form7,
    };

    if (step === 1) {
      setForm1(data);
      setStep(2);
      finalData.form1 = { ...data };
    } else if (step === 2) {
      setForm2(data);
      setStep(3);
      finalData.form2 = { ...data };
    } else if (step === 3) {
      setForm3(data);
      setStep(4);
      finalData.form3 = { ...data };
    } else if (step === 4) {
      setForm4(data);
      setStep(5);
      finalData.form4 = { ...data };
    } else if (step === 5) {
      setForm5(data);
      setStep(6);
      finalData.form5 = { ...data };
    } else if (step === 6) {
      setForm6(data);
      setStep(7);
      finalData.form6 = { ...data };
    } else if (step === 7) {
      setForm7(data);
      finalData.form7 = { ...data };

      userService
        .addEmploymentForm(finalData)
        .then((res) => {
          dispatch(setSuccessMessage(res));

          handleOpen();
        })
        .catch((err) => {
          dispatch(setErrorMessage(err));
        });
    }

    finalData.step = step;

    saveToLocalStorage(finalData);
  };

  if (loading) {
    return <Loading comment="Chargement des données d'emploi..."></Loading>;
  }

  return (
    <UserTemplate active={2}>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
            bgcolor: "white",
            boxShadow: 24,
            p: 4,
            outline: "none",
          }}
        >
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ color: "#2574A9", fontWeight: 700 }}
          >
            Formulaire complété
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2, mb: 10, color: "#2574A9", fontWeight: 400 }}
          >
            Nous avons bien enregistré vos informations.
          </Typography>

          <StyledButton type="button" onClick={handleClose}>
            CONTINUER
          </StyledButton>
        </Box>
      </Modal>

      <Modal
        open={openSelectData}
        onClose={() => setOpenSelectData(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-[500px] shadow-xl p-[30px] outline-none flex flex-col bg-paperColor items-center rounded-[10px]">
          <div className="mt-[20px] font-segoeLight text-[32px] text-ageoTitle">
            Sélectionner les données
          </div>
          <div className="mt-[43px] w-full">
            <StyledButton
              onClick={() => {
                loadDataFromString(dataFromServer);
                setOpenSelectData(false);
              }}
              disabled={!dataFromServer}
            >
              Utiliser dernière soumission
            </StyledButton>
          </div>
          <div className="mt-[43px] w-full">
            <StyledButton
              onClick={() => {
                loadDataFromString(dataFromStorage);
                setOpenSelectData(false);
              }}
              disabled={!dataFromStorage}
            >
              Reprendre ma demande
            </StyledButton>
          </div>
          <div className="mt-[43px] w-full">
            <StyledButton
              onClick={() => {
                setOpenSelectData(false);
              }}
            >
              Faire une nouvelle demande
            </StyledButton>
          </div>
        </div>
      </Modal>

      <div className="relative w-full flex justify-center" id="content">
        <div
          className="relative w-full justify-center px-[10vw] sm:px-0 sm:mx-[16vw]"
          style={{ maxWidth: `${step !== 2 ? 658 : 758}px` }}
        >
          <div className="h-[67px]"></div>
          <div className="mb-[70px]">
            <StyledTypographyTitle sx={{ textAlign: "center !important" }}>
              Formulaire d’étude transition emploi retraite
            </StyledTypographyTitle>
          </div>

          {step === 1 && (
            <Step1
              goToPrevStep={goToPrevStep}
              onSubmit={onSubmit}
              data={form1}
            />
          )}
          {step === 2 && (
            <Step2
              goToPrevStep={goToPrevStep}
              onSubmit={onSubmit}
              data={form2}
            />
          )}
          {step === 3 && (
            <Step3
              goToPrevStep={goToPrevStep}
              onSubmit={onSubmit}
              data={form3}
            />
          )}
          {step === 4 && (
            <Step4
              goToPrevStep={goToPrevStep}
              onSubmit={onSubmit}
              data={form4}
            />
          )}
          {step === 5 && (
            <Step5
              goToPrevStep={goToPrevStep}
              onSubmit={onSubmit}
              data={form5}
            />
          )}
          {step === 6 && (
            <Step6
              goToPrevStep={goToPrevStep}
              onSubmit={onSubmit}
              data={form6}
            />
          )}
          {step === 7 && (
            <Step7
              goToPrevStep={goToPrevStep}
              onSubmit={onSubmit}
              data={form7}
            />
          )}
        </div>
        <div className="absolute top-[2vh] right-[2vw] xl:top-[8vh] xl:right-[14vw]">
          <StepButton step={step} />
        </div>
      </div>
    </UserTemplate>
  );
};
