import { useCallback, useEffect, useState } from "react";
import {
  StyledTypographyInputLabel,
  StyledTypographyTitle,
} from "../../Styled/StyledTypography";
import { ReturnToIcon } from "../../utilities/icons/ReturnToIcon";
import {
  StyledInputPasswordField,
  StyledInputTelephoneField,
  StyledInputTextField,
} from "../../Styled/StyledInput";
import { StyledButton, StyledWarningButton } from "../../Styled/StyledButton";
import { useNavigate, useParams } from "react-router-dom";
import { StyledAvatarPicker } from "../../Styled/StyledAvatarPicker";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import { emailRegex, modalStyle } from "../../utils";
import { useDispatch, useSelector } from "react-redux";
import { setErrorMessage, setSuccessMessage } from "../../actions/message";
import { Loading } from "../Loading";
import adminService from "../../services/admin.service";
import { StyledSelect } from "../../Styled/StyledSelect";
import { ROLE } from "../../router/roles";
import { Modal, Box } from "@mui/material";
import AdminTemplate from "../../components/AdminTemplate";

const schema = yup.object().shape({
  firstname: yup.string().required("Le prénom est requis"),
  lastname: yup.string().required("Le nom de famille est requis"),
  // role: yup.string().required("Le rôle est requis"),
  email: yup
    .string()
    .required("L'e-mail est requis")
    .matches(emailRegex, "Adresse e-mail invalide"),
  telephone: yup
    .string()
    .required("Numéro de téléphone requis")
    .matches(
      /^\+33 \d{2} \d{2} \d{2} \d{2} \d{2}$/,
      "Format de numéro de téléphone invalide (ex: +33 (01) 23 45 67 89)"
    ),
  password: yup
    .string()
    .min(8, "Le mot de passe doit contenir au moins 8 caractères")
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&'"#^_`{}|~[\]()\\/<>+=.,-])[A-Za-z\d@$!%*?&'"#^_`{}|~[\]()\\/<>+=.,-]*$/,
      "Le mot de passe doit contenir au moins 1 chiffre et 1 caractère spécial"
    )
    .required("Le mot de passe est requis"),
  confirmPassword: yup
    .string()
    .oneOf(
      [yup.ref("password"), null],
      "Les mots de passe doivent correspondre"
    )
    .required("Confirmez votre mot de passe"),
  roles: yup.string().required("Le rôle doit être requis"),
});

export const EditAgeoInfo = () => {
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);
  const dispatch = useDispatch();
  const { userId } = useParams();
  const { id: myId, roles } = useSelector(
    (state) => state.auth?.user ?? { roles: [] }
  );
  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    const updateData = {
      id: userId,
      ...data,
    };

    adminService
      .updateAdmin(updateData)
      .then((res) => {
        dispatch(setSuccessMessage(res));
      })
      .catch((err) => {
        dispatch(setErrorMessage(err));
      });
  };

  const loadUserInfo = useCallback(() => {
    adminService
      .getAdmin(userId)
      .then((res) => {
        setUserData(res.data);
      })
      .catch((err) => {
        dispatch(setErrorMessage(err));
      });
  }, [userId, dispatch]);

  useEffect(() => {
    loadUserInfo(); // now loadUserInfo is included as a dependency
  }, [loadUserInfo]); // Include loadUserInfo in the dependency array

  const handleRemoveUser = () => {
    adminService
      .removeAdmin(userId)
      .then((res) => {
        dispatch(setSuccessMessage(res));
        if (userId === myId) {
          navigate("/logout");
        } else {
          navigate("/manage-admin");
        }
      })
      .catch((err) => {
        dispatch(setErrorMessage(err));
      });
  };

  if (userData === null) {
    return <Loading comment="Chargement des données utilisateur"></Loading>;
  }

  return (
    <AdminTemplate active={3}>
      <Modal
        open={openConfirmModal}
        onClose={() => setOpenConfirmModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <p className="text-myerror font-segoeBold text-[20px]">
            {userData?.roles?.includes(ROLE.AGEO)
              ? "Êtes-vous sûr de vouloir supprimer cet élément pour un utilisateur super-administrateur ?"
              : "Êtes-vous sûr de vouloir supprimer cet élément pour un administrateur AGEO ?"}
          </p>
          <div className="w-full sm:w-[320px] my-[20px]">
            <StyledWarningButton
              onClick={() => {
                setOpenConfirmModal(false);
                handleRemoveUser();
              }}
              type="button"
            >
              Oui
            </StyledWarningButton>
          </div>
          <div className="w-full sm:w-[320px]">
            <StyledButton
              type="button"
              onClick={() => setOpenConfirmModal(false)}
            >
              Non
            </StyledButton>
          </div>
        </Box>
      </Modal>
      <div className="h-[63px]"></div>
      <div className="px-[5vw]">
        <StyledTypographyTitle
          sx={{
            fontWeight: "300 !important",
            display: "flex",
            flexWrap: "wrap",
            textAlign: "center",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Mon&nbsp;
          <span className="font-segoeSemi">profil</span>
        </StyledTypographyTitle>
      </div>
      <div
        className="flex justify-center items-center mb-16 hover:cursor-pointer mt-[80px]"
        onClick={() => navigate("/manage-admin")}
      >
        <ReturnToIcon />
        <StyledTypographyInputLabel
          sx={{
            color: "#2574A9 !important",
            fontWeight: "700 !important",
            paddingLeft: "5px",
          }}
        >
          Retour au listing
        </StyledTypographyInputLabel>
      </div>
      <div className={`w-full max-w-[720px] m-auto p-[20px]`}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex justify-center">
            <Controller
              name="avatar"
              control={control}
              defaultValue={userData.avatar}
              render={({ field }) => (
                <StyledAvatarPicker
                  id="avatar"
                  onChange={field.onChange}
                  value={field.value}
                />
              )}
            />
          </div>
          <div className="mt-[3.7vh] flex justify-center flex-wrap gap-x-4 gap-y-4">
            <div className="w-full sm:w-[320px]">
              <Controller
                name="firstname"
                control={control}
                defaultValue={userData.firstname}
                render={({ field }) => (
                  <StyledInputTextField
                    id="firstname"
                    placeholder="Votre prénom"
                    label="Prénom :"
                    onChange={field.onChange}
                    value={field.value}
                    error={errors.firstname?.message}
                  />
                )}
              />
            </div>
            <div className="w-full sm:w-[320px]">
              <Controller
                name="lastname"
                control={control}
                defaultValue={userData.lastname}
                render={({ field }) => (
                  <StyledInputTextField
                    id="lastname"
                    placeholder="Votre nom"
                    label="Nom :"
                    onChange={field.onChange}
                    value={field.value}
                    error={errors.lastname?.message}
                  />
                )}
              />
            </div>

            <div className="w-full sm:w-[320px]">
              <Controller
                name="telephone"
                control={control}
                defaultValue={userData.phone_number}
                render={({ field }) => (
                  <StyledInputTelephoneField
                    id="telephone"
                    placeholder="+33 __ __ __ __ __"
                    label="Téléphone :"
                    onChange={field.onChange}
                    value={field.value}
                    error={errors.telephone?.message}
                  />
                )}
              />
            </div>

            <div className="w-full sm:w-[320px]">
              <Controller
                name="email"
                control={control}
                defaultValue={userData.email}
                render={({ field }) => (
                  <StyledInputTextField
                    id="email"
                    placeholder="Votre email"
                    label="Votre email :"
                    onChange={field.onChange}
                    value={field.value}
                    error={errors.email?.message}
                  />
                )}
              />
            </div>

            <div className="w-full sm:w-[320px]">
              <Controller
                name="password"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <StyledInputPasswordField
                    id="password"
                    placeholder="Mot de passe"
                    label="Mot de passe :"
                    value={field.value}
                    onChange={field.onChange}
                    error={errors.password?.message}
                  />
                )}
              />
            </div>

            <div className="w-full sm:w-[320px]">
              <Controller
                name="confirmPassword"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <StyledInputPasswordField
                    id="confirmPassword"
                    placeholder="Confirmez votre mot de passe"
                    label="Confirmez votre mot de passe :"
                    value={field.value}
                    onChange={field.onChange}
                    error={errors.confirmPassword?.message}
                  />
                )}
              />
            </div>
            <div className="w-full sm:w-[320px]">
              <Controller
                name="roles"
                control={control}
                defaultValue={userData.roles[0]}
                render={({ field }) => (
                  <StyledSelect
                    id="roles"
                    label="Rôle :"
                    placeholder="Rôle"
                    options={[
                      {
                        label: "Admin",
                        value: "ROLE_ADMIN",
                      },
                      { label: "Super Admin", value: "ROLE_AGEO" },
                    ]}
                    value={field.value}
                    onChange={field.onChange}
                    error={errors.roles?.message}
                  />
                )}
              />
            </div>

            <div className="w-full sm:w-[320px]"></div>
          </div>

          <div className="mt-[5.4vh] flex flex-wrap justify-center gap-x-4 gap-y-4 w-full">
            {roles && roles.includes(ROLE.AGEO) && (
              <div className="w-full sm:w-[320px]">
                <StyledWarningButton onClick={() => setOpenConfirmModal(true)}>
                  SUPPRIMER LE COMPTE
                </StyledWarningButton>
              </div>
            )}
            <div className="w-full sm:w-[320px]">
              <StyledButton type="submit">
                MODIFIER MES INFORMATIONS
              </StyledButton>
            </div>
          </div>
        </form>
      </div>
    </AdminTemplate>
  );
};
