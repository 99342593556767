export const DisconnectionIcon = ({
  width = 30,
  height = 30,
  color = "#4f7793",
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
  >
    <g clipPath="url(#clip0_559_380)">
      <mask
        id="mask0_559_380"
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width={width}
        height={height}
      >
        <path d="M30 0H0V30H30V0Z" fill="white" />
      </mask>
      <g mask="url(#mask0_559_380)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3 21V24C3 27.3149 5.6865 30 9 30H24C27.3149 30 30 27.3149 30 24V6C30 2.6865 27.3149 0 24 0H9C5.6865 0 3 2.6865 3 6V9C3 9.828 3.672 10.5 4.5 10.5C5.328 10.5 6 9.828 6 9V6C6 4.344 7.344 3 9 3H24C25.6575 3 27 4.344 27 6V24C27 25.6575 25.6575 27 24 27H9C7.344 27 6 25.6575 6 24V21C6 20.1735 5.328 19.5 4.5 19.5C3.672 19.5 3 20.1735 3 21ZM14.3789 13.5L12.4395 11.562C11.8546 10.9753 11.8546 10.0261 12.4395 9.4395C13.0261 8.85459 13.9753 8.85459 14.5605 9.4395L19.0605 13.9395C19.6468 14.5261 19.6468 15.4753 19.0605 16.062L14.5605 20.562C13.9753 21.1468 13.0261 21.1468 12.4395 20.562C11.8546 19.9753 11.8546 19.0261 12.4395 18.4395L14.3789 16.5H1.5C0.671997 16.5 0 15.828 0 15C0 14.1735 0.671997 13.5 1.5 13.5H14.3789Z"
          fill={color}
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_559_380">
        <rect width={width} height={height} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
