import styled from "@emotion/styled";
import { Box } from "@mui/material";
import { StyledTypographySidebarList } from "./StyledTypography";

export const StyledDashboardItemBox = ({
  width = "350px",
  height = "130px",
  content = "",
  onClick,
}) => {
  return (
    <Box
      sx={{
        borderRadius: "15px",
        width: width,
        minHeight: height,
        background: "#fff",
        color: "#EB4C37",
        boxShadow: "0px 4px 35px 0px #DBE0F0",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        "&:hover": {
          background: "#EB4C37",
          color: "#fff",
        },
      }}
      onClick={onClick}
    >
      <div className="w-[200px]">
        <StyledTypographySidebarList
          sx={{
            lineHeight: "normal !important",
            textTransform: "uppercase",
            textAlign: "center !important",
            fontFamily: "Segoe UI Bold !important",
          }}
        >
          {content}
        </StyledTypographySidebarList>
      </div>
    </Box>
  );
};
export const StyledProfileBox = styled(Box)`
  border-radius: 10px;
  border: 2px solid #2574a9;
  padding: 30.62px 39.63px;
  background: rgba(37, 116, 169, 0.1);

  min-height: 211.278px;
  height: 100%;
`;

export const StyledContractBox = styled(Box)`
  border-radius: 10px;
  border: 2px solid #37434b;
  padding: 30.62px 39.63px;
  background: rgba(55, 67, 75, 0.1);

  min-height: 211.278px;
  height: 100%;
`;

export const StyledDashboardBox = styled(Box)`
  border-radius: 10px;
  border: 2px solid #eceef6;
  background: #fff;
  box-shadow: 0px 20px 50px 0px rgba(220, 224, 249, 0.5);
  padding: 30px;
`;

// export const StyledModalProfileBox = styled(Box)`
//   border-radius: 10px;
//   border: 2px solid #2574a9;
//   padding: 30.62px 39.63px;
//   background: rgba(37, 116, 169, 0.1);
//   min-width: 200px;
//   height: 211.278px;
// `;

// export const StyledModalActionBox = styled(Box)`
//   border-radius: 10px;
//   border: 2px solid #37434b;
//   padding: 30.62px 39.63px;
//   background: rgba(55, 67, 75, 0.1);
//   min-width: 200px;
//   height: 211.278px;
// `;
