import { CircularProgress } from "@mui/material";
import { StyledTypographyInputLabel } from "../Styled/StyledTypography";

export const Loading = ({ comment = "" }) => {
  return (
    <div className="relative h-[100vh] w-[100vw] flex flex-col justify-center items-center bg-[rgba(230,230,230,0.4)] gap-y-4">
      <CircularProgress
        sx={{ width: "20vw", height: "20vw", color: "#eb4c37" }}
      />
      {comment !== "" ? (
        <StyledTypographyInputLabel sx={{ color: "#eb4c37 !important" }}>
          {comment}
        </StyledTypographyInputLabel>
      ) : (
        []
      )}
    </div>
  );
};
