import { StyledInputPasswordField } from "../../Styled/StyledInput";
import {
  StyledTypographyTitle,
  StyledTypographyInputLabel,
} from "../../Styled/StyledTypography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import { Circle } from "@mui/icons-material";
import { StyledButton } from "../../Styled/StyledButton";
import { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import WelcomeTemplate from "../../components/WelcomeTemplate";
import { Logo } from "../../utilities/Logo";
import { useNavigate } from "react-router-dom";

const schema = yup.object().shape({
  password: yup
    .string()
    .min(8, "Le mot de passe doit contenir au moins 8 caractères")
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&'"#^_`{}|~[\]()\\/<>+=.,-])[A-Za-z\d@$!%*?&'"#^_`{}|~[\]()\\/<>+=.,-]*$/,
      "Le mot de passe doit contenir au moins 1 chiffre et 1 caractère spécial"
    )
    .required("Le mot de passe est requis"),
  confirmPassword: yup
    .string()
    .oneOf(
      [yup.ref("password"), null],
      "Les mots de passe doivent correspondre"
    )
    .required("Confirmez votre mot de passe"),
});

export const Password = ({ onSubmit: onPasswordSubmit, form }) => {
  const [scrWidth, setScrWidth] = useState(window.innerWidth);
  const navigate = useNavigate();
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    const handleResize = () => {
      setScrWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const onSubmit = (data) => {
    onPasswordSubmit({
      password: data.password,
    });
  };

  return (
    <WelcomeTemplate>
      <div className="mt-[77px]">
        <Logo width={200} onClick={() => navigate("/")} />
      </div>
      <div className="mb-[5px] mt-[10px]">
        <StyledTypographyTitle>Plus qu’une étape !</StyledTypographyTitle>
      </div>

      <div className={`${scrWidth > 550 ? "w-[470px]" : "w-[80vw]"}`}>
        <div className="mb-[12px]">
          <StyledTypographyInputLabel
            sx={{ textAlign: "left", fontWeight: "300 !important" }}
          >
            Veuillez saisir un nouveau mot de passe en respectant les critères
            suivants :
          </StyledTypographyInputLabel>
        </div>
        <div className="mb-[22px]">
          <List
            sx={{
              paddingTop: 0,
              paddingBottom: 0,
              "& .MuiListItem-root": {
                paddingTop: 0,
                paddingBottom: 0,
                paddingLeft: 0,
              },
            }}
          >
            <ListItem>
              <ListItemIcon sx={{ minWidth: "12px" }}>
                <Circle sx={{ width: 6, height: 6, color: "#4f7793" }} />
              </ListItemIcon>
              <StyledTypographyInputLabel sx={{ fontWeight: "400 !important" }}>
                8 caractères minimum
              </StyledTypographyInputLabel>
            </ListItem>
            <ListItem>
              <ListItemIcon sx={{ minWidth: "12px" }}>
                <Circle sx={{ width: 6, height: 6, color: "#4f7793" }} />
              </ListItemIcon>
              <StyledTypographyInputLabel sx={{ fontWeight: "400 !important" }}>
                1 caractère spécial minimum
              </StyledTypographyInputLabel>
            </ListItem>
            <ListItem>
              <ListItemIcon sx={{ minWidth: "12px" }}>
                <Circle sx={{ width: 6, height: 6, color: "#4f7793" }} />
              </ListItemIcon>
              <StyledTypographyInputLabel sx={{ fontWeight: "400 !important" }}>
                1 chiffre minimum
              </StyledTypographyInputLabel>
            </ListItem>
          </List>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="">
            <Controller
              name="password"
              control={control}
              defaultValue={form.password}
              render={({ field }) => (
                <StyledInputPasswordField
                  id="password"
                  placeholder="Mot de passe"
                  label="Mot de passe :"
                  value={field.value}
                  onChange={field.onChange}
                  error={errors.password?.message}
                />
              )}
            />
          </div>
          <div className="mt-[19px]">
            <Controller
              name="confirmPassword"
              control={control}
              defaultValue={form.confirmPassword}
              render={({ field }) => (
                <StyledInputPasswordField
                  id="confirmPassword"
                  placeholder="Confirmez votre mot de passe"
                  label="Confirmez votre mot de passe :"
                  value={field.value}
                  onChange={field.onChange}
                  error={errors.confirmPassword?.message}
                />
              )}
            />
          </div>

          <div className="mt-[43px] mb-[20px]">
            <StyledButton type="submit">VALIDER</StyledButton>
          </div>
        </form>
      </div>
    </WelcomeTemplate>
  );
};
