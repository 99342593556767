import { Box } from "@mui/material";
import { StyledTypographyInputLabel } from "./StyledTypography";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

export const StyledDatePicker = ({
  label = "",
  error = "",
  sub_label = "",
  value,
  onChange,
}) => {
  return (
    <div className="w-full flex flex-col space-y-2">
      {label === "" ? (
        []
      ) : (
        <StyledTypographyInputLabel
          sx={{
            textAlign: "left",
            color: error === "" ? "#4f7793 !important" : "#eb4c37 !important",
          }}
        >
          {label}
        </StyledTypographyInputLabel>
      )}

      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div className="flex flex-wrap items-center">
          {sub_label === "" ? (
            []
          ) : (
            <div className="min-w-[88px]">
              <StyledTypographyInputLabel
                sx={{
                  color:
                    error === "" ? "#4f7793 !important" : "#eb4c37 !important",
                }}
              >
                {sub_label}
              </StyledTypographyInputLabel>
            </div>
          )}
          <DatePicker
            sx={{
              "&:hover .MuiOutlinedInput-notchedOutline": {
                border:
                  error === ""
                    ? "2px solid #4f7793 !important"
                    : "2px solid red !important",
              },
              "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                border:
                  error === ""
                    ? "2px solid #4f7793 !important"
                    : "2px solid red !important",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                border:
                  error === ""
                    ? "2px solid #e6e8ec !important"
                    : "2px solid #eb4c37 !important",
                borderRadius: "8px",
              },
              "& .MuiInputBase-input.MuiOutlinedInput-input": {
                color:
                  error === "" ? "#4f7793 !important" : "#eb4c37 !important",
                // fontWeight: fontWeight,

                paddingLeft: "20px",
                fontFamily: "Segoe UI",
              },
              "& .MuiInputBase-root.MuiOutlinedInput-root": {
                maxWidth: "320px",
                background: "#fff",
              },
              "& .MuiSvgIcon-root": {
                fill:
                  error === "" ? "#4f7793 !important" : "#eb4c37 !important",
              },
            }}
            slotProps={{
              textField: {
                helperText: error,
              },
            }}
            value={dayjs(value)}
            onChange={(v) => onChange(v.toISOString())}
            format="DD/MM/YYYY"
          />
        </div>
      </LocalizationProvider>
    </div>
  );
};
export const StyledDateRangePicker = ({
  label,
  startDate = null,
  endDate = null,
  handleStartDateChange,
  handleEndDateChange,
  error = "",
  value = "",
  ...fields
}) => {
  return (
    <Box className="w-full flex flex-col space-y-4">
      {label === "" ? (
        []
      ) : (
        <StyledTypographyInputLabel sx={{ textAlign: "left" }}>
          {label}
        </StyledTypographyInputLabel>
      )}
      <LocalizationProvider dateAdapter={AdapterDayjs} {...fields}>
        <div className="flex items-center flex-wrap">
          <div className="min-w-[88px]">
            <StyledTypographyInputLabel>Début:</StyledTypographyInputLabel>
          </div>
          <DatePicker
            value={startDate}
            onChange={(date) => handleStartDateChange(date)}
            sx={{
              margin: "0 21px",
              backgroundColor: "white",
              "&:hover .MuiOutlinedInput-notchedOutline": {
                border:
                  error === ""
                    ? "2px solid #4f7793 !important"
                    : "2px solid red !important",
              },
              "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                border:
                  error === ""
                    ? "2px solid #4f7793 !important"
                    : "2px solid red !important",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                border: "2px solid #e6e8ec",
                borderRadius: "8px",
              },
              "& .MuiInputBase-input.MuiOutlinedInput-input": {
                color:
                  error === "" ? "#4f7793 !important" : "#eb4c37 !important",
                // fontWeight: fontWeight,
              },
              "& .Mui-error .MuiOutlinedInput-notchedOutline": {
                border: "2px solid #eb4c37 !important",
              },
            }}
          />
        </div>
        <div className="flex items-center flex-wrap">
          <div className="min-w-[88px]">
            <StyledTypographyInputLabel>Fin:</StyledTypographyInputLabel>
          </div>
          <DatePicker
            value={endDate}
            onChange={(date) => handleEndDateChange(date)}
            sx={{
              margin: "0 21px",
              backgroundColor: "white",
              "&:hover .MuiOutlinedInput-notchedOutline": {
                border:
                  error === ""
                    ? "2px solid #4f7793 !important"
                    : "2px solid red !important",
              },
              "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                border:
                  error === ""
                    ? "2px solid #4f7793 !important"
                    : "2px solid red !important",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                border: "2px solid #e6e8ec",
                borderRadius: "8px",
              },
              "& .MuiInputBase-input.MuiOutlinedInput-input": {
                color:
                  error === "" ? "#4f7793 !important" : "#eb4c37 !important",
                // fontWeight: fontWeight,
              },
              "& .Mui-error .MuiOutlinedInput-notchedOutline": {
                border: "2px solid #eb4c37 !important",
              },
            }}
          />
        </div>
      </LocalizationProvider>
    </Box>
  );
};
