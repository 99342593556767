import React, { useEffect, useState } from "react";
import { AppBar, Toolbar, IconButton, Menu, MenuItem } from "@mui/material";
import {
  MoreVertOutlined,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { Logo } from "../utilities/Logo";
import { StyledTypographyMenuText } from "../Styled/StyledTypography";
import { DisconnectionIcon } from "../utilities/icons/DisconnectionIcon";
import userService from "../services/user.service";
import { useDispatch, useSelector } from "react-redux";
import { setErrorMessage } from "../actions/message";
import { ROLE } from "../router/roles";

const Header = ({ active = 1 }) => {
  const navigate = useNavigate();
  const [scrWidth, setScrWidth] = useState(window.innerWidth);
  const [logo, setLogo] = useState(null);
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { roles } = useSelector((state) => state.auth?.user ?? { roles: [] });
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setScrWidth(window.innerWidth);
    };

    if (!!user?.roles) {
      let bAdmin = ![ROLE.USER, ROLE.HR].includes(user.roles[0]);

      setIsAdmin(bAdmin);

      if (bAdmin === false) {
        userService
          .getCompanyLogo()
          .then((res) => {
            if (!!res.data.logo) {
              setLogo(`${process.env.REACT_APP_API_URL}${res.data.logo}`);
            }
          })
          .catch((err) => {
            dispatch(setErrorMessage(err));
          });
      }
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [dispatch, user]);

  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);

  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const activeColor = (id) => (active !== id ? "#4f7793" : "#eb4c37");
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      sx={{
        "& .MuiButtonBase-root.MuiMenuItem-root.MuiMenuItem-gutters": {
          minHeight: 54,
        },
      }}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      {roles && roles.length > 0 && (
        <MenuItem onClick={() => navigate(isAdmin ? "/dashboard" : "/welcome")}>
          <StyledTypographyMenuText sx={{ color: activeColor(1) }}>
            Accueil
          </StyledTypographyMenuText>
        </MenuItem>
      )}
      {roles && roles[0] === "ROLE_USER" && (
        <MenuItem onClick={() => navigate("/employment-form")}>
          <StyledTypographyMenuText sx={{ color: activeColor(2) }}>
            Formulaire transition E-R
          </StyledTypographyMenuText>
        </MenuItem>
      )}
      {roles && roles.length > 0 && !isAdmin && (
        <MenuItem onClick={() => navigate("/profile")}>
          <StyledTypographyMenuText sx={{ color: activeColor(3) }}>
            Mon profil
          </StyledTypographyMenuText>
        </MenuItem>
      )}
      <MenuItem onClick={() => navigate("/logout")}>
        <DisconnectionIcon color="#eb4c37" width={30} height={30} />
      </MenuItem>
    </Menu>
  );

  return (
    <AppBar
      position="sticky"
      elevation={0}
      sx={{
        backgroundColor: "white",
        boxShadow: "0px 4px 35px 0px #B5D0E9",
      }}
    >
      <Toolbar
        sx={{
          paddingLeft: "0 !important",
          paddingRight: "0 !important",
        }}
      >
        <div className="flex h-[100px] w-full px-[10vw] sm:px-[16vw] justify-between items-center relative">
          <div className="flex space-x-[42px]">
            <div
              className="flex items-center cursor-pointer"
              onClick={() => navigate(isAdmin ? "/dashboard" : "/welcome")}
            >
              <Logo width="auto" height="51px" />
            </div>
            {scrWidth > 640 ? (
              <div className="flex items-center h-[82px]">
                {!!logo && (
                  <img
                    src={logo}
                    alt="group"
                    style={{ height: "82px", width: "auto" }}
                  />
                )}
              </div>
            ) : (
              <></>
            )}
          </div>
          {scrWidth > 1280 ? (
            <div className="flex justify-end space-x-[5vw]">
              {roles && roles.length > 0 && (
                <div
                  className="flex items-center cursor-pointer"
                  onClick={() => navigate(isAdmin ? "/dashboard" : "/welcome")}
                >
                  <StyledTypographyMenuText sx={{ color: activeColor(1) }}>
                    Accueil
                  </StyledTypographyMenuText>
                </div>
              )}
              {roles && roles[0] === "ROLE_USER" && (
                <div
                  className="flex items-center cursor-pointer"
                  onClick={() => navigate("/employment-form")}
                >
                  <StyledTypographyMenuText sx={{ color: activeColor(2) }}>
                    Formulaire transition E-R
                  </StyledTypographyMenuText>
                </div>
              )}
              {roles && roles.length > 0 && !isAdmin && (
                <div
                  className="flex items-center cursor-pointer"
                  onClick={() => navigate("/profile")}
                >
                  <StyledTypographyMenuText sx={{ color: activeColor(3) }}>
                    Mon profil
                  </StyledTypographyMenuText>
                </div>
              )}
              <div
                className="flex items-center cursor-pointer"
                onClick={() => navigate("/logout")}
              >
                <DisconnectionIcon color="#eb4c37" width={30} height={30} />
              </div>
            </div>
          ) : (
            <div className="absolute right-0 top-0 h-full flex items-center">
              <IconButton
                size="large"
                aria-label="show more"
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
              >
                <MoreVertOutlined sx={{ color: "black" }} />
              </IconButton>
            </div>
          )}
        </div>
        {renderMobileMenu}
      </Toolbar>
    </AppBar>
  );
};

export default Header;
