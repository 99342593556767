import { CameraAlt } from "@mui/icons-material";
import {
  Avatar,
  Badge,
  IconButton,
  Modal,
  Box,
  Slider,
  Button,
} from "@mui/material";
import { useRef, useState } from "react";
import AvatarEditor from "react-avatar-editor";
import fileService from "../services/file.service";
import { setErrorMessage } from "../actions/message";
import { useDispatch } from "react-redux";

import("screw-filereader");

const boxStyle = {
  width: "300px",
  height: "300px",
  display: "flex",
  flexFlow: "column",
  justifyContent: "center",
  alignItems: "center",
};
const modalStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const CropperModal = ({ src, modalOpen, setModalOpen, setPreview }) => {
  const [slideValue, setSlideValue] = useState(10);
  const cropRef = useRef(null);

  const handleSave = async () => {
    if (cropRef) {
      const dataUrl = cropRef.current.getImage().toDataURL();

      const img = new Image();

      img.onload = function () {
        let MAX_WIDTH = 300;
        let MAX_HEIGHT = 300;

        let width = img.width;
        let height = img.height;

        // Check if the image needs resizing
        if (width > MAX_WIDTH || height > MAX_HEIGHT) {
          if (width > height) {
            if (width > MAX_WIDTH) {
              height *= MAX_WIDTH / width;
              width = MAX_WIDTH;
            }
          } else {
            if (height > MAX_HEIGHT) {
              width *= MAX_HEIGHT / height;
              height = MAX_HEIGHT;
            }
          }
        }

        // Create a canvas
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        // Set the canvas dimensions based on the resized image
        canvas.width = width;
        canvas.height = height;

        // Draw the resized image onto the canvas
        ctx.drawImage(img, 0, 0, width, height);

        // Obtain the compressed data URL from the canvas
        canvas.toBlob(
          function (blob) {
            // Set the preview using the compressed blob
            setPreview(blob);
          },
          "image/jpeg",
          0.7
        ); // Adjust the quality as per your requirement

        setModalOpen(false);
      };

      img.src = dataUrl;
    }
  };

  return (
    <Modal sx={modalStyle} open={modalOpen}>
      <Box sx={boxStyle}>
        <AvatarEditor
          ref={cropRef}
          image={src}
          style={{ width: "100%", height: "100%" }}
          border={50}
          borderRadius={150}
          color={[0, 0, 0, 0.72]}
          scale={slideValue / 10}
          rotate={0}
        />

        <Slider
          min={10}
          max={50}
          sx={{
            margin: "0 auto",
            width: "80%",
            color: "cyan",
          }}
          size="medium"
          defaultValue={slideValue}
          value={slideValue}
          onChange={(e) => setSlideValue(e.target.value)}
        />
        <Box
          sx={{
            display: "flex",
            padding: "10px",
            border: "3px solid white",
            background: "black",
          }}
        >
          <Button
            size="small"
            sx={{ marginRight: "10px", color: "white", borderColor: "white" }}
            variant="outlined"
            onClick={(e) => setModalOpen(false)}
          >
            cancel
          </Button>
          <Button
            sx={{ background: "#5596e6" }}
            size="small"
            variant="contained"
            onClick={handleSave}
          >
            Save
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export const StyledAvatarPicker = ({ onChange, value, readonly = false }) => {
  const [file, setFile] = useState(null);
  const imageRef = useRef();
  const [modalOpen, setModalOpen] = useState(false);
  const dispatch = useDispatch();

  const renderImage = (fileObject) => {
    setFile(fileObject);
    setModalOpen(true);
  };

  const showOpenFileDialog = () => {
    imageRef.current.click();
  };

  const handleSelectAvatar = (event) => {
    const fileObject = event.target.files[0];
    if (!fileObject) return;
    renderImage(fileObject);
  };

  const handleSetPreview = (croppedImageBlob) => {
    let fileToUpload = new File([croppedImageBlob], "edited-avatar.png");

    fileService
      .uploadFiles(
        {
          avatar: fileToUpload,
          file_type: "avatars",
        },
        (event) => {
          // setProgress(Math.round((100 * event.loaded) / event.total));
        }
      )
      .then((res) => {
        onChange(res.data.urls.avatar);
      })
      .catch((err) => {
        dispatch(setErrorMessage(err));
      });
  };

  return (
    <div>
      <CropperModal
        modalOpen={modalOpen}
        src={file}
        setPreview={handleSetPreview}
        setModalOpen={setModalOpen}
      />
      <Badge
        overlap="circular"
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        badgeContent={
          !readonly && (
            <IconButton onClick={showOpenFileDialog}>
              <Avatar
                alt="Remy Sharp"
                sx={{
                  background: "#F7F9FB",
                  boxShadow:
                    "0px 0px 35px 0px rgba(219, 224, 240, 0.50) !important",
                  width: "47px",
                  height: "47px",
                  border: "2px solid #eee",
                }}
              >
                <CameraAlt sx={{ color: "#4f7793" }} />
              </Avatar>
            </IconButton>
          )
        }
      >
        <Avatar
          alt="Travis Howard"
          src={
            !!value
              ? `${process.env.REACT_APP_API_URL}${value}`
              : "https://millerconsulting.codes/default.jpg"
          }
          sx={{ width: "150px", height: "150px" }}
        />
      </Badge>

      <input
        ref={imageRef}
        type="file"
        style={{ display: "none" }}
        accept="image/*"
        onChange={handleSelectAvatar}
      />
    </div>
  );
};
