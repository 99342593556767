export const DashBoardIcon = ({
  width = 30,
  height = 30,
  color = "#4f7793",
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 28 28"
    fill="none"
  >
    <path
      d="M2.89577 9.86676H11.0208C12.1768 9.86676 13.1168 8.92683 13.1168 7.77078V2.89577C13.1168 1.73991 12.1768 0.8 11.0208 0.8H2.89577C1.73993 0.8 0.8 1.73993 0.8 2.89577V7.77078C0.8 8.92682 1.73991 9.86676 2.89577 9.86676ZM2.825 2.89577C2.825 2.85688 2.85688 2.825 2.89577 2.825H11.0208C11.06 2.825 11.0918 2.85698 11.0918 2.89577V7.77078C11.0918 7.80988 11.0599 7.84175 11.0208 7.84175H2.89577C2.85698 7.84175 2.825 7.80998 2.825 7.77078V2.89577Z"
      fill={color}
      stroke={color}
      strokeWidth="0.4"
    />
    <path
      d="M2.89577 27.1998H11.0208C12.1768 27.1998 13.1168 26.2599 13.1168 25.104V13.729C13.1168 12.5729 12.1768 11.633 11.0208 11.633H2.89577C1.73991 11.633 0.8 12.573 0.8 13.729V25.104C0.8 26.2599 1.73993 27.1998 2.89577 27.1998ZM2.825 13.729C2.825 13.6898 2.85698 13.658 2.89577 13.658H11.0208C11.0599 13.658 11.0918 13.6899 11.0918 13.729V25.104C11.0918 25.1428 11.06 25.1748 11.0208 25.1748H2.89577C2.85688 25.1748 2.825 25.1429 2.825 25.104V13.729Z"
      fill={color}
      stroke={color}
      strokeWidth="0.4"
    />
    <path
      d="M16.979 27.1998H25.104C26.2599 27.1998 27.1998 26.2598 27.1998 25.104V20.229C27.1998 19.0729 26.2599 18.133 25.104 18.133H16.979C15.8229 18.133 14.883 19.0729 14.883 20.229V25.104C14.883 26.2599 15.823 27.1998 16.979 27.1998ZM16.908 20.229C16.908 20.1899 16.9399 20.158 16.979 20.158H25.104C25.1428 20.158 25.1748 20.1898 25.1748 20.229V25.104C25.1748 25.1429 25.1429 25.1748 25.104 25.1748H16.979C16.9398 25.1748 16.908 25.1428 16.908 25.104V20.229Z"
      fill={color}
      stroke={color}
      strokeWidth="0.4"
    />
    <path
      d="M24.904 16.3668H25.104C26.2599 16.3668 27.1998 15.4268 27.1998 14.2708V2.89577C27.1998 1.73993 26.2598 0.8 25.104 0.8H16.979C15.823 0.8 14.883 1.73991 14.883 2.89577V14.2708C14.883 15.4268 15.8229 16.3668 16.979 16.3668L24.904 16.3668ZM16.908 2.89577C16.908 2.85697 16.9398 2.825 16.979 2.825H25.104C25.1429 2.825 25.1748 2.85688 25.1748 2.89577V14.2708C25.1748 14.31 25.1428 14.3418 25.104 14.3418H16.979C16.9399 14.3418 16.908 14.3099 16.908 14.2708V2.89577Z"
      fill={color}
      stroke={color}
      strokeWidth="0.4"
    />
  </svg>
);
