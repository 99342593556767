import { StyledButton } from "../Styled/StyledButton";
import { StyledTypographyTitle } from "../Styled/StyledTypography";
import UserTemplate from "../components/UserTemplate";

export const CookiePolicy = () => {
  return (
    <UserTemplate>
      <div className="flex flex-col justify-center items-center px-[5vw] sm:px-[10vw]">
        <div className="h-[117px]"></div>
        <StyledTypographyTitle>
          Politique d’utilisation des cookies
        </StyledTypographyTitle>
        <div className="h-[71px]"></div>
        <div className="max-w-[483px] w-full">
          <StyledButton onClick={() => window.open("cookies.pdf", "_blank")}>
            CONSULTER LA POLITIQUE D'UTILISATION DES COOKIES
          </StyledButton>
        </div>
      </div>
    </UserTemplate>
  );
};
