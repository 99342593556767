import {
  StyledTypographyContentText,
  StyledTypographyTitle,
} from "../Styled/StyledTypography";
import UserTemplate from "../components/UserTemplate";

export const LegalInformation = () => {
  return (
    <UserTemplate>
      <div className="px-[5vw] sm:px-[15vw]">
        <div className="h-[117px]"></div>
        <StyledTypographyTitle>Mentions légales</StyledTypographyTitle>
        <div className="mt-[48px]">
          <StyledTypographyContentText sx={{ textAlign: "left !important" }}>
            Ces données personnelles ne seront pas transmises aux Ressources
            Humaines de votre entreprise, sauf si vous en faites le choix une
            fois l’analyse de faisabilité effectuée par AGEO. Le groupe AGEO a
            été choisi par votre employeur pour réaliser votre bilan de
            transition emploi-retraite. Dans cette optique, nous étudierons les
            informations que vous nous transmettrez concernant votre vie
            personnelle et votre parcours professionnel.
            <br />
            <br />
            <span className="font-segoeBold">Identification:</span>
            <br />
            Hébergeur du site : SAS AGEO <br />
            Editeur du site : SAS AGEO Société de courtage en assurances sous le
            contrôle de l’ACPR (Autorité de Contrôle Prudentiel et de
            Résolution) – www.acpr.banque-france.fr <br /> <br />
            Garantie financière et assurance de responsabilité civile conforme
            au Code des Assurances.
            <br />
            Code APE : 6622Z <br />
            Siège social : 7, rue de Turbigo – 75001 Paris <br />
            SAS AGEO Capital de 457 340 € RCS Paris 493 349 682
            <br />
            N° ORIAS : 150 013 50 www.orias.fr 7, rue de Turbigo 75001 Paris
            <br />
            <br />
            Président :&nbsp;
            <span className="font-segoeBold text-ageoNormal">
              Eric OZIEBALA Directrice
            </span>
            <br />
            Associée :{" "}
            <span className="font-segoeBold text-ageoNormal">
              Maryse OZIEBALA
            </span>
            &nbsp;
            <br />
            Directeurs Associés :&nbsp;
            <span className="font-segoeBold text-ageoNormal">
              Antoine GIACOMOTTO
            </span>
            <br />
            Directeur Général Adjoint :&nbsp;
            <span className="font-segoeBold text-ageoNormal">
              Nicolas PAGES{" "}
            </span>
            <br />
            Directeur de la publication : Pour toute remarque relative au
            site,&nbsp;
            <a
              href="mailto:contact-ageo-retraite@ageo.fr"
              className="underline"
              target="_blank"
              rel="noreferrer"
            >
              contactez-nous en cliquant ici
            </a>
            .
            <br />
            <br />
            <span className="font-segoeBold">Droits d’auteur : </span>
            <br />
            L’ensemble des contenus de tous types présents sur ce site Internet
            sont la propriété exclusive de la SAS AGEO ou des prestataires
            désignés par cette société, et sont protégés par la législation
            française et/ou internationale sur les droits d’auteur. La
            reproduction de tout ou partie de ce site sur quelque support que ce
            soit (y compris pour les documents téléchargeables et les
            représentations iconographiques et photographiques), ainsi que toute
            utilisation du contenu du site, autre que la consultation
            individuelle et privée, sont formellement interdites sauf
            autorisation expresse de la SAS AGEO. <br />
            Les marques citées sur ce site sont déposées par leurs propriétaires
            respectifs. Toute reproduction ou représentation faites sans leur
            accord préalable est une contrefaçon. <br />
            Dans le cadre de sa communication Internet, la SAS AGEO donne la
            permission à tout créateur/gestionnaire de site web de pointer via
            un lien hypertexte sur la page d’accueil de son site
            www.ageo.fr.&nbsp;
            <br />
            <br />
            <span className="font-segoeBold">Crédits Photos :</span>
            <br />
            Fotolia.com – Shutterstock.com <br />
            <br />
            <span className="font-segoeBold">Contenu du site : </span>
            <br />
            Les principales fonctionnalités du site sont disponibles sur la page
            d’accueil (www.ageo.fr/index). L’information communiquée sur le site
            www.ageo.fr ne constitue en aucun cas une offre commerciale ou à
            contracter, ni des conseils ou avis juridiques. Malgré tous les
            soins apportés à la réalisation de ce site et à son actualisation
            régulière, des erreurs peuvent s’être glissées dans les informations
            présentées. <br />
            Les utilisateurs du site procéderont donc à toutes vérifications
            utiles. La SAS AGEO se réserve le droit sans avertissement préalable
            de corriger ou de modifier le contenu de ce site à tout
            moment.&nbsp;
            <br />
            <br />
            <span className="font-segoeBold">
              Confidentialité et Données Personnelles :
            </span>
            <br />
            En remplissant des questionnaires, vous transmettez des informations
            qui pourront être utilisées exclusivement par la SAS AGEO. <br />
            Les informations transmises servent à la mise en place et à
            l’exécution de votre demande. Elles pourront, par ailleurs, être
            utilisées à des fins commerciales pour vous proposer des produits ou
            des services.
            <br />
            Vous pouvez nous signaler que vous ne souhaitez plus recevoir ces
            propositions commerciales, pour cela&nbsp;
            <a
              href="mailto:contact-ageo-retraite@ageo.fr"
              className="underline"
              target="_blank"
              rel="noreferrer"
            >
              contactez-nous en cliquant ici
            </a>
            .
            <br />
            Conformément à la loi 78-17 du 6 janvier 1978 relative à
            l’informatique, aux fichiers et aux libertés modifiée dite « Loi
            Informatique et Liberté » et du Règlement Générale sur la Protection
            des Données n°2016-679 du 27 avril 2016, les internautes sont
            informés qu’ils disposent d’un droit d’accès, de suppression ou de
            modification des données nominatives les concernant.
            <br />
            Si vous souhaitez exercer les droits listés ci-dessus ou contacter
            notre DPO pour toute question relative à la protection des données
            personnelles,&nbsp;
            <a
              href="mailto:contact-ageo-retraite@ageo.fr"
              className="underline"
              target="_blank"
              rel="noreferrer"
            >
              contactez-nous en cliquant ici
            </a>
            .
            <br />
            <br />
            <span className="font-segoeBold">Cookies :</span> <br />
            Les « cookies » sont des fichiers texte envoyés par un site à votre
            navigateur et qui sont stockés sur votre ordinateur. Ils servent à
            vous proposer des informations personnalisées en permettant au
            serveur visité de vous identifier.
            <br /> Vous avez la possibilité de visiter le site www.ageo.fr en
            désactivant cette option dans les paramètres de votre
            navigateur.&nbsp;
            <br />
            Pour connaitre notre politique d’utilisation des cookies,{" "}
            <a href="/cookiepolicy" className="underline" rel="noreferrer">
              veuillez cliquer ici
            </a>
            .
            <br />
            <br />
            <span className="font-segoeBold">Service Réclamation :</span>
            <br /> Si vous souhaitez nous faire part de toute réclamation, vous
            pouvez le faire : <br />
            <br />
            Soit par courrier postal : <br />
            AGEO Service Réclamation <br />
            14, rue Joliot Curie – CS 30248 <br />
            51010 Châlons en Champagne Cedex <br />
            Soit par e-mail :<br />
            service.reclamation@ageo.fr <br />
            <br />
            Notre Service Réclamation enregistre, analyse et répond à vos
            réclamations et suggestions, parce que le Service Client est notre
            Engagement.
            <br />
            <br />
            <span className="font-segoeBold">Notre Médiateur : </span>
            <br />
            Après saisie de notre service réclamation et de réponse non
            satisfaisante, vous avez la possibilité de saisir notre médiateur
            (article L612-2 du code de la consommation) :<br />
            <br /> Soit par courrier postal :<br /> La Médiation de
            l’assurance&nbsp;
            <br />
            Pôle CSCA <br />
            TSA 50110 75441 Paris Cedex 09
            <br /> Soit par e-mail : <br />
            le.mediateur@mediation-assurance.org
            <br />
            www.mediation-assurance.org
          </StyledTypographyContentText>
        </div>
        <div className="h-[30px]"></div>
      </div>
    </UserTemplate>
  );
};
