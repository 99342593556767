import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { StyledTypographyInputLabel } from "./StyledTypography";
import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";

export const StyledRadioBoxGroup = ({
  group_title = "",
  contents = {},
  error = "",
  onChange,
  value,
}) => {
  return (
    <Box className="items-start flex flex-col gap-y-2">
      {group_title !== "" ? (
        <StyledTypographyInputLabel
          sx={{
            color: error === "" ? "#4f7793 !important" : "#EB4C37 !important",
          }}
        >
          {group_title}
        </StyledTypographyInputLabel>
      ) : (
        []
      )}

      <RadioGroup row onChange={onChange} value={value}>
        <div className="flex flex-wrap gap-x-16">
          {contents.map((content) => {
            return (
              <FormControlLabel
                key={content}
                value={content}
                control={
                  <Radio
                    icon={<CheckBoxOutlineBlank />}
                    checkedIcon={<CheckBox />}
                    sx={{
                      "&.Mui-checked .MuiSvgIcon-root": {
                        fill: "#4F7793 !important",
                      },
                      "& .MuiSvgIcon-root": {
                        fill:
                          error === ""
                            ? "#E6E8EC !important"
                            : "#EB4C37 !important",
                      },
                    }}
                  />
                }
                label={
                  <StyledTypographyInputLabel
                    sx={{
                      color:
                        error === ""
                          ? "#4f7793 !important"
                          : "#EB4C37 !important",
                    }}
                  >
                    {content}
                  </StyledTypographyInputLabel>
                }
              />
            );
          })}
        </div>
      </RadioGroup>
      {error && <Typography color="red">{error}</Typography>}
    </Box>
  );
};

export const StyledUpdatedRadioBoxGroup = ({
  group_title = "",
  options = [],
  handleChange,
  error = "",
  ...fields
}) => {
  return (
    <Box className="items-start flex flex-col gap-y-2">
      {group_title !== "" ? (
        <StyledTypographyInputLabel
          sx={{
            color: error === "" ? "#4f7793 !important" : "#EB4C37 !important",
          }}
        >
          {group_title}
        </StyledTypographyInputLabel>
      ) : (
        []
      )}

      <RadioGroup row {...fields}>
        <div className="flex flex-wrap gap-x-16">
          {options.map((option, i) => {
            return (
              <FormControlLabel
                key={i}
                value={option.value}
                control={
                  <Radio
                    icon={<CheckBoxOutlineBlank />}
                    checkedIcon={<CheckBox />}
                    sx={{
                      "&.Mui-checked .MuiSvgIcon-root": {
                        fill: "#4F7793 !important",
                      },
                      "& .MuiSvgIcon-root": {
                        fill:
                          error === ""
                            ? "#E6E8EC !important"
                            : "#EB4C37 !important",
                      },
                    }}
                  />
                }
                label={
                  <StyledTypographyInputLabel
                    sx={{
                      color:
                        error === ""
                          ? "#4f7793 !important"
                          : "#EB4C37 !important",
                    }}
                  >
                    {option.label}
                  </StyledTypographyInputLabel>
                }
              />
            );
          })}
        </div>
      </RadioGroup>
      {error && <Typography color="red">{error}</Typography>}
    </Box>
  );
};
