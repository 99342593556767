import axios from "../axios-orders";

class AuthService {
  login(email, password) {
    return axios.post("/sign-in", { email, password });
  }

  logout(refresh_token) {
    return axios.post('/api/sign-out', { refresh_token });
  }

  activate(user) {
    return axios.post("/activate", user);
  }

  activateCheck(user) {
    return axios.post("/activate-check", user);
  }

  activateHR(user) {
    return axios.post("/api/activate-hr", user);
  }

  activateHRCheck(user) {
    return axios.post("/activate-hr-check", user);
  }

  forgotPassword(email) {
    return axios.post("/reset-password/send-email", {
      email,
    });
  }

  resetPassword(password, token) {
    return axios.post(`/reset-password/reset/${token}`, {
      password,
    });
  }

  refreshToken(refresh_token) {
    return axios.post("/refresh-token", { refresh_token });
  }
}

const authService = new AuthService();
export default authService;
