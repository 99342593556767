import React from 'react';
import {
  StyledTypographyInputLabel,
  StyledTypographyTitle,
} from "../../Styled/StyledTypography";
import { ReturnToIcon } from "../../utilities/icons/ReturnToIcon";
import {
  StyledInputPasswordField,
  StyledInputTelephoneField,
  StyledInputTextField,
} from "../../Styled/StyledInput";
import { StyledButton } from "../../Styled/StyledButton";
import { useNavigate } from "react-router-dom";
import { StyledAvatarPicker } from "../../Styled/StyledAvatarPicker";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import { emailRegex } from "../../utils";
import adminService from "../../services/admin.service";
import { useDispatch } from "react-redux";
import { setErrorMessage, setSuccessMessage } from "../../actions/message";
import { StyledSelect } from "../../Styled/StyledSelect";
import AdminTemplate from "../../components/AdminTemplate";

const schema = yup.object().shape({
  firstname: yup.string().required("Le prénom est requis"),
  lastname: yup.string().required("Le nom de famille est requis"),
  email: yup
    .string()
    .required("L'e-mail est requis")
    .matches(emailRegex, "Adresse e-mail invalide"),
  telephone: yup
    .string()
    .required("Numéro de téléphone requis")
    .matches(
      /^\+33 \d{2} \d{2} \d{2} \d{2} \d{2}$/,
      "Format de numéro de téléphone invalide (ex: +33 (01) 23 45 67 89)"
    ),
  password: yup
    .string()
    .min(8, "Le mot de passe doit contenir au moins 8 caractères")
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&'"#^_`{}|~[\]()\\/<>+=.,-])[A-Za-z\d@$!%*?&'"#^_`{}|~[\]()\\/<>+=.,-]*$/,
      "Le mot de passe doit contenir au moins 1 chiffre et 1 caractère spécial"
    )
    .required("Le mot de passe est requis"),
  confirmPassword: yup
    .string()
    .oneOf(
      [yup.ref("password"), null],
      "Les mots de passe doivent correspondre"
    )
    .required("Confirmez votre mot de passe"),
  roles: yup.string().required("Le rôle doit être requis"),
});

export const AddSuperAdmin = ({ avatar_link = "" }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    const createData = {
      ...data,
    };

    adminService
      .addAdmin(createData)
      .then((res) => {
        dispatch(setSuccessMessage(res));

        navigate("/manage-admin");
      })
      .catch((err) => {
        dispatch(setErrorMessage(err));
      });
  };

  return (
    <AdminTemplate active={3}>
      <div className="h-[63px]"></div>
      <div className="px-[5vw]">
        <StyledTypographyTitle
          sx={{
            fontWeight: "300 !important",
            display: "flex",
            flexWrap: "wrap",
            textAlign: "center",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Ajouter un&nbsp;
          <span className="font-segoeSemi">administrateur</span>
        </StyledTypographyTitle>
      </div>
      <div
        className="flex justify-center items-center hover:cursor-pointer mt-[80px]"
        onClick={() => navigate("/manage-admin")}
      >
        <ReturnToIcon />
        <StyledTypographyInputLabel
          sx={{
            color: "#2574A9 !important",
            fontWeight: "700 !important",
            paddingLeft: "5px",
          }}
        >
          Retour au listing
        </StyledTypographyInputLabel>
      </div>

      <div className={`w-full max-w-[720px] m-auto p-[20px] mt-[52px]`}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex justify-center">
            <Controller
              name="avatar"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <StyledAvatarPicker
                  id="avatar"
                  onChange={field.onChange}
                  value={field.value}
                />
              )}
            />
          </div>
          <div className="mt-[3.7vh] flex justify-center flex-wrap gap-x-4 gap-y-4">
            <div className="w-full sm:w-[320px]">
              <Controller
                name="firstname"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <StyledInputTextField
                    id="firstname"
                    placeholder="Votre prénom"
                    label="Prénom :"
                    onChange={field.onChange}
                    value={field.value}
                    error={errors.firstname?.message}
                  />
                )}
              />
            </div>
            <div className="w-full sm:w-[320px]">
              <Controller
                name="lastname"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <StyledInputTextField
                    id="lastname"
                    placeholder="Votre nom"
                    label="Nom :"
                    onChange={field.onChange}
                    value={field.value}
                    error={errors.lastname?.message}
                  />
                )}
              />
            </div>

            <div className="w-full sm:w-[320px]">
              <Controller
                name="telephone"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <StyledInputTelephoneField
                    id="telephone"
                    placeholder="+33 __ __ __ __ __"
                    label="Téléphone :"
                    onChange={field.onChange}
                    value={field.value}
                    error={errors.telephone?.message}
                  />
                )}
              />
            </div>

            <div className="w-full sm:w-[320px]">
              <Controller
                name="email"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <StyledInputTextField
                    id="email"
                    placeholder="Votre email"
                    label="Votre email :"
                    onChange={field.onChange}
                    value={field.value}
                    error={errors.email?.message}
                  />
                )}
              />
            </div>

            <div className="w-full sm:w-[320px]">
              <Controller
                name="password"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <StyledInputPasswordField
                    id="password"
                    placeholder="Mot de passe"
                    label="Mot de passe :"
                    value={field.value}
                    onChange={field.onChange}
                    error={errors.password?.message}
                  />
                )}
              />
            </div>

            <div className="w-full sm:w-[320px]">
              <Controller
                name="confirmPassword"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <StyledInputPasswordField
                    id="confirmPassword"
                    placeholder="Confirmez votre mot de passe"
                    label="Confirmez votre mot de passe :"
                    value={field.value}
                    onChange={field.onChange}
                    error={errors.confirmPassword?.message}
                  />
                )}
              />
            </div>

            <div className="w-full sm:w-[320px]">
              <Controller
                name="roles"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <StyledSelect
                    id="roles"
                    label="Rôle :"
                    placeholder="Rôle"
                    options={[
                      {
                        label: "Admin",
                        value: "ROLE_ADMIN",
                      },
                      { label: "Super Admin", value: "ROLE_AGEO" },
                    ]}
                    value={field.value}
                    onChange={field.onChange}
                    error={errors.roles?.message}
                  />
                )}
              />
            </div>
          </div>

          <div className="mt-[5.4vh] flex mx-auto w-full sm:w-[318px]">
            <StyledButton type="submit">CRÉER ADMINISTRATEUR AGEO</StyledButton>
          </div>
        </form>
      </div>
    </AdminTemplate>
  );
};
