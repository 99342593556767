import { StyledInputTextField } from "../../Styled/StyledInput";
// import { StyledTypographyTitle } from "../../Styled/StyledTypography";
import { StyledButton } from "../../Styled/StyledButton";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { forgotPassword } from "../../actions/auth";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { ROLE } from "../../router/roles";
import { emailRegex } from "../../utils";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import WelcomeTemplate from "../../components/WelcomeTemplate";
import { Logo } from "../../utilities/Logo";

const schema = yup.object().shape({
  email: yup
    .string()
    .matches(emailRegex, "Adresse e-mail invalide")
    .required("L'e-mail est requis"),
});

function ForgotPassword({ props }) {
  const [scrWidth, setScrWidth] = useState(window.innerWidth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isLoggedIn, user } = useSelector((state) => state.auth);
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    const handleResize = () => {
      setScrWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (isLoggedIn === true && !!user.roles) {
      if (user.roles.includes(ROLE.AGEO)) {
        navigate("/dashboard");
      } else if (user.roles.includes(ROLE.USER)) {
        navigate("/welcome");
      }
    }
  }, [isLoggedIn, navigate, user]);

  const handleLogin = async (data) => {
    dispatch(forgotPassword(data.email));
  };

  return (
    <WelcomeTemplate>
      <div className="mt-[77px]">
        <Logo width={200} onClick={() => navigate("/")} />
      </div>
      <div className="mb-[5px] mt-[10px]">
        <span className="text-[40px] font-segoeLight text-ageoTitle leading-[50px]">
          Mot de passe oublié
        </span>
      </div>

      <form onSubmit={handleSubmit(handleLogin)}>
        <div className={`${scrWidth > 550 ? "w-[470px]" : "w-[80vw]"}`}>
          <div className="min-h-[120px]">
            <Controller
              name="email"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <StyledInputTextField
                  id="email"
                  placeholder="Votre email"
                  label="Votre email :"
                  onChange={field.onChange}
                  value={field.value}
                  error={errors.email?.message}
                />
              )}
            />
          </div>
          <div className="mb-[43px] mt-[30px]">
            <StyledButton type="submit">Réinitialiser</StyledButton>
          </div>
        </div>
      </form>
    </WelcomeTemplate>
  );
}

export default ForgotPassword;
