import { StyledButton } from "../Styled/StyledButton";
import { StyledTypographyTitle } from "../Styled/StyledTypography";
import UserTemplate from "../components/UserTemplate";
export const TermsOfUse = () => {
  return (
    <UserTemplate>
      <div className="flex flex-col justify-center items-center px-[5vw] sm:px-[10vw]">
        <div className="h-[117px]"></div>
        <StyledTypographyTitle>
          Politique de confidentialité et de protection des données
        </StyledTypographyTitle>
        <div className="h-[71px]"></div>
        <div className="max-w-[437px] w-full">
          <StyledButton onClick={() => window.open("policy.pdf", "_blank")}>
            CONSULTER LA POLITIQUE DE CONFIDENTIALITÉ
          </StyledButton>
        </div>
      </div>
    </UserTemplate>
  );
};
