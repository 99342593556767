import { StyledRadioBoxGroup } from "../../Styled/StyledRadioBoxGroup";

import { StyledDropzone } from "../../Styled/StyledDropzone";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { StyledButton, StyledWarningButton } from "../../Styled/StyledButton";
import { Collapse } from "@mui/material";
import { useEffect } from "react";

const schema = yup.object().shape({
  earlyRetirementCertificate: yup.string().required("Ce champ est requis"),
  file: yup
    .array()
    .required("Fichier requis")
    .test(
      "start-retirement-files",
      "Vous devez sélectionner au moins 1 fichier",
      function (files) {
        const { earlyRetirementCertificate } = this.parent;
        if (earlyRetirementCertificate === "Oui") {
          return files.length > 0;
        }
        return true;
      }
    ),
});

export const Step5 = ({ goToPrevStep, onSubmit, data }) => {
  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
    getValues,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      earlyRetirementCertificate: data.earlyRetirementCertificate || "",
      file: data.file || [],
    },
  });

  useEffect(() => {
    setValue(
      "earlyRetirementCertificate",
      data.earlyRetirementCertificate || ""
    );
    setValue("file", data.file || []);
  }, [data, setValue]);

  const { earlyRetirementCertificate } = watch();

  const early_retirement_certificate_radiobox = ["Oui", "Non"];
  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="">
          <Controller
            name="earlyRetirementCertificate"
            control={control}
            render={({ field }) => (
              <StyledRadioBoxGroup
                id="earlyRetirementCertificate"
                group_title="Le cas échéant, avez-vous en votre possession l'attestation de départ en retraite anticipée pour carrière longue ?"
                contents={early_retirement_certificate_radiobox}
                onChange={field.onChange}
                value={field.value}
                error={errors.earlyRetirementCertificate?.message}
              />
            )}
          />
        </div>
        <Collapse in={earlyRetirementCertificate === "Oui"}>
          <div className="mt-[33px]">
            <Controller
              name="file"
              control={control}
              render={({ field }) => (
                <StyledDropzone
                  id="file"
                  onChange={field.onChange}
                  value={field.value}
                  error={errors.file?.message}
                />
              )}
            />
          </div>
        </Collapse>
        <div className="mt-[5.4vh] flex flex-wrap justify-center gap-x-4 gap-y-4 w-full">
          <div className="w-full sm:w-[320px]">
            <StyledWarningButton
              onClick={() => {
                goToPrevStep(getValues());
              }}
              type="button"
            >Précédent</StyledWarningButton>
          </div>
          <div className="w-full sm:w-[320px]">
            <StyledButton type="submit">CONTINUER</StyledButton>
          </div>
        </div>
      </form>
    </div>
  );
};
