import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  AUTH_START,
  SET_USER,
  REFRESH_TOKEN_SUCCESS,
  LOGIN_TOKEN_SUCCESS,
} from "../actions/types";

const initialState = {
  loading: false,
  user: {},
  token: localStorage.getItem("token") || null,
  refresh_token: localStorage.getItem("refresh_token") || null,
  isLoggedIn: false,
  authChecked: false,
};

export default function authReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case REGISTER_SUCCESS:
      return {
        ...state,
        user: action.payload.user,
        token: action.payload.token,
        refresh_token: action.payload.refresh_token,
        isLoggedIn: true,
        loading: false,
        authChecked: true,
        ...payload,
      };
    case REGISTER_FAIL:
      return {
        ...state,
        isLoggedIn: false,
        loading: false,
        authChecked: true,
      };
    case LOGIN_SUCCESS:
      localStorage.setItem("token", action.payload.token);
      localStorage.setItem("refresh_token", action.payload.refresh_token);
      return {
        ...state,
        isLoggedIn: true,
        token: action.payload.token,
        refresh_token: action.payload.refresh_token,
        user: action.payload.user,
        loading: false,
        authChecked: true,
      };
    case REFRESH_TOKEN_SUCCESS:
      localStorage.setItem("token", action.payload.token);
      return {
        ...state,
        token: action.payload.token,
        loading: false,
        authChecked: true,
      };
    case LOGIN_TOKEN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        user: action.payload.user,
        loading: false,
        authChecked: true,
      };
    case LOGIN_FAIL:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
        loading: false,
        authChecked: true,
      };
    case LOGOUT:
      localStorage.removeItem("token");
      localStorage.removeItem("refresh_token");
      return {
        ...state,
        token: null,
        refresh_token: null,
        isLoggedIn: false,
        user: {},
      };
    case AUTH_START:
      return {
        ...state,
        loading: true,
        authChecked: false,
      };
    case SET_USER:
      return {
        ...state,
        user: {
          ...payload,
        },
      };
    default:
      return state;
  }
}
