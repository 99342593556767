import {
  StyledInputPercentageField,
  StyledInputTextField,
  StyledInputYearField,
} from "../../Styled/StyledInput";
import { StyledTypographyInputLabel } from "../../Styled/StyledTypography";
import { StyledRadioBoxGroup } from "../../Styled/StyledRadioBoxGroup";

import { StyledDropzone } from "../../Styled/StyledDropzone";
import { StyledSelect } from "../../Styled/StyledSelect";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { StyledButton, StyledWarningButton } from "../../Styled/StyledButton";
import { Controller } from "react-hook-form";
import { Collapse } from "@mui/material";
import { useEffect } from "react";

const schema = yup.object().shape({
  disabledWorker: yup.string().required("Ce champ est requis"),
  file: yup
    .array()
    .required("Fichier requis")
    .test(
      "start-retirement-files",
      "Vous devez sélectionner au moins 1 fichier",
      function (files) {
        const { disabledWorker } = this.parent;
        if (disabledWorker === "Oui") {
          return files.length > 0;
        }
        return true;
      }
    ),
  permanentDisability: yup.string().required("Ce champ est requis"),
  percent: yup
    .number()
    .transform((v) => v || 0)
    .when("permanentDisability", {
      is: "Oui",
      then: () => yup.number().required("Ce champ est requis"),
    }),
  file1: yup
    .array()
    .required("Fichier requis")
    .test(
      "start-retirement-files",
      "Vous devez sélectionner au moins 1 fichier",
      function (files) {
        const { permanentDisability } = this.parent;
        if (permanentDisability === "Oui") {
          return files.length > 0;
        }
        return true;
      }
    ),
  boughtQuarter: yup.string().required("Ce champ est requis"),
  quarterNumber: yup.string().when("boughtQuarter", {
    is: "Oui",
    then: () => yup.string().required("Nombre de trimestres requis"),
  }),
  option: yup.string().when("boughtQuarter", {
    is: "Oui",
    then: () => yup.string().required("L'option requis"),
  }),
  baccalaureateDegree: yup.string().required("Ce champ est requis"),
  level: yup.string().when("baccalaureateDegree", {
    is: "Oui",
    then: () => yup.string().required("Niveau requis."),
  }),
  graduationYear: yup.string().when("baccalaureateDegree", {
    is: "Oui",
    then: () =>
      yup
        .string()
        .required("Année de graduation")
        .test(
          "start-year-test",
          "Vous devez saisir l'année à partir de 1900.",
          function (year) {
            const { baccalaureateDegree } = this.parent;
            console.log(year, baccalaureateDegree);
            if (baccalaureateDegree === "Oui") {
              return +year >= 1900;
            }
            return true;
          }
        ),
  }),
  c2p: yup.string().required("C2P est requis"),
  c2pValue: yup.string().when("c2p", {
    is: "Oui",
    then: () => yup.string().required("Veuillez entrer une adresse e-mail"),
  }),
  file2: yup
    .array()
    .required("Fichier requis")
    .test(
      "start-retirement-files",
      "Vous devez sélectionner au moins 1 fichier",
      function (files) {
        const { c2p } = this.parent;
        if (c2p === "Oui") {
          return files.length > 0;
        }
        return true;
      }
    ),
});

export const Step3 = ({ goToPrevStep, onSubmit, data }) => {
  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
    getValues,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      disabledWorker: data.disabledWorker || "",
      file: data.file || [],
      permanentDisability: data.permanentDisability || "",
      percent: data.percent || "",
      file1: data.file1 || [],
      boughtQuarter: data.boughtQuarter || "",
      quarterNumber: data.quarterNumber || "",
      option: data.option || "",
      baccalaureateDegree: data.baccalaureateDegree || "",
      level: data.level || "",
      graduationYear: data.graduationYear || "",
      c2p: data.c2p || "",
      c2pValue: data.c2pValue || "",
      file2: data.file2 || [],
    },
  });

  useEffect(() => {
    setValue("disabledWorker", data.disabledWorker || "");
    setValue("file", data.file || []);
    setValue("permanentDisability", data.permanentDisability || "");
    setValue("percent", data.percent || "");
    setValue("file1", data.file1 || []);
    setValue("boughtQuarter", data.boughtQuarter || "");
    setValue("quarterNumber", data.quarterNumber || "");
    setValue("option", data.option || "");
    setValue("baccalaureateDegree", data.baccalaureateDegree || "");
    setValue("level", data.level || "");
    setValue("graduationYear", data.graduationYear || "");
    setValue("c2p", data.c2p || "");
    setValue("c2pValue", data.c2pValue || "");
    setValue("file2", data.file2 || []);
  }, [data, setValue]);

  const {
    disabledWorker,
    permanentDisability,
    boughtQuarter,
    baccalaureateDegree,
    c2p,
  } = watch();

  const disabled_worker_radiobox = ["Oui", "Non"];
  const permanent_disability_radiobox = ["Oui", "Non"];
  const bought_quarter_radiobox = ["Oui", "Non"];
  const baccalaureate_degree_radiobox = ["Oui", "Non"];
  const c2p_radiobox = ["Oui", "Non"];

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="">
          <Controller
            name="disabledWorker"
            control={control}
            render={({ field }) => (
              <StyledRadioBoxGroup
                id="disabledWorker"
                group_title="Êtes-vous reconnu(e) travailleur handicapé ?"
                contents={disabled_worker_radiobox}
                onChange={field.onChange}
                value={field.value}
                error={errors.disabledWorker?.message}
              />
            )}
          />
        </div>
        <Collapse in={disabledWorker === "Oui"}>
          <div className="mt-[50px]">
            <div className="flex flex-col gap-y-4">
              <StyledTypographyInputLabel>
                Documents justificatifs :
              </StyledTypographyInputLabel>
              <StyledTypographyInputLabel sx={{ fontWeight: "300 !important" }}>
                Veuillez nous transmettre les documents nous permettant
                d'analyser une date de départ anticipée (au titre du dispositif
                travailleur handicapé) sans référence à la nature de votre
                handicap.
              </StyledTypographyInputLabel>
              <Controller
                name="file"
                control={control}
                render={({ field }) => (
                  <StyledDropzone
                    id="file"
                    onChange={field.onChange}
                    value={field.value}
                    error={errors.file?.message}
                  />
                )}
              />
            </div>
          </div>
        </Collapse>
        <div className="mt-[41px]">
          <Controller
            name="permanentDisability"
            control={control}
            render={({ field }) => (
              <StyledRadioBoxGroup
                id="permanentDisability"
                group_title="Avez-vous une incapacité permanente suite à un accident du travail ou une maladie professionnelle ?"
                contents={permanent_disability_radiobox}
                onChange={field.onChange}
                value={field.value}
                error={errors.permanentDisability?.message}
              />
            )}
          />
        </div>
        <Collapse in={permanentDisability === "Oui"}>
          <div className="mt-[41px]">
            <Controller
              name="percent"
              control={control}
              render={({ field }) => (
                <StyledInputPercentageField
                  id="percent"
                  label="Si oui, précisez le taux d’incapacité (en pourcentage) :"
                  placeholder="Valeur"
                  onChange={field.onChange}
                  value={field.value}
                  error={errors.percent?.message}
                />
              )}
            />
          </div>
          <div className="mt-[23px]">
            <StyledTypographyInputLabel sx={{ fontWeight: "300 !important" }}>
              Si oui, merci de nous transmettre l’ensemble des justificatifs
              liés au paiement d’une rente pour incapacité avec indication du
              taux d’incapacité, mais sans référence médicale.
            </StyledTypographyInputLabel>
          </div>
          <div className="mt-4">
            <Controller
              name="file1"
              control={control}
              render={({ field }) => (
                <StyledDropzone
                  id="file1"
                  onChange={field.onChange}
                  value={field.value}
                  error={errors.file1?.message}
                />
              )}
            />
          </div>
        </Collapse>
        <div className="mt-[38px]">
          <Controller
            name="boughtQuarter"
            control={control}
            render={({ field }) => (
              <StyledRadioBoxGroup
                id="boughtQuarter"
                group_title="Avez-vous racheté des trimestres ?"
                contents={bought_quarter_radiobox}
                onChange={field.onChange}
                value={field.value}
                error={errors.boughtQuarter?.message}
              />
            )}
          />
        </div>
        <Collapse in={boughtQuarter === "Oui"}>
          <div className="my-[36px] flex justify-center flex-wrap gap-x-4 gap-y-4">
            <div className="w-full sm:w-[320px]">
              <Controller
                name="quarterNumber"
                control={control}
                render={({ field }) => (
                  <StyledInputTextField
                    id="quarterNumber"
                    placeholder="Valeur"
                    label="Nombre de trimestres rachetés :"
                    value={field.value}
                    onChange={field.onChange}
                    error={errors.quarterNumber?.message}
                  />
                )}
              />
            </div>
            <div className="w-full sm:w-[320px]">
              <Controller
                name="option"
                control={control}
                render={({ field }) => (
                  <StyledSelect
                    id="level"
                    label="L’option :"
                    placeholder="Sélectionnez"
                    onChange={field.onChange}
                    value={field.value}
                    error={errors.level?.message}
                    options={["Option 1", "Option 2"]}
                  />
                )}
              />
            </div>
          </div>
        </Collapse>
        <div className="">
          <Controller
            name="baccalaureateDegree"
            control={control}
            render={({ field }) => (
              <StyledRadioBoxGroup
                id="baccalaureateDegree"
                group_title="Avez-vous obtenu des diplômes Post Baccalauréat ?"
                contents={baccalaureate_degree_radiobox}
                onChange={field.onChange}
                value={field.value}
                error={errors.baccalaureateDegree?.message}
              />
            )}
          />
        </div>
        <Collapse in={baccalaureateDegree === "Oui"}>
          <div className="my-[36px] flex justify-center flex-wrap gap-x-4 gap-y-4">
            <div className="w-full sm:w-[320px]">
              <Controller
                name="level"
                control={control}
                render={({ field }) => (
                  <StyledSelect
                    id="level"
                    label="Niveau atteint :"
                    placeholder="Sélectionnez"
                    onChange={field.onChange}
                    value={field.value}
                    error={errors.level?.message}
                    options={[
                      "CAP",
                      "BEP",
                      "BAC",
                      "BAC+1",
                      "BAC+2",
                      "BAC+3",
                      "BAC+4",
                      "BAC+5",
                      "BAC>5",
                    ]}
                  />
                )}
              />
            </div>
            <div className="w-full sm:w-[320px]">
              <Controller
                name="graduationYear"
                control={control}
                render={({ field }) => (
                  <StyledInputYearField
                    id="graduationYear"
                    label="Année d’obtention :"
                    placeholder="Année"
                    onChange={field.onChange}
                    value={field.value}
                    error={errors.graduationYear?.message}
                  />
                )}
              />
            </div>
          </div>
        </Collapse>
        <div className="">
          <Controller
            name="c2p"
            control={control}
            render={({ field }) => (
              <StyledRadioBoxGroup
                id="c2p"
                group_title="Avez-vous un compte professionnel de prévention (C2P) ?"
                contents={c2p_radiobox}
                onChange={field.onChange}
                value={field.value}
                error={errors.c2p?.message}
              />
            )}
          />
        </div>
        <Collapse in={c2p === "Oui"}>
          <div className="mt-[27px] flex justify-start">
            <div className="w-full sm:w-[400px]">
              <Controller
                name="c2pValue"
                control={control}
                render={({ field }) => (
                  <StyledInputTextField
                    id="c2pValue"
                    placeholder="Valeur"
                    label="Si oui, indiquez le nombre de points obtenus :"
                    onChange={field.onChange}
                    value={field.value}
                    error={errors.c2pValue?.message}
                  />
                )}
              />
            </div>
          </div>
          <div className="mt-[35px]">
            <StyledTypographyInputLabel sx={{ fontWeight: "300 !important" }}>
              Veuillez transmettre un justificatif pouvant en attester :
            </StyledTypographyInputLabel>
          </div>
          <div className="">
            <Controller
              name="file2"
              control={control}
              render={({ field }) => (
                <StyledDropzone
                  id="file2"
                  onChange={field.onChange}
                  value={field.value}
                  error={errors.file2?.message}
                />
              )}
            />
          </div>
        </Collapse>
        <div className="mt-[5.4vh] flex flex-wrap justify-center gap-x-4 gap-y-4 w-full">
          <div className="w-full sm:w-[320px]">
            <StyledWarningButton
              onClick={() => {
                goToPrevStep(getValues());
              }}
              type="button"
            >
              Précédent
            </StyledWarningButton>
          </div>
          <div className="w-full sm:w-[320px]">
            <StyledButton type="submit">CONTINUER</StyledButton>
          </div>
        </div>
      </form>
    </div>
  );
};
