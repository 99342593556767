import axios from '../axios-orders';

class AdminService {
  getAdminList(page = 1) {
    return axios.get(`/api/admin-list?page=${page}`);
  }

  addAdmin(userData) {
    return axios.post('/api/admin', userData);
  }

  updateAdmin(userData) {
    return axios.post(`/api/admin-update/${userData.id}`, userData);
  }

  removeAdmin(userId) {
    return axios.delete(`/api/admin/${userId}`);
  }

  getAdmin(userId) {
    return axios.get(`/api/admin/${userId}`);
  }

  getContactList() {
    return axios.get('/api/contact-list');
  }
}

const adminService = new AdminService();

export default adminService;
