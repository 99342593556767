import {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
  createRef,
  useCallback,
} from "react";
import {
  StyledInputNumberField,
  StyledInputTextField,
} from "../../Styled/StyledInput";
import { StyledSelect } from "../../Styled/StyledSelect";
import { StyledTypographyInputLabel } from "../../Styled/StyledTypography";
import { StyledButton, StyledWarningButton } from "../../Styled/StyledButton";
import { Badge, Tab, Box, Typography } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import { StyledDatePicker } from "../../Styled/StyledDatePicker";
import { StyledRadioBoxGroup } from "../../Styled/StyledRadioBoxGroup";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";

const familySchema = yup.object().shape({
  familySituation: yup.string().required("La situation familiale est requise"),
  job: yup.string().required("L'emploi est requis"),
  forCSP: yup.string().required("Pour CSP est requis"),
});

const childSchema = yup.object().shape({
  firstname: yup.string().required("Ce champ est requis"),
  birthdate: yup.string().required("La date de naissance est requise").min(1),
  deathdate: yup.string(),
  relationship: yup.string().required("Ce champ est requis"),
  businessInterruption: yup.string().required("Ce champ est requis"),
  dependency: yup.string().required("Ce champ est requis"),
});

const ChildComponent = forwardRef((props, ref) => {
  const { visible, data } = props;

  const {
    formState: { errors },
    control,
    trigger,
    getValues,
  } = useForm({
    resolver: yupResolver(childSchema),
    defaultValues: {
      firstname: data.firstname || "",
      birthdate: data.birthdate || "",
      deathdate: data.deathdate || "",
      relationship: data.relationship || "",
      dependency: data.dependency || "",
      businessInterruption: data.businessInterruption || "",
    },
  });

  const relationship_radiobox = [
    "Enfant légitime",
    "Naturel reconnu",
    "Adopté simple ou plénier",
    "Recueilli",
  ];
  const dependency_radiobox = ["Oui", "Non"];
  const business_interruption_radiobox = [
    "Non",
    "Enfant élevé seul pendant 9 ans",
    "Congé de maternité",
    "Congé de paternité",
    "Congé d’adoption",
    "Congé parental d’éducation",
    "Congé de présence parentale",
    "Congé sans solde pour éducation jeunes enfants",
    "Congé sans solde exceptionnel pour enfant recueilli handicapé",
  ];

  const childFunction = async () => {
    const isValid = await trigger();
    if (isValid) {
      const data = getValues();
      return data;
    }
    return null;
  };

  const getChildData = () => {
    return getValues();
  };

  useImperativeHandle(ref, () => ({
    childFunction,
    getChildData,
  }));

  return (
    <div className={visible === true ? `block` : "hidden"}>
      <div
        className="flex justify-start flex-wrap gap-x-4 gap-y-8 bg-white p-[40px] rounded-[8px] border-2"
        sx={{
          border: "2px solid #E6E8EC",
          transform: "translatex(40px)",
          marginLeft: "40px",
        }}
      >
        <div className="w-full sm:w-[320px]">
          <Controller
            name="firstname"
            control={control}
            render={({ field }) => (
              <StyledInputTextField
                id="firstname"
                label="Prénom :"
                placeholder="Prénom"
                onChange={field.onChange}
                value={field.value}
                error={errors.firstname?.message}
              />
            )}
          />
        </div>
        <div className="w-full sm:w-[360px]">
          <Controller
            name="birthdate"
            control={control}
            render={({ field }) => {
              return (
                <StyledDatePicker
                  id="birthdate"
                  label="Date de naissance ou d’arrivée au foyer :"
                  onChange={field.onChange}
                  error={errors.birthdate?.message}
                  value={field.value}
                />
              );
            }}
          />
        </div>

        <div className="w-full sm:w-[320px]">
          <Controller
            name="deathdate"
            control={control}
            render={({ field }) => {
              return (
                <StyledDatePicker
                  id="deathdate"
                  label="Date de décès (si effective) :"
                  onChange={field.onChange}
                  error={errors.deathdate?.message}
                  value={field.value}
                />
              );
            }}
          />
        </div>

        <div className="w-full sm:w-[320px]" />
        <div className="w-full sm:w-[320px] flex flex-wrap">
          <Controller
            name="relationship"
            control={control}
            render={({ field }) => (
              <StyledRadioBoxGroup
                id="relationship"
                group_title={"Relation parent-enfant"}
                contents={relationship_radiobox}
                onChange={field.onChange}
                value={field.value}
                error={errors.relationship?.message}
              />
            )}
          />
          <div className="mt-[30px]">
            <Controller
              name="dependency"
              control={control}
              render={({ field }) => (
                <StyledRadioBoxGroup
                  id="dependency"
                  group_title="9 ans à charge avant les 16 ans"
                  contents={dependency_radiobox}
                  onChange={field.onChange}
                  value={field.value}
                  error={errors.dependency?.message}
                />
              )}
            />
          </div>
        </div>
        <div className="w-full sm:w-[320px]">
          <Controller
            name="businessInterruption"
            control={control}
            render={({ field }) => (
              <StyledRadioBoxGroup
                id="businessInterruption"
                group_title={"Interruption d'activité"}
                contents={business_interruption_radiobox}
                onChange={field.onChange}
                value={field.value}
                error={errors.businessInterruption?.message}
              />
            )}
          />
        </div>
      </div>
    </div>
  );
});

export const Step2 = ({ goToPrevStep, onSubmit, data }) => {
  const [childCount, setChildCount] = useState(0);
  const childRefs = useRef([]);

  const [scrWidth, setScrWidth] = useState(window.innerWidth);
  const [currentTab, setCurrentTab] = useState("0");
  const [result, setResult] = useState([]);
  const [error, setError] = useState("");

  const prevChildDataLengthRef = useRef(0);

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const handleChangeChildCount = useCallback(
    (newCount) => {
      setChildCount(newCount);

      if (+currentTab >= newCount && newCount !== 0) {
        setCurrentTab(`${newCount - 1}`);
      }

      childRefs.current = Array(newCount)
        .fill()
        .map((_, i) => childRefs.current[i] || createRef());
    },
    [currentTab]
  );

  useEffect(() => {
    const newLength = data.childrenData ? data.childrenData.length : 3;
    if (prevChildDataLengthRef.current !== newLength) {
      handleChangeChildCount(newLength);
    }
    prevChildDataLengthRef.current = newLength; // Update the previous length
  }, [data.childrenData, handleChangeChildCount]);

  const {
    handleSubmit,
    formState: { errors },
    control,
    trigger,
    getValues,
    setValue,
  } = useForm({
    resolver: yupResolver(familySchema),
    defaultValues: {
      forCSP: "",
      familySituation: "",
      job: "",
    },
  });

  useEffect(() => {
    const handleResize = () => {
      setScrWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    setValue("forCSP", data.forCSP || "");
    setValue("familySituation", data.familySituation || "");
    setValue("job", data.job || "");
  }, [data, setValue]);

  const handleMakeSubmit = async () => {
    let i;

    let promises = [];

    for (i = 0; i < childCount; i++) {
      promises.push(childRefs.current[i].childFunction());
    }

    Promise.all(promises)
      .then((res) => {
        setResult(res);
        for (i = 0; i < childCount; i++) {
          if (res[i] === null) {
            break;
          }
        }

        if (i < childCount) {
          setError(
            `Tous les champs n’ont pas été correctement renseigné${
              childCount === 1 ? "" : "s"
            }`
          );
          return;
        } else {
          setError("");
        }

        Promise.all([trigger()]).then(([isValid]) => {
          if (isValid) {
            let formData = getValues();
            let finalData = { ...formData, childrenData: res };

            onSubmit(finalData);
          }
        });
      })
      .catch((err) => {});
  };

  const handleBackPage = () => {
    let formData = getValues();
    let res = Array.from({ length: childCount }, (_, i) => {
      let childData = childRefs.current[i].getChildData();
      return { ...childData };
    });

    goToPrevStep({ ...formData, childrenData: [...res] });
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex justify-start flex-wrap gap-x-4 gap-y-4">
          <div className="w-full sm:w-[320px]">
            <Controller
              name="forCSP"
              control={control}
              render={({ field }) => (
                <StyledSelect
                  id="forCSP"
                  label="Votre CSP :"
                  placeholder="Catégorie"
                  options={[
                    "Cadre et professions intellectuelles supérieures",
                    "Profession intermédiaire",
                    "Employé(e)",
                    "Ouvrier/Ouvrière",
                  ]}
                  value={field.value}
                  onChange={field.onChange}
                  error={errors.forCSP?.message}
                />
              )}
            />
          </div>
          <div className="w-full sm:w-[320px]">
            <Controller
              name="familySituation"
              control={control}
              render={({ field }) => (
                <StyledSelect
                  id="familySituation"
                  label="Situation familiale :"
                  placeholder="Situation familiale"
                  options={[
                    "Célibataire",
                    "Pacsé(e)",
                    "Divorcé(e)",
                    "Marié(e)",
                    "Veuf/veuve",
                  ]}
                  value={field.value}
                  onChange={field.onChange}
                  error={errors.familySituation?.message}
                />
              )}
            />
          </div>
          <div className="w-full sm:w-[320px]">
            <Controller
              name="job"
              control={control}
              render={({ field }) => (
                <StyledSelect
                  id="job"
                  label="Vous êtes :"
                  placeholder="Je suis..."
                  options={["Femme", "Homme"]}
                  value={field.value}
                  onChange={field.onChange}
                  error={errors.job?.message}
                />
              )}
            />
          </div>

          <div className="w-full sm:w-[320px]" />
        </div>
        <div className="my-4 flex flex-wrap items-center gap-x-8 gap-y-8">
          <div className="flex items-center gap-x-2 sm:gap-x-4">
            <StyledTypographyInputLabel>
              Nombre d’enfants (nés, élevés ou adoptés) :
            </StyledTypographyInputLabel>
            <div className="w-[95px]">
              <StyledInputNumberField
                value={childCount}
                setValue={handleChangeChildCount}
              />
            </div>
          </div>
        </div>
        <Box
          sx={{
            "& .MuiTabPanel-root": {
              border: "2px solid #E3E9F0",
              borderRadius: "8px",
              paddingTop: "42px",
              paddingLeft: "54px",
              width: scrWidth > 1400 ? "1009px !important" : "100%",
            },
          }}
        >
          <TabContext value={`${currentTab}`}>
            <Box
              sx={{
                marginBottom: "11px",
                "& .MuiButtonBase-root.MuiTab-root:last-of-type": {
                  borderRadius: "0 8px 8px 0",
                },
                "& .MuiButtonBase-root.MuiTab-root:first-of-type": {
                  borderRadius: "8px 0 0 8px",
                },
                "& .MuiButtonBase-root.MuiTab-root": {
                  background: "#E2E9F0",
                  border: "2px solid #E2E9F0",
                  width: "107px",
                },
                "& .Mui-selected.MuiButtonBase-root.MuiTab-root": {
                  background: "#fff",
                  borderRadius: "8px !important",
                },
              }}
            >
              <TabList
                sx={{
                  background: "#e2e9f0",
                  width: `${childCount * 110}px`,
                  borderRadius: "8px",
                }}
                onChange={handleTabChange}
                aria-label="lab API tabs example"
                TabIndicatorProps={{
                  style: {
                    backgroundColor: "transparent",
                  },
                }}
              >
                {Array.from({ length: childCount }, (_, i) => (
                  <Tab
                    key={i}
                    // label={`Enfant ${i + 1}`}
                    label={
                      <Badge
                        badgeContent={result[i] === null ? 1 : 0}
                        color="error"
                        sx={{
                          fontFamily: "Segoe UI Semibold",
                          fontSize: "18px",
                          textTransform: "none",
                        }}
                      >
                        Enfant{i + 1}
                      </Badge>
                    }
                    value={`${i}`}
                  ></Tab>
                ))}
              </TabList>
            </Box>
            {Array.from({ length: childCount }, (_, i) => (
              <ChildComponent
                key={i}
                ref={(ref) => {
                  childRefs.current[i] = ref;
                }}
                visible={`${i}` === currentTab}
                data={data.childrenData?.[i] || {}}
              />
            ))}
          </TabContext>
          <Typography sx={{ color: "#EB4C37" }}>{error}</Typography>
        </Box>
        <div className="mt-[5.4vh] flex flex-wrap justify-start gap-x-4 gap-y-4 w-full">
          <div className="w-full sm:w-[320px]">
            <StyledWarningButton
              onClick={handleBackPage}
              type="button"
            >Précédent</StyledWarningButton>
          </div>
          <div className="w-full sm:w-[320px]">
            <StyledButton onClick={handleMakeSubmit} type="button">
              CONTINUER
            </StyledButton>
          </div>
        </div>
      </form>
    </div>
  );
};
