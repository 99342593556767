import { StyledInputPercentageField } from "../../Styled/StyledInput";
import { StyledRadioBoxGroup } from "../../Styled/StyledRadioBoxGroup";
import { StyledDatePicker } from "../../Styled/StyledDatePicker";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { StyledButton, StyledWarningButton } from "../../Styled/StyledButton";
import { Collapse } from "@mui/material";
import { useEffect } from "react";

const schema = yup.object().shape({
  partTimeWork: yup.string().required("Ce champ est requis."),
  date: yup.string().when("partTimeWork", {
    is: "Oui",
    then: () => yup.string().required("La date de début est requise"),
  }),
  rate: yup.string().when("partTimeWork", {
    is: "Oui",
    then: () => yup.string().required("Taux requis"),
  }),
  contributionRate: yup.string().when("partTimeWork", {
    is: "Oui",
    then: () => yup.string().required("Taux de cotisation requis"),
  }),
});

export const Step6 = ({ goToPrevStep, onSubmit, data }) => {
  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
    getValues,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      partTimeWork: data.partTimeWork || "",
      date: data.date || "",
      rate: data.rate || "",
      contributionRate: data.contributionRate || "",
    },
  });

  useEffect(() => {
    setValue("partTimeWork", data.partTimeWork || "");
    setValue("date", data.date || "");
    setValue("rate", data.rate || "");
    setValue("contributionRate", data.contributionRate || "");
  }, [data, setValue]);

  const { partTimeWork } = watch();
  const parttime_word_radiobox = ["Oui", "Non"];

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="">
          <Controller
            name="partTimeWork"
            control={control}
            render={({ field }) => (
              <StyledRadioBoxGroup
                id="partTimeWork"
                group_title="Travaillez-vous actuellement à temps partiel ?"
                contents={parttime_word_radiobox}
                onChange={field.onChange}
                value={field.value}
                error={errors.partTimeWork?.message}
              />
            )}
          />
        </div>
        <Collapse in={partTimeWork === "Oui"}>
          <div className="mt-[32px]">
            <Controller
              name="date"
              control={control}
              render={({ field }) => {
                return (
                  <StyledDatePicker
                    id="date"
                    label="Si oui, à quelle date avez-vous commencé ?"
                    sub_label="Début :"
                    onChange={field.onChange}
                    error={errors.date?.message}
                    value={field.value}
                  />
                );
              }}
            />
          </div>
          <div className="mt-[40px]">
            <Controller
              name="rate"
              control={control}
              render={({ field }) => (
                <StyledInputPercentageField
                  id="rate"
                  label="Taux (en pourcentage) :"
                  placeholder="Valeur"
                  onChange={field.onChange}
                  value={field.value}
                  error={errors.rate?.message}
                />
              )}
            />
          </div>
          <div className="mt-[35px]">
            <Controller
              name="contributionRate"
              control={control}
              render={({ field }) => (
                <StyledInputPercentageField
                  id="contributionRate"
                  label="Taux de cotisation (en pourcentage du temps plein) :"
                  placeholder="Valeur"
                  onChange={field.onChange}
                  value={field.value}
                  error={errors.contributionRate?.message}
                />
              )}
            />
          </div>
        </Collapse>
        <div className="mt-[5.4vh] flex flex-wrap justify-center gap-x-4 gap-y-4 w-full">
          <div className="w-full sm:w-[320px]">
            <StyledWarningButton
              onClick={() => {
                goToPrevStep(getValues());
              }}
              type="button"
            >Précédent</StyledWarningButton>
          </div>
          <div className="w-full sm:w-[320px]">
            <StyledButton type="submit">CONTINUER</StyledButton>
          </div>
        </div>
      </form>
    </div>
  );
};
