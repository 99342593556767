// import { Button, Typography } from "@mui/material";

// export const StyledButton = ({
//   content,
//   handleClick,
//   color = "#2574a9",
//   disabled = false,
//   type = "button",
//   ...fields
// }) => {
//   return (
//     <Button
//       type={type}
//       variant="contained"
//       className="min-h-[50px] flex items-center justify-center gap-4"
//       sx={{
//         backgroundColor: disabled ? "#E6E8EC" : color,
//         width: "100%",
//         borderRadius: "8px",
//         "&:hover": { backgroundColor: "#E6E8EC" },
//         "&:hover .MuiTypography-root": {
//           color: "#4f7793",
//         },
//       }}
//       disabled={disabled}
//       onClick={handleClick}
//       {...fields}
//     >
//       <Typography
//         sx={{
//           color: disabled ? "#4F7793" : 'white',
//           textAlign: 'center',
//           fontFamily: 'Segoe UI Bold',
//           fontSize: '16px',
//           fontStyle: 'normal',
//           lineHeight: 'normal',
//           textTransform: 'uppercase',
//         }}
//       >
//         {content}
//       </Typography>
//     </Button>
//   );
// };

export const StyledButton = ({
  disabled = false,
  type = "button",
  children,
  onClick,
}) => {
  return (
    <button
      type={type}
      className="w-full font-segoeBold bg-ageoNormal h-[50px] rounded-[8px] text-paperColor text-[16px] hover:bg-ageoHover transition-all disabled:bg-ageoDisable disabled:text-ageoHover disabled:cursor-not-allowed uppercase"
      disabled={disabled}
      onClick={onClick}
    >
      {children.toUpperCase()}
    </button>
  );
};

export const StyledWarningButton = ({
  onClick,
  disabled = false,
  type = "button",
  children,
}) => {
  return (
    <button
      type={type}
      className="w-full font-segoeBold bg-myerror h-[50px] rounded-[8px] text-paperColor text-[16px] hover:bg-ageoHover transition-all disabled:bg-ageoDisable disabled:text-ageoHover disabled:cursor-not-allowed uppercase"
      disabled={disabled}
      onClick={onClick}
    >
      {children.toUpperCase()}
    </button>
  );
};
