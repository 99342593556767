import {
  StyledInputTextField,
  StyledInputPasswordField,
  StyledInputTelephoneField,
} from "../../Styled/StyledInput";
import { Grid } from "@mui/material";
import { StyledButton } from "../../Styled/StyledButton";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import { emailRegex } from "../../utils";
import WelcomeTemplate from "../../components/WelcomeTemplate";
import { StyledTypographyTitle } from "../../Styled/StyledTypography";
import { Logo } from "../../utilities/Logo";

const schema = yup.object().shape({
  firstname: yup.string().required("Le prénom est requis"),
  lastname: yup.string().required("Le nom de famille est requis"),
  phone_number: yup.string().required("Le numéro de téléphone est requis"),
  email: yup
    .string()
    .matches(emailRegex, "Adresse e-mail invalide")
    .required("L'e-mail est requis"),
  company_code: yup.string().required("Le code de l'entreprise est requis"),
  registration_code: yup.string().required("Le Code d’activation est requis"),
});

export const Description = ({ onSubmit: onDescriptionSubmit, form: data }) => {
  const [scrWidth, setScrWidth] = useState(window.innerWidth);
  const navigate = useNavigate();

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(schema),
  });
  useEffect(() => {
    const handleResize = () => {
      setScrWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const onSubmit = (data) => {
    onDescriptionSubmit({
      firstname: data.firstname,
      lastname: data.lastname,
      phone_number: data.phone_number,
      company_code: data.company_code,
      email: data.email,
      registration_code: data.registration_code,
    });
  };
  return (
    <WelcomeTemplate>
      <div className="mt-[77px]">
        <Logo width={200} onClick={() => navigate("/")} />
      </div>
      <div className="mt-[5px] mb-[10px] sm:w-[550px]">
        <StyledTypographyTitle>
          Complétez votre dossier de transition emploi retraite !
        </StyledTypographyTitle>
      </div>
      <div
        className={`${
          scrWidth >= 500 || scrWidth < 900 ? "px-[40px]" : "w-[90vw]"
        } max-w-[900px]`}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container rowSpacing={2} columnSpacing={2}>
            <Grid item xs={12} lg={6} md={6}>
              <Controller
                name="firstname"
                control={control}
                defaultValue={data.firstname}
                render={({ field }) => (
                  <StyledInputTextField
                    id="firstname"
                    placeholder="Votre prénom"
                    label="Prénom :"
                    value={field.value}
                    onChange={field.onChange}
                    error={errors.firstname?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} lg={6} md={6}>
              <Controller
                name="lastname"
                control={control}
                defaultValue={data.lastname}
                render={({ field }) => (
                  <StyledInputTextField
                    id="lastname"
                    placeholder="Votre nom"
                    label="Nom :"
                    value={field.value}
                    onChange={field.onChange}
                    error={errors.lastname?.message}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} lg={6} md={6}>
              <Controller
                name="phone_number"
                control={control}
                defaultValue={data.phone_number}
                render={({ field }) => (
                  <StyledInputTelephoneField
                    id="phone_number"
                    placeholder="+33 __ __ __ __ __"
                    label="Téléphone :"
                    onChange={field.onChange}
                    value={field.value}
                    error={errors.phone_number?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} lg={6} md={6}>
              <Controller
                name="company_code"
                control={control}
                defaultValue={data.company_code}
                render={({ field }) => (
                  <StyledInputTextField
                    id="company_code"
                    placeholder="Votre code entreprise"
                    label="Code entreprise :"
                    value={field.value}
                    onChange={field.onChange}
                    error={errors.company_code?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} lg={6} md={6}>
              <Controller
                name="email"
                control={control}
                defaultValue={data.email}
                render={({ field }) => (
                  <StyledInputTextField
                    id="email"
                    placeholder="Votre email"
                    label="Email :"
                    value={field.value}
                    onChange={field.onChange}
                    error={errors.email?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} lg={6} md={6}>
              <Controller
                name="registration_code"
                control={control}
                defaultValue={data.registration_code}
                render={({ field }) => (
                  <StyledInputPasswordField
                    id="registration_code"
                    placeholder="Code d’activation"
                    label="Code d’activation :"
                    value={field.value}
                    onChange={field.onChange}
                    error={errors.registration_code?.message}
                  />
                )}
              />
            </Grid>
          </Grid>

          <div
            className={`mb-[30px] mt-[72px] mx-auto ${
              scrWidth > 550 ? "w-[470px]" : "w-full"
            }`}
          >
            <StyledButton type="submit">INSCRIPTION</StyledButton>
          </div>
        </form>
        <div className="w-full flex justify-center">
          <button
            className="text-ageoNormal font-segoeSemi text-[20px] text-center cursor-pointer hover:text-linkHover rounded-[5px] h-[50px] transition-all"
            onClick={() => navigate("/")}
          >
            Se connecter
          </button>
        </div>
      </div>
    </WelcomeTemplate>
  );
};
