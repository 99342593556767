import { SET_MESSAGE, CLEAR_MESSAGE, SET_NOTIFY } from "./types";

export const setMessage = function setMessage(message) {
  return {
    type: SET_MESSAGE,
    payload: message,
  };
};

export const clearMessage = function clearMessage() {
  return {
    type: CLEAR_MESSAGE,
  };
};

export const setNotify = function setNotify(flag) {
  return {
    type: SET_NOTIFY,
    payload: flag,
  };
};

export const setSuccessMessage = function setMessage(res) {
  return {
    type: SET_MESSAGE,
    payload: {
      message: res.data?.message || "Succeed!!!",
      severity: "success",
    },
  };
};

export const setErrorMessage = function setMessage(err) {
  const message =
    (err.response && err.response.data && err.response.data.message) ||
    err.message ||
    err.toString();

  return {
    type: SET_MESSAGE,
    payload: {
      message,
      severity: "error",
    },
  };
};
