import { useNavigate } from "react-router";
import { StyledButton } from "../../Styled/StyledButton";
import { StyledTypographyTitle } from "../../Styled/StyledTypography";

export const Error404 = () => {
  const navigate = useNavigate();
  return (
    <div className="w-[100vw] h-[100vh] flex flex-col justify-center items-center bg-originBack">
      <div className="">
        <StyledTypographyTitle sx={{ fontWeight: "700 !important" }}>
          Oups...
        </StyledTypographyTitle>
      </div>
      <div className="">
        <img src="/404.png" alt="404" />
      </div>
      <div className="w-[320px]">
        <StyledButton onClick={() => navigate(-1)}>Retourner</StyledButton>
      </div>
    </div>
  );
};
