import axios from '../axios-orders';

class UserService {
  getUser() {
    return axios.post(`/api/user`);
  }

  loginWithToken() {
    return axios.post(`/api/login-with-token`);
  }

  getEmploymentForm() {
    return axios.get(`/api/user-employment-form`);
  }

  addEmploymentForm(form) {
    return axios.post(`/api/add-employment-form`, form, {
      headers: {
        'Content-Type': 'application/json',
      }
    });
  }

  getCompanyLogo() {
    return axios.get('/api/get-company-logo');
  }

  updateUser(userData) {
    return axios.post(`/api/user-update`, userData);
  }
}

const userService = new UserService();

export default userService;
