import { useCallback, useEffect, useState } from "react";
import {
  StyledTypographyInputLabel,
  StyledTypographyTitle,
} from "../../Styled/StyledTypography";
import { ReturnToIcon } from "../../utilities/icons/ReturnToIcon";
import { StyledInputTextField } from "../../Styled/StyledInput";
import { StyledButton, StyledWarningButton } from "../../Styled/StyledButton";
import { useNavigate, useParams } from "react-router-dom";
import clientService from "../../services/client.service";
import { useDispatch, useSelector } from "react-redux";
import { setErrorMessage, setSuccessMessage } from "../../actions/message";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import { Loading } from "../Loading";
import { ROLE } from "../../router/roles";
import { Box, Modal, Typography } from "@mui/material";
import { StyledCompanyLogoPicker } from "../../Styled/StyledCompanyLogoPicker";
import AdminTemplate from "../../components/AdminTemplate";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "white",
  boxShadow: 24,
  p: 4,
  outline: "none",
};

const schema = yup.object().shape({
  companyName: yup.string().required("Le nom de l'entreprise est requis"),
  companyCode: yup.string().required("Le code de l'entreprise est requis"),
  address: yup.string().required("L'adresse est requise"),
});

export const EditClientInfo = () => {
  const navigate = useNavigate();
  const { clientId } = useParams();
  const [clientData, setClientData] = useState(null);
  const [loadingClientData, setLoadingClientData] = useState(true);
  const dispatch = useDispatch();
  const { roles } = useSelector((state) => state.auth?.user ?? { roles: [] });
  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      avatar: clientData?.avatar || "",
      companyName: clientData?.companyName || "",
      companyCode: clientData?.companyCode || "",
      address: clientData?.address || "",
    },
  });

  const { companyName } = watch();

  const handleChangeClientData = useCallback(
    (data) => {
      setClientData(data);

      setValue("avatar", data?.avatar || "");
      setValue("companyName", data?.companyName || "");
      setValue("companyCode", data?.companyCode || "");
      setValue("address", data?.address || "");
    },
    [setClientData, setValue]
  );

  const onSubmit = (data) => {
    const clientInfo = {
      id: clientId,
      ...data,
    };

    clientService
      .updateClient(clientInfo)
      .then((res) => {
        handleChangeClientData(res.data.client);
        dispatch(setSuccessMessage(res));
      })
      .catch((err) => {
        dispatch(setErrorMessage(err));
      });
  };

  useEffect(() => {
    setLoadingClientData(true);
    clientService
      .getClient(clientId)
      .then((res) => {
        setLoadingClientData(false);
        handleChangeClientData(res.data);
      })
      .catch((err) => {
        setLoadingClientData(false);
        dispatch(setErrorMessage(err));
      });
  }, [dispatch, clientId, handleChangeClientData]);

  const handleRemoveClient = () => {
    clientService
      .removeClient(clientId)
      .then((res) => {
        dispatch(setSuccessMessage(res));

        navigate(`/manage-client`);
      })
      .catch((err) => {
        dispatch(setErrorMessage(err));
      });
  };

  if (loadingClientData === true) {
    return <Loading comment="Chargement des données client"></Loading>;
  }

  return (
    <AdminTemplate active={2}>
      <Modal
        open={openConfirmModal}
        onClose={() => setOpenConfirmModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography className="text-myerror font-700">
            Êtes-vous certain de vouloir supprimer la fiche client ?&nbsp;
          </Typography>
          <div className="w-full sm:w-[320px] my-[20px]">
            <StyledWarningButton
              onClick={() => {
                setOpenConfirmModal(false);
                handleRemoveClient();
              }}
              type="button"
            >
              Oui
            </StyledWarningButton>
          </div>
          <div className="w-full sm:w-[320px]">
            <StyledButton
              type="button"
              onClick={() => setOpenConfirmModal(false)}
            >
              Non
            </StyledButton>
          </div>
        </Box>
      </Modal>
      <div className="h-[63px]"></div>
      <div className="mb-[78px] px-[5vw]">
        <StyledTypographyTitle
          sx={{
            fontWeight: "300 !important",
            display: "flex",
            flexWrap: "wrap",
            textAlign: "center",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Fiche&nbsp;:&nbsp;
          <span className="font-segoeSemi">{companyName?.toUpperCase()}</span>
        </StyledTypographyTitle>
      </div>
      <div
        className="flex justify-center items-center mb-16 hover:cursor-pointer"
        onClick={() => navigate("/manage-client")}
      >
        <ReturnToIcon />
        <StyledTypographyInputLabel
          sx={{
            color: "#2574A9 !important",
            fontWeight: "700 !important",
            paddingLeft: "5px",
          }}
        >
          Retour au listing
        </StyledTypographyInputLabel>
      </div>
      <div
        className={`flex m-auto max-w-[640px] w-full items-center px-[20px]`}
      >
        <div className="w-full">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="flex justify-center">
              <Controller
                name="avatar"
                control={control}
                render={({ field }) => (
                  <StyledCompanyLogoPicker
                    id="avatar"
                    onChange={field.onChange}
                    value={field.value}
                  />
                )}
              />
            </div>
            <div className="flex flex-col space-y-4">
              <Controller
                name="companyName"
                control={control}
                render={({ field }) => (
                  <StyledInputTextField
                    id="companyName"
                    label="Nom de l’entreprise :"
                    fontWeight={700}
                    color="#2574A9"
                    onChange={field.onChange}
                    value={field.value}
                    error={errors.companyName?.message}
                  />
                )}
              />
              <Controller
                name="address"
                control={control}
                render={({ field }) => (
                  <StyledInputTextField
                    id="address"
                    label="Adresse :"
                    fontWeight={700}
                    color="#2574A9"
                    onChange={field.onChange}
                    value={field.value}
                    error={errors.address?.message}
                  />
                )}
              />
              <div className="w-[50%]">
                <Controller
                  name="companyCode"
                  control={control}
                  render={({ field }) => (
                    <StyledInputTextField
                      id="companyCode"
                      label="Code entreprise :"
                      placeholder="Votre code entreprise"
                      fontWeight={700}
                      color="#2574A9"
                      onChange={field.onChange}
                      value={field.value}
                      error={errors.companyCode?.message}
                    />
                  )}
                />
              </div>
            </div>
            <div className="h-[52px]"></div>
            <div className="mb-[30px] mx-auto w-full sm:w-[318px]">
              {roles && roles.includes(ROLE.AGEO) && (
                <StyledWarningButton onClick={() => setOpenConfirmModal(true)}>
                  Supprimer fiche client
                </StyledWarningButton>
              )}
            </div>

            <div className="mb-[90px] mx-auto w-full sm:w-[318px]">
              <StyledButton type="submit">
                MODIFIER MES INFORMATIONS
              </StyledButton>
            </div>
          </form>
          <div className="flex justify-between flex-wrap items-end gap-x-[1.5vw] xl:mr-[100px]">
            <div className="flex-none w-full sm:w-[320px]">
              <StyledTypographyInputLabel
                sx={{
                  textAlign: "left",
                }}
              >
                Administrateurs :
              </StyledTypographyInputLabel>
            </div>

            {clientData?.users.map((hr, _) => {
              return (
                <div key={_} className="flex flex-row">
                  <div className="flex-grow">
                    <StyledInputTextField
                      defaultvalue={`${hr.firstName} ${hr.lastName}`}
                      readonly={true}
                    />
                  </div>
                  <div className="flex">
                    <button
                      className="min-w-[200px] text-ageoNormal font-segoeBold hover:text-ageoHover"
                      onClick={() =>
                        navigate(`/manage-hr/edit/${clientId}/${hr.id}`)
                      }
                    >
                      ACCÉDER / MODIFIER
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="mt-[5.4vh] flex mx-auto w-full sm:w-[318px]">
            <StyledButton
              onClick={() => navigate(`/manage-hr/add/${clientId}`)}
            >
              Ajouter un administrateur
            </StyledButton>
          </div>
        </div>
      </div>
    </AdminTemplate>
  );
};
