export const EmploymentFormIcon = ({
  width = 45,
  height = 49,
  color = "#EB4C37",
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
  >
    <g clipPath="url(#clip0_82_1039)">
      <path
        d="M8.65386 12.25H25.9615C26.9177 12.25 27.6923 11.4667 27.6923 10.5C27.6923 9.53327 26.9177 8.75 25.9615 8.75H8.65386C7.69776 8.75 6.9231 9.53327 6.9231 10.5C6.9231 11.4667 7.69776 12.25 8.65386 12.25Z"
        fill={color}
      />
      <path
        d="M8.65386 19.25H25.9615C26.9177 19.25 27.6923 18.4667 27.6923 17.5C27.6923 16.5333 26.9177 15.75 25.9615 15.75H8.65386C7.69776 15.75 6.9231 16.5333 6.9231 17.5C6.9231 18.4667 7.69776 19.25 8.65386 19.25Z"
        fill={color}
      />
      <mask
        id="mask0_82_1039"
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width={width}
        height={height}
      >
        <path d="M45 0H0V49H45V0Z" fill="white" />
      </mask>
      <g mask="url(#mask0_82_1039)">
        <path
          d="M15.5769 22.75H8.65386C7.69776 22.75 6.9231 23.5333 6.9231 24.5C6.9231 25.4667 7.69776 26.25 8.65386 26.25H15.5769C16.533 26.25 17.3077 25.4667 17.3077 24.5C17.3077 23.5333 16.533 22.75 15.5769 22.75Z"
          fill={color}
        />
        <path
          d="M45 19.9746C45.0039 18.5818 44.4568 17.2454 43.4805 16.2627C41.4252 14.2797 38.1918 14.2797 36.1366 16.2627L34.6154 17.8007V5.25C34.6154 2.3504 32.2908 0 29.4231 0H5.1923C2.32456 0 0 2.3504 0 5.25V43.75C0 46.6496 2.32456 49 5.1923 49H29.4231C32.2908 49 34.6154 46.6496 34.6154 43.75V32.6479L43.4788 23.6883C44.4562 22.7055 45.0044 21.3679 45 19.9746ZM31.1538 43.75C31.1538 44.7167 30.3792 45.5 29.4231 45.5H5.1923C4.2362 45.5 3.46153 44.7167 3.46153 43.75V5.25C3.46153 4.28327 4.2362 3.5 5.1923 3.5H29.4231C30.3792 3.5 31.1538 4.28327 31.1538 5.25V21.3007L19.8329 32.7459C19.5962 32.9857 19.4334 33.2894 19.3641 33.6209L18.062 39.8877C17.9544 40.4039 18.0823 40.9415 18.4108 41.3518C18.7393 41.7619 19.2334 42 19.7551 42C19.8745 42 19.994 41.9875 20.1117 41.963L26.3091 40.6454C26.6377 40.5758 26.9384 40.4101 27.1745 40.1696L31.1556 36.1445L31.1538 43.75ZM41.0313 21.2119L25.0961 37.3276L21.9963 37.9856L22.6471 34.853L38.5856 18.7372C39.2697 18.0737 40.3491 18.0737 41.033 18.7372C41.7086 19.4209 41.7086 20.5284 41.033 21.2119H41.0313Z"
          fill={color}
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_82_1039">
        <rect width={width} height={height} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
