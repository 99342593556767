import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import { LegalInformation } from "../mainpage/LegalInformation";
import { CookiePolicy } from "../mainpage/CookiePolicy";
import { TermsOfUse } from "../mainpage/TermsOfUse";
import Login from "../mainpage/connection/Login";
import Activation from "../mainpage/connection/Activation";
import ForgotPassword from "../mainpage/connection/ForgotPassword";
import { ResetPassword } from "../mainpage/connection/ResetPassword";
import { Description } from "../mainpage/connection/Description";
import { Welcome } from "../mainpage/Welcome";
import { RetirementDashboard } from "../mainpage/admin/RetirementDashboard";
import { ManageClient } from "../mainpage/admin/ManageClient";
import { EditClientInfo } from "../mainpage/admin/EditClientInfo";
import { AddHR } from "../mainpage/admin/AddHR";
import { EditHR } from "../mainpage/admin/EditHR";
import { ManageAdmin } from "../mainpage/admin/ManageAdmin";
import { AddSuperAdmin } from "../mainpage/admin/AddSuperAdmin";
import { MyProfile } from "../mainpage/admin/MyProfile";
import { EmploymentForm } from "../mainpage/EmploymentForm";
import { Profile } from "../mainpage/Profile";
import { InviteForm } from "../mainpage/InviteForm";
import PrivateRoute from "./PrivateRoute";
import { ROLE } from "./roles";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loginWithToken } from "../actions/auth";
import { Logout } from "../mainpage/Logout";
import { Loading } from "../mainpage/Loading";
import { Error404 } from "../mainpage/Error/Error404";
import { EditAgeoInfo } from "../mainpage/admin/EditAgeoInfo";
import { AddClientInfo } from "../mainpage/admin/AddClientInfo";
import ActivationHR from "../mainpage/connection/ActivationHR";

export const PageRouter = () => {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.auth);

  useEffect(() => {
    setTimeout(() => {
      dispatch(loginWithToken());
    }, 200);
  }, [dispatch]);

  if (loading === true) {
    return <Loading comment="Chargement des données utilisateur.." />;
  }

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/description" element={<Description />} />
        <Route path="/activation" element={<Activation />} />
        <Route path="/activation-hr" element={<ActivationHR />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password/:token" element={<ResetPassword />} />
        <Route path="/legalinformation" element={<LegalInformation />}></Route>
        <Route path="/cookiepolicy" element={<CookiePolicy />}></Route>
        <Route path="/termsofuse" element={<TermsOfUse />}></Route>

        {/* <Route
          path="/legalinformation"
          element={
            <PrivateRoute roles={[ROLE.USER, ROLE.HR, ROLE.AGEO, ROLE.ADMIN]}>
              <LegalInformation />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path="/cookiepolicy"
          element={
            <PrivateRoute roles={[ROLE.USER, ROLE.HR, ROLE.AGEO, ROLE.ADMIN]}>
              <CookiePolicy />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path="/termsofuse"
          element={
            <PrivateRoute roles={[ROLE.USER, ROLE.HR, ROLE.AGEO, ROLE.ADMIN]}>
              <TermsOfUse />
            </PrivateRoute>
          }
        ></Route> */}

        <Route
          path="/welcome"
          element={
            <PrivateRoute roles={[ROLE.USER, ROLE.HR, ROLE.CLIENT]}>
              <Welcome />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path="/manage-client/edit-client-info/:clientId"
          element={
            <PrivateRoute roles={[ROLE.AGEO, ROLE.ADMIN]}>
              <EditClientInfo />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path="/manage-client"
          element={
            <PrivateRoute roles={[ROLE.AGEO, ROLE.ADMIN]}>
              <ManageClient />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path="/manage-client/add-client"
          element={
            <PrivateRoute roles={[ROLE.AGEO, ROLE.ADMIN]}>
              <AddClientInfo />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path="/manage-hr/add/:clientId"
          element={
            <PrivateRoute roles={[ROLE.AGEO, ROLE.ADMIN]}>
              <AddHR />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path="/manage-hr/edit/:clientId/:hrId"
          element={
            <PrivateRoute roles={[ROLE.AGEO, ROLE.ADMIN]}>
              <EditHR />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path="/manage-admin/add-super-admin"
          element={
            <PrivateRoute roles={[ROLE.AGEO, ROLE.ADMIN]}>
              <AddSuperAdmin />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path="/manage-admin/edit-ageo-info/:userId"
          element={
            <PrivateRoute roles={[ROLE.AGEO, ROLE.ADMIN]}>
              <EditAgeoInfo />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path="/manage-admin"
          element={
            <PrivateRoute roles={[ROLE.AGEO, ROLE.ADMIN]}>
              <ManageAdmin />
            </PrivateRoute>
          }
        ></Route>

        <Route
          path="/employment-form"
          element={
            <PrivateRoute roles={[ROLE.USER]}>
              <EmploymentForm />
            </PrivateRoute>
          }
        ></Route>

        <Route
          path="/invite"
          element={
            <PrivateRoute roles={[ROLE.CLIENT, ROLE.HR]}>
              <InviteForm />
            </PrivateRoute>
          }
        ></Route>

        <Route
          path="/profile"
          element={
            <PrivateRoute roles={[ROLE.USER, ROLE.AGEO, ROLE.HR, ROLE.ADMIN]}>
              <Profile />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path="/my-profile"
          element={
            <PrivateRoute roles={[ROLE.USER, ROLE.HR, ROLE.AGEO, ROLE.ADMIN]}>
              <MyProfile />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path="/dashboard"
          element={
            <PrivateRoute roles={[ROLE.AGEO, ROLE.ADMIN]}>
              <RetirementDashboard />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path="/logout"
          element={
            <PrivateRoute roles={[ROLE.USER, ROLE.AGEO, ROLE.ADMIN, ROLE.HR]}>
              <Logout />
            </PrivateRoute>
          }
        ></Route>

        <Route path="/*" element={<Error404 />} />
      </Routes>
    </Router>
  );
};
