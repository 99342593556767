import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import { ErrorAccessDenied } from "../mainpage/Error/ErrorAccessDenied";
import { Loading } from "../mainpage/Loading";

const PrivateRoute = ({ children, roles }) => {
  let location = useLocation();
  const { isLoggedIn, user, loading, authChecked } = useSelector((state) => state.auth);

  if (loading === true || authChecked === false) {
    return <Loading comment="Vérification d'authentification..." />
  }

  if (authChecked === true && isLoggedIn === false) {
    return <Navigate to="/" state={{ from: location }} />;
  }

  const commonRoles = roles.filter((role) => user.roles?.includes(role));

  const userHasRequiredRole = user.roles && commonRoles.length > 0 ? true : false;

  if (isLoggedIn === true && !userHasRequiredRole) {
    return <ErrorAccessDenied />; // build your won access denied page (sth like 404)
  }
  // return null;

  return children;
};

export default PrivateRoute;