import React, { useEffect, useState } from "react";
import { Password } from "./Password";
import { Description } from "./Description";
import { activation } from "../../actions/auth";
import { useDispatch, useSelector } from "react-redux";
import { ROLE } from "../../router/roles";
import { useNavigate } from "react-router-dom";
import authService from "../../services/auth.service";
import { setErrorMessage, setSuccessMessage } from "../../actions/message";

const Activation = () => {
  const [form0, setForm0] = useState({});
  const [form1, setForm1] = useState({});
  const [step, setStep] = useState(0);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isLoggedIn, user } = useSelector((state) => state.auth);

  useEffect(() => {
    if (isLoggedIn === true && user?.roles) {
      if (user.roles.includes(ROLE.AGEO)) {
        navigate("/dashboard");
      } else if (user.roles.includes(ROLE.ADMIN)) {
        navigate("/dashboard");
      } else if (user.roles.includes(ROLE.HR)) {
        navigate("/welcome");
      } else if (user.roles.includes(ROLE.CLIENT)) {
        navigate("/welcome");
      } else if (user.roles.includes(ROLE.USER)) {
        navigate("/welcome");
      }
    }
  }, [isLoggedIn, navigate, user]);

  const handleSubmit = (lastForm) => {
    const final = {
      ...form0,
      ...lastForm,
    };

    dispatch(activation(final));
  };

  return (
    <div>
      {step === 0 && (
        <Description
          onSubmit={(data) => {
            setForm0(data);

            authService
              .activateCheck(data)
              .then((res) => {
                dispatch(setSuccessMessage(res));
                setStep(1);
              })
              .catch((err) => {
                dispatch(setErrorMessage(err));
              });
          }}
          form={form0}
        />
      )}
      {step === 1 && (
        <Password
          onSubmit={(data) => {
            setForm1(data);
            handleSubmit(data);
          }}
          form={form1}
        />
      )}
    </div>
  );
};

export default Activation;
