import { CameraAlt } from "@mui/icons-material";
import { Avatar, Badge, IconButton } from "@mui/material";
import { useRef } from "react";
import fileService from "../services/file.service";
import { setErrorMessage } from "../actions/message";
import { useDispatch } from "react-redux";

import("screw-filereader");

export const StyledCompanyLogoPicker = ({
  onChange,
  value,
  readonly = false,
}) => {
  const imageRef = useRef();
  const dispatch = useDispatch();

  const showOpenFileDialog = () => {
    imageRef.current.click();
  };

  const compressImage = (file, maxWidth, maxHeight) => {
    return new Promise((resolve, reject) => {
      let img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = () => {
        let width = img.naturalWidth;
        let height = img.naturalHeight;

        if (width > maxWidth || height > maxHeight) {
          let ratio = Math.min(maxWidth / width, maxHeight / height);
          width = width * ratio;
          height = height * ratio;
        }

        let canvas = document.createElement("canvas");
        canvas.width = width;
        canvas.height = height;

        let ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);

        canvas.toBlob((blob) => {
          resolve(blob);
        }, file.type);
      };
    });
  };

  const handleSelectAvatar = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    compressImage(file, 300, 300)
      .then((compressedBlob) => {
        // Here you can use the compressedBlob, for example, you can upload it to the server
        // You can also create a new File object from the blob and use it as needed
        const compressedFile = new File([compressedBlob], file.name, {
          type: file.type,
          lastModified: Date.now(),
        });
        // Perform any action with the compressed file

        fileService
          .uploadFiles({
            avatar: compressedFile,
            file_type: "avatars",
          })
          .then((res) => {
            onChange(res.data.urls.avatar);
          })
          .catch((err) => {
            dispatch(setErrorMessage(err));
          });
      })
      .catch((error) => {
        // Handle errors if the compression fails
      });
  };

  return (
    <div>
      <Badge
        overlap="circular"
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        badgeContent={
          !readonly && (
            <IconButton onClick={showOpenFileDialog}>
              <Avatar
                alt="Remy Sharp"
                sx={{
                  background: "#F7F9FB",
                  boxShadow:
                    "0px 0px 35px 0px rgba(219, 224, 240, 0.50) !important",
                  width: "47px",
                  height: "47px",
                  border: "2px solid #eee",
                }}
              >
                <CameraAlt sx={{ color: "#4f7793" }} />
              </Avatar>
            </IconButton>
          )
        }
      >
        <div
          style={{
            width: "150px",
            height: "150px",
            borderRadius: "100%",
            overflow: "hidden",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={
              !!value
                ? `${process.env.REACT_APP_API_URL}${value}`
                : "https://millerconsulting.codes/default.jpg"
            }
            alt="avatar"
          />
        </div>
      </Badge>
      <input
        ref={imageRef}
        type="file"
        style={{ display: "none" }}
        accept="image/*"
        onChange={handleSelectAvatar}
      />
    </div>
  );
};
