import {
  StyledTypographyInputLabel,
  StyledTypographyTitle,
} from "../Styled/StyledTypography";
import { StyledButton } from "../Styled/StyledButton";
import { StyledInputTextField } from "../Styled/StyledInput";
import { useNavigate } from "react-router-dom";
import { StyledAvatarPicker } from "../Styled/StyledAvatarPicker";
import { useDispatch, useSelector } from "react-redux";
import userService from "../services/user.service";
import { setErrorMessage, setSuccessMessage } from "../actions/message";
import { setUserData } from "../actions/auth";
import { ROLE } from "../router/roles";
import UserTemplate from "../components/UserTemplate";

export const Profile = () => {
  const navigate = useNavigate();
  const {
    firstname,
    lastname,
    phone_number,
    company_code,
    email,
    avatar,
    roles,
  } = useSelector((state) => state.auth?.user ?? { roles: [] });
  const dispatch = useDispatch();

  const handleImageChange = (selectedAvatar) => {
    let userInfo = {
      avatar: selectedAvatar,
      firstname,
      lastname,
      phone_number,
      company_code,
      email,
    };

    userService
      .updateUser(userInfo)
      .then((res) => {
        dispatch(setSuccessMessage(res));
        userService.getUser().then((res) => {
          dispatch(setUserData(res.data.user));
        });
      })
      .catch((err) => {
        dispatch(setErrorMessage(err));
      });
  };

  return (
    <UserTemplate active={3}>
      <div className="relative w-full flex flex-col items-center sm:max-w-[658px] mx-auto">
        <div className="mt-[92px]">
          <StyledTypographyTitle
            sx={{
              fontWeight: "300 !important",
              display: "flex",
              flexWrap: "wrap",
              textAlign: "center",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            Mon profil
          </StyledTypographyTitle>
        </div>
        <div className="mt-[37px]">
          <StyledAvatarPicker onChange={handleImageChange} value={avatar} />
        </div>

        <div className="flex justify-center flex-wrap gap-x-4 gap-y-4 mt-8">
          <div className="w-full sm:w-[320px]">
            <StyledInputTextField
              label="Prénom :"
              placeholder="Votre prénom"
              value={firstname}
            />
          </div>
          <div className="w-full sm:w-[320px]">
            <StyledInputTextField
              label="Nom :"
              placeholder="Votre nom"
              value={lastname}
            />
          </div>

          <div className="w-full sm:w-[320px]">
            <StyledInputTextField
              label="Téléphone :"
              placeholder="+33 __ __ __ __ __"
              value={phone_number}
            />
          </div>
          <div className="w-full sm:w-[320px]">
            <StyledInputTextField
              label="Code entreprise :"
              placeholder="Votre code entreprise"
              value={company_code}
            />
          </div>

          <div className="w-full sm:w-[320px]">
            <StyledInputTextField
              label="Email :"
              placeholder="Votre email"
              value={email}
            />
          </div>

          <div className="w-full sm:w-[320px]"></div>

          {roles && roles.includes(ROLE.HR) && (
            <div className="mt-[4vh]">
              <StyledTypographyInputLabel>
                Inviter un utilisateur à remplir le formulaire :
              </StyledTypographyInputLabel>
            </div>
          )}
        </div>

        {roles && roles.includes(ROLE.HR) && (
          <div className="flex justify-center mt-4 w-full">
            <div className="w-full sm:w-[320px]">
              <StyledButton onClick={() => navigate("/invite")}>
                ENVOYER UNE INVITATION
              </StyledButton>
            </div>
          </div>
        )}
        <div className="h-[30px]"></div>
      </div>
    </UserTemplate>
  );
};
