import { Box } from "@mui/material";
import { StyledTypographySmallText } from "../../Styled/StyledTypography";

export const StepButton = ({ step = 1 }) => {
  return (
    <Box
      sx={{
        width: "149px",
        height: "36px",
        borderRadius: "100px",
        border: "3px solid #2574A9",
        background: "#fff",
        textAlign: 'center',
        paddingTop: '3px',
        "&:hover": {
          background: "#2574A9",
        },
        "&:hover .MuiTypography-root": {
          color: "#fff !important",
        },
      }}
    >
      <StyledTypographySmallText sx={{ fontFamily: "Segoe UI Bold !important" }}>
        Etape&nbsp;{step}/7
      </StyledTypographySmallText>
    </Box>
  );
};
