import {
  REGISTER_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  SET_MESSAGE,
  AUTH_START,
  SET_NOTIFY,
  SET_USER,
  REFRESH_TOKEN_SUCCESS,
  LOGIN_TOKEN_SUCCESS,
} from "./types";

import authService from "../services/auth.service";
import userService from "../services/user.service";
import { ROLE } from "../router/roles";
import { setErrorMessage, setSuccessMessage } from "./message";

export const activation = (user) => (dispatch) => {
  return authService.activate(user).then(
    (response) => {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: {
          user: response.data.user,
          token: response.data.token,
          refresh_token: response.data.refresh_token,
        },
      });

      dispatch({
        type: SET_MESSAGE,
        payload: {
          message: "Utilisateur activé avec succès",
          severity: "success",
        },
      });

      dispatch({
        type: SET_NOTIFY,
        payload: true,
      });
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: REGISTER_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: {
          message: message,
          severity: "error",
        },
      });
    }
  );
};

export const activationHR = (user) => (dispatch) => {
  return authService.activateHR(user).then(
    (response) => {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: {
          user: response.data.user,
          token: response.data.token,
          refresh_token: response.data.refresh_token,
        },
      });

      dispatch({
        type: SET_MESSAGE,
        payload: {
          message: "Utilisateur activé avec succès",
          severity: "success",
        },
      });

      dispatch({
        type: SET_NOTIFY,
        payload: true,
      });
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: REGISTER_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: {
          message: message,
          severity: "error",
        },
      });
    }
  );
};

export const login = (email, password) => (dispatch) => {
  return authService.login(email, password).then(
    (res) => {
      if (res.data?.user?.firstname && res.data?.user?.lastname) {
        dispatch({
          type: LOGIN_SUCCESS,
          payload: {
            user: res.data.user,
            token: res.data.token,
            refresh_token: res.data.refresh_token,
          },
        });

        dispatch({
          type: SET_MESSAGE,
          payload: {
            message: `Bonjour ${res.data.user.firstname} ${res.data.user.lastname}`,
            severity: "success",
          },
        });
      } else {
        dispatch({
          type: LOGIN_FAIL,
        });
      }

      if (
        res.data?.user?.roles[0] === ROLE.USER ||
        res.data?.user?.roles[0] === ROLE.HR
      ) {
        dispatch({
          type: SET_NOTIFY,
          payload: true,
        });
      }

      return res;
    },
    (error) => {
      dispatch({
        type: LOGIN_FAIL,
      });

      return Promise.reject(error);
    }
  );
};

export const logout = () => (dispatch, getState) => {
  const { refresh_token } = getState().auth;
  authService
    .logout(refresh_token)
    .then((res) => {
      sessionStorage.removeItem("employmentData");
      dispatch({
        type: LOGOUT,
      });
    })
    .catch((err) => {
      dispatch({
        type: LOGOUT,
      });
    });
};

export const loginWithToken = () => (dispatch) => {
  const token = localStorage.getItem("token");

  if (!token) {
    dispatch({
      type: LOGIN_FAIL,
    });
    return;
  }

  dispatch({
    type: AUTH_START,
  });
  userService
    .loginWithToken()
    .then((res) => {
      dispatch({
        type: LOGIN_TOKEN_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(logout());
      dispatch({
        type: LOGIN_FAIL,
      });
    });
};

export const forgotPassword = (email) => (dispatch) => {
  authService
    .forgotPassword(email)
    .then((res) => {
      dispatch(setSuccessMessage(res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const resetPassword = (password, token) => (dispatch) => {
  return new Promise((resolve, reject) => {
    authService
      .resetPassword(password, token)
      .then((res) => {
        setSuccessMessage(res);
        resolve();
      })
      .catch((err) => {
        setErrorMessage(err);
        reject();
      });
  });
};

export const setUserData = (data) => (dispatch) => {
  dispatch({
    type: SET_USER,
    payload: data,
  });
};

export const refreshToken = () => (dispatch, getState) => {
  const { refresh_token } = getState().auth;

  return authService
    .refreshToken(refresh_token)
    .then((response) => {
      const { token } = response.data;
      dispatch({
        type: REFRESH_TOKEN_SUCCESS,
        payload: { token },
      });

      return response;
    })
    .catch((err) => {
      Promise.reject(err);
    });
};
