import { useEffect, useState } from "react";
import { StyledButton } from "../Styled/StyledButton";
import { StyledTypographyContentText } from "../Styled/StyledTypography";
import { Button } from "@mui/material";
import { EmploymentFormIcon } from "../utilities/icons/EmploymentFormIcon";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { ROLE } from "../router/roles";
import UserTemplate from "../components/UserTemplate";

export const Welcome = () => {
  const navigate = useNavigate();
  const [scrWidth, setScrWidth] = useState(window.innerWidth);

  const { roles } = useSelector((state) => state.auth?.user ?? { roles: [] });

  useEffect(() => {
    console.log("Initializing welcome page...");
    const handleResize = () => {
      setScrWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleGo = () => {
    console.log("handle goo");
    if (roles?.includes(ROLE.HR)) {
      navigate("/profile");
    } else {
      navigate("/employment-form");
    }
  };

  return (
    <UserTemplate>
      <div className="overflow-hidden">
        <div
          className="absolute aspect-[1406/810] top-0 right-[-7.4%] bg-paperColor"
          style={{
            backgroundImage: "url(/Accueil_003.jpg)",
            backgroundSize: "contain",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            display: scrWidth >= 1280 ? "flex" : "none",
            height: "calc(100vh - 160px)",
          }}
        ></div>
        <div className="relative">
          <div className="relative px-[16vw] sm:px-[16vw] xl:px-0 xl:pl-[16vw] pt-[9.55vh] xl:w-[50vw] bg-originBack">
            <div className="text-[40px] font-segoeLight text-ageoTitle">
              {roles?.includes(ROLE.HR) ? (
                <span>
                  Bienvenue sur votre espace transition emploi retraite avec
                  AGEO
                </span>
              ) : (
                <span>
                  Votre transition
                  <br />
                  emploi retraite avec AGEO
                </span>
              )}
            </div>
            <div className="mt-[3.3vh] w-full xl:w-[28.125vw]">
              {roles?.includes(ROLE.HR) ? (
                <StyledTypographyContentText>
                  <span>
                    Depuis “Mon profil”, invitez les salariés concernés à
                    remplir leur formulaire d’étude transition emploi retraite.
                    <br />
                    <br />
                    Des questions ? Contactez votre interlocuteur AGEO Retraite.
                  </span>
                </StyledTypographyContentText>
              ) : (
                <StyledTypographyContentText>
                  <span>
                    Le groupe AGEO a été choisi par votre employeur pour
                    réaliser votre bilan de transition emploi-retraite. <br />
                    <br />
                    Dans cette optique, nous étudierons les informations que
                    vous nous transmettrez concernant votre vie personnelle et
                    votre parcours professionnel.
                    <br />
                    <br />
                    Ces données personnelles ne seront pas transmises aux
                    Ressources Humaines de votre entreprise, sauf si vous en
                    faites le choix une fois l’analyse de faisabilité effectuée
                    par AGEO.
                  </span>
                </StyledTypographyContentText>
              )}
            </div>
            <div className="mt-[48px] flex justify-between items-center mb-[50px]">
              {scrWidth > 640 && (
                <Button>
                  <EmploymentFormIcon />
                </Button>
              )}
              <StyledButton onClick={handleGo}>
                {roles?.includes(ROLE.HR)
                  ? "Mon profil"
                  : "Remplir mon formulaire transition emploi retraite"}
              </StyledButton>
            </div>
          </div>
        </div>
      </div>
    </UserTemplate>
  );
};
