import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { logout } from "../actions/auth";
import { Loading } from "./Loading";

export const Logout = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    setTimeout(() => {
      dispatch(logout());
    }, 1000);
  }, [dispatch]);
  return <Loading comment="Déconnecter..." />;
};
