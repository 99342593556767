import React from "react";
import { AdminSidebar } from "../layout/AdminSidebar";
import Footer from "../layout/Footer";

const AdminTemplate = (props) => {
  return (
    <div className="flex justify-stretch">
      <div className="relative lg:w-[400px] lg:block lg:flex-none">
        <AdminSidebar active={props.active} />
      </div>
      <div className="w-full h-[100vh] customScroll bg-originBack">
        <div className="relative" style={{ minHeight: "calc(100vh - 100px)" }}>
          {props.children}
        </div>
        <div className="min-h-[100px] flex items-center justify-center">
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default AdminTemplate;
