import { useEffect, useState } from "react";
import {
  StyledTypographyClientList,
  StyledTypographyInputLabel,
  StyledTypographyTitle,
} from "../../Styled/StyledTypography";
import { AddCircleOutline } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import adminService from "../../services/admin.service";
import { useDispatch } from "react-redux";
import { setErrorMessage } from "../../actions/message";
import { ROLE } from "../../router/roles";
import { Loading } from "../Loading";
import AdminTemplate from "../../components/AdminTemplate";

export const ManageAdmin = () => {
  const navigate = useNavigate();
  const [scrWidth, setScrWidth] = useState(window.innerWidth);
  const [clients, setClients] = useState([]);
  const [loadingAdminList, setLoadingAdminList] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const handleResize = () => {
      setScrWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    setLoadingAdminList(true);

    adminService
      .getAdminList()
      .then((res) => {
        setClients(res.data.users);
        setLoadingAdminList(false);
      })
      .catch((err) => {
        dispatch(setErrorMessage(err));
        setLoadingAdminList(false);
      });

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [dispatch]);

  if (loadingAdminList === true) {
    return <Loading comment="Chargement de la liste des administrateurs..." />;
  }

  return (
    <AdminTemplate active={3}>
      <div className="h-[63px]"></div>
      <div className="mb-[81px] px-[5vw]">
        <StyledTypographyTitle
          sx={{
            display: "flex",
            flexWrap: "wrap",
            textAlign: "center",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Administration&nbsp;
          <span className="font-segoeSemi">AGEO Retraite</span>
        </StyledTypographyTitle>
      </div>
      <div
        className="flex justify-center items-center mb-[88px] hover:cursor-pointer"
        onClick={() => navigate("/manage-admin/add-super-admin")}
      >
        <AddCircleOutline
          sx={{ color: "#EB4C37", width: "25px", height: "25px" }}
        />
        <StyledTypographyInputLabel
          sx={{
            color: "#2574A9 !important",
            fontWeight: "700 !important",
            paddingLeft: "5px",
          }}
        >
          Ajouter un admin AGEO Retraite
        </StyledTypographyInputLabel>
      </div>
      <div className={`w-full ${scrWidth < 1700 ? "px-[10vw]" : "px-[20vw]"}`}>
        <div className="sampleScroll w-full">
          <div className="min-w-[500px] w-full">
            <TableContainer
              component={Paper}
              sx={{ boxShadow: "none", background: "transparent !important" }}
            >
              <Table
                sx={{
                  "& .MuiTableCell-root": {
                    padding: 0,
                    borderBottom: "2px solid #9EACC9",
                  },
                }}
                aria-label="simple table"
              >
                <TableHead>
                  <TableRow
                    sx={{
                      "& .MuiTableCell-root": {
                        paddingBottom: "30px",
                      },
                    }}
                  >
                    <TableCell align="center">
                      <StyledTypographyClientList>
                        NOM
                      </StyledTypographyClientList>
                    </TableCell>
                    <TableCell align="center">
                      <StyledTypographyClientList>
                        PRÉNOM
                      </StyledTypographyClientList>
                    </TableCell>
                    <TableCell align="center">
                      <StyledTypographyClientList>
                        DROITS
                      </StyledTypographyClientList>
                    </TableCell>
                    <TableCell align="center">
                      <StyledTypographyClientList>
                        ACTIONS
                      </StyledTypographyClientList>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {clients.map((client, key) => (
                    <TableRow key={key}>
                      <TableCell align="left">
                        <StyledTypographyClientList>
                          {client.lastname}
                        </StyledTypographyClientList>
                      </TableCell>
                      <TableCell align="left">
                        <StyledTypographyClientList>
                          {client.firstname}
                        </StyledTypographyClientList>
                      </TableCell>
                      <TableCell align="left">
                        <StyledTypographyClientList
                          sx={{ color: "#EB4C37 !important" }}
                        >
                          {client.roles.includes(ROLE.AGEO)
                            ? "SUPER ADMIN"
                            : "ADMIN"}
                        </StyledTypographyClientList>
                      </TableCell>
                      <TableCell align="center" className="cursor-pointer">
                        <StyledTypographyClientList
                          sx={{
                            color: "#2574A9 !important",
                            "&:hover": {
                              color: "#1E608D !important",
                            },
                          }}
                          onClick={() =>
                            navigate(
                              `/manage-admin/edit-ageo-info/${client.id}`
                            )
                          }
                        >
                          ACCÉDER / MODIFIER
                        </StyledTypographyClientList>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
    </AdminTemplate>
  );
};
