import axios from '../axios-orders';

class HRService {
  getHRList(page = 1) {
    return axios.get(`/api/hr-list?page=${page}`);
  }

  addHR(userData) {
    return axios.post('/api/hr', userData);
  }

  updateHR(userData) {
    return axios.post(`/api/hr-update/${userData.id}`, userData);
  }

  removeHR(userId) {
    return axios.delete(`/api/hr/${userId}`);
  }

  getHR(userId) {
    return axios.get(`/api/hr/${userId}`);
  }

  sendInvitation(invitationData) {
    return axios.post('/api/hr-invite', invitationData);
  }
}

const hrService = new HRService();

export default hrService;
