import { StyledTypographyTitle } from "../Styled/StyledTypography";
import { StyledButton } from "../Styled/StyledButton";
import {
  StyledInputTextField,
  StyledInputTelephoneField,
} from "../Styled/StyledInput";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import { emailRegex, generateRegistrationCode } from "../utils";
import hrService from "../services/hr.service";
import { useDispatch } from "react-redux";
import { setErrorMessage, setSuccessMessage } from "../actions/message";
import UserTemplate from "../components/UserTemplate";

const schema = yup.object().shape({
  firstname: yup.string().required("Le prénom est requis"),
  lastname: yup.string().required("Le nom de famille est requis"),
  phone_number: yup.string().required("Le numéro de téléphone est requis"),
  email: yup
    .string()
    .matches(emailRegex, "Adresse e-mail invalide")
    .required("L'e-mail est requis"),
  company_code: yup.string().required("Le code de l'entreprise est requis"),
  registration_code: yup.string().required("Le Code d’activation est requis"),
});

export const InviteForm = ({ avatar_link = "" }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    const invitationData = {
      firstname: data.firstname,
      lastname: data.lastname,
      phone_number: data.phone_number,
      company_code: data.company_code,
      email: data.email,
      registration_code: data.registration_code,
    };

    hrService
      .sendInvitation(invitationData)
      .then((res) => {
        dispatch(setSuccessMessage(res));
        navigate("/profile");
      })
      .catch((err) => {
        dispatch(setErrorMessage(err));
      });
  };

  return (
    <UserTemplate active={3}>
      <div className="relative w-full flex flex-col items-center sm:max-w-[658px] mx-auto">
        <div className="h-[67px]"></div>
        <div className="mb-[65px]">
          <StyledTypographyTitle
            sx={{
              fontWeight: "300 !important",
              display: "flex",
              flexWrap: "wrap",
              textAlign: "center",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            Inviter un utilisateur à faire une demande de transition emploi
            retraite
          </StyledTypographyTitle>
        </div>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex justify-center flex-wrap gap-x-4 gap-y-8">
            <div className="w-full sm:w-[320px]">
              <Controller
                name="firstname"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <StyledInputTextField
                    id="firstname"
                    label="Prénom :"
                    placeholder="Prénom"
                    value={field.value}
                    onChange={field.onChange}
                    error={errors.firstname?.message}
                  />
                )}
              />
            </div>
            <div className="w-full sm:w-[320px]">
              <Controller
                name="lastname"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <StyledInputTextField
                    id="lastname"
                    label="Nom :"
                    placeholder="Nom"
                    value={field.value}
                    onChange={field.onChange}
                    error={errors.lastname?.message}
                  />
                )}
              />
            </div>

            <div className="w-full sm:w-[320px]">
              <Controller
                name="phone_number"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <StyledInputTelephoneField
                    id="phone_number"
                    placeholder="+33 __ __ __ __ __"
                    label="Téléphone :"
                    onChange={field.onChange}
                    value={field.value}
                    error={errors.phone_number?.message}
                  />
                )}
              />
            </div>
            <div className="w-full sm:w-[320px]">
              <Controller
                name="company_code"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <StyledInputTextField
                    id="company_code"
                    label="Code entreprise :"
                    placeholder="Code entreprise"
                    value={field.value}
                    onChange={field.onChange}
                    error={errors.company_code?.message}
                  />
                )}
              />
            </div>

            <div className="w-full sm:w-[320px]">
              <Controller
                name="email"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <StyledInputTextField
                    id="email"
                    label="Email :"
                    placeholder="Email utilisateur"
                    value={field.value}
                    onChange={field.onChange}
                    error={errors.email?.message}
                  />
                )}
              />
            </div>

            <div className="w-full sm:w-[320px]">
              <Controller
                name="registration_code"
                control={control}
                defaultValue={generateRegistrationCode()}
                render={({ field }) => (
                  <StyledInputTextField
                    id="registration_code"
                    label="Code d’activation :"
                    placeholder="Votre code entreprise"
                    value={field.value}
                    onChange={field.onChange}
                    error={errors.registration_code?.message}
                    readonly={true}
                  />
                )}
              />
            </div>
          </div>

          <div className="flex justify-center mt-16 w-full">
            <div className="w-full sm:w-[320px]">
              <StyledButton type="submit">ENVOYER UNE INVITATION</StyledButton>
            </div>
          </div>
        </form>
      </div>
    </UserTemplate>
  );
};
