import { StyledTypographyInputLabel } from "../../Styled/StyledTypography";
import { StyledRadioBoxGroup } from "../../Styled/StyledRadioBoxGroup";
import { StyledDropzone } from "../../Styled/StyledDropzone";
import { StyledDatePicker } from "../../Styled/StyledDatePicker";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { StyledButton, StyledWarningButton } from "../../Styled/StyledButton";
import { Collapse, Typography } from "@mui/material";
import { useEffect } from "react";

const schema = yup.object().shape({
  // file: yup.mixed().required("file required"),
  receivedRetirementPension: yup.string().required("Ce champ est requis"),
  file: yup
    .array()
    .required("Fichier requis")
    .test(
      "start-recevied-file-test",
      "Vous devez sélectionner au moins 1 fichier",
      function (files) {
        const { receivedRetirementPension } = this.parent;
        if (receivedRetirementPension === "Oui") {
          return files.length > 0;
        }
        return true;
      }
    ),
  completedMilitaryService: yup.string().required("Ce champ est requis"),

  startDate: yup.string().when("completedMilitaryService", {
    is: "Oui",
    then: () =>
      yup
        .string()
        .required("La date de début est requise")
        .test(
          "start-date-test",
          "La date de début doit être antérieure à la date de fin",
          function (value) {
            const { endDate } = this.parent;
            if (new Date(value) > new Date(endDate)) {
              return false;
            }
            return true;
          }
        ),
  }),
  endDate: yup.string().when("completedMilitaryService", {
    is: "Oui",
    then: () =>
      yup
        .string()
        .required("La date de fin est requise")
        .test(
          "end-date-test",
          "La date de fin doit être postérieure à la date de début",
          function (value) {
            const { startDate } = this.parent;
            if (new Date(value) < new Date(startDate)) {
              return false;
            }
            return true;
          }
        ),
  }),
  reportedPeriod: yup.string().required("Ce champ est requis"),
  file1: yup
    .array()
    .required("Fichier requis")
    .test(
      "start-recevied-file-test",
      "Vous devez sélectionner au moins 1 fichier",
      function (files) {
        const { reportedPeriod } = this.parent;
        if (reportedPeriod === "Oui") {
          return files.length > 0;
        }
        return true;
      }
    ),
});

export const Step4 = ({ goToPrevStep, onSubmit, data }) => {
  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
    getValues,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      receivedRetirementPension: data.receivedRetirementPension || "",
      file: data.file || [],
      completedMilitaryService: data.completedMilitaryService || "",
      startDate: data.startDate || "",
      endDate: data.endDate || "",
      reportedPeriod: data.reportedPeriod || "",
      file1: data.file1 || [],
    },
  });

  useEffect(() => {
    setValue("receivedRetirementPension", data.receivedRetirementPension || "");
    setValue("file", data.file || []);
    setValue("completedMilitaryService", data.completedMilitaryService || "");
    setValue("startDate", data.startDate || "");
    setValue("endDate", data.endDate || "");
    setValue("reportedPeriod", data.reportedPeriod || "");
    setValue("file1", data.file1 || []);
  }, [data, setValue]);

  const received_retirement_pension_radiobox = ["Oui", "Non"];
  const completed_military_service_radiobox = ["Oui", "Non"];
  const reported_periods_radiobox = ["Oui", "Non"];

  const {
    receivedRetirementPension,
    completedMilitaryService,
    reportedPeriod,
  } = watch();

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="">
          <Controller
            name="receivedRetirementPension"
            control={control}
            render={({ field }) => (
              <StyledRadioBoxGroup
                id="receivedRetirementPension"
                group_title="Percevez-vous déjà une rente de retraite ?"
                contents={received_retirement_pension_radiobox}
                onChange={field.onChange}
                value={field.value}
                error={errors.receivedRetirementPension?.message}
              />
            )}
          />
        </div>
        <Collapse in={receivedRetirementPension === "Oui"}>
          <div className="my-[21px]">
            <div className="flex flex-col gap-y-4">
              <StyledTypographyInputLabel>
                Si oui, merci de nous transmettre le justificatif avec
                récapitulatif des périodes passées dans le régime :
              </StyledTypographyInputLabel>
              <Controller
                name="file"
                control={control}
                render={({ field }) => (
                  <StyledDropzone
                    id="file"
                    onChange={field.onChange}
                    value={field.value}
                    error={errors.file?.message}
                  />
                )}
              />
            </div>
          </div>
        </Collapse>
        <div className="">
          <Controller
            name="completedMilitaryService"
            control={control}
            render={({ field }) => (
              <StyledRadioBoxGroup
                id="completedMilitaryService"
                group_title="Avez-vous effectué votre service (militaire) national ?"
                contents={completed_military_service_radiobox}
                onChange={field.onChange}
                value={field.value}
                error={errors.completedMilitaryService?.message}
              />
            )}
          />
        </div>
        <Collapse in={completedMilitaryService === "Oui"}>
          <div className="my-[31px] flex-col gap-y-2">
            <Typography
              sx={{
                color: "#4F7793",
                fontFamily: "Segoe UI",
                fontSize: "18px",
                fontStyle: "normal",
                fontWeight: 600,
                lineHeight: "normal",
                marginBottom: "12px",
              }}
            >
              Si oui et qu’il est absent du relevé de carrière, précisez les
              dates ci-dessous :
            </Typography>
            <Controller
              name="startDate"
              control={control}
              render={({ field }) => {
                return (
                  <StyledDatePicker
                    id="startDate"
                    sub_label="Debut :"
                    onChange={field.onChange}
                    error={errors.startDate?.message}
                    value={field.value}
                  />
                );
              }}
            />
            <Controller
              name="endDate"
              control={control}
              render={({ field }) => {
                return (
                  <StyledDatePicker
                    id="endDate"
                    sub_label="Fin :"
                    onChange={field.onChange}
                    error={errors.endDate?.message}
                    value={field.value}
                  />
                );
              }}
            />
          </div>
        </Collapse>
        <div>
          <Controller
            name="reportedPeriod"
            control={control}
            render={({ field }) => (
              <StyledRadioBoxGroup
                id="reportedPeriod"
                group_title="Au regard de votre Relevé de situation Individuelle (RIS) / Estimation Indicative Globale (EIG) ou de votre relevé d'un autre régime, vous semble-t-il que des périodes ne sont pas reportées (chômage, étranger, activité salariée…) ?"
                contents={reported_periods_radiobox}
                onChange={field.onChange}
                value={field.value}
                error={errors.reportedPeriod?.message}
              />
            )}
          />
        </div>
        <Collapse in={reportedPeriod === "Oui"}>
          <div className="mt-[20px]">
            <div className="flex flex-col gap-y-4">
              <StyledTypographyInputLabel>
                Si oui, merci de nous transmettre les documents nous permettant
                de prendre en compte ces éléments de votre carrière :
              </StyledTypographyInputLabel>
              <Controller
                name="file1"
                control={control}
                render={({ field }) => (
                  <StyledDropzone
                    id="file1"
                    onChange={field.onChange}
                    value={field.value}
                    error={errors.file1?.message}
                  />
                )}
              />
            </div>
          </div>
        </Collapse>
        <div className="mt-[5.4vh] flex flex-wrap justify-center gap-x-4 gap-y-4 w-full">
          <div className="w-full sm:w-[320px]">
            <StyledWarningButton
              onClick={() => {
                goToPrevStep(getValues());
              }}
              type="button"
            >Précédent</StyledWarningButton>
          </div>
          <div className="w-full sm:w-[320px]">
            <StyledButton type="submit">CONTINUER</StyledButton>
          </div>
        </div>
      </form>
    </div>
  );
};
