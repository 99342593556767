import { useEffect, useState } from "react";
import {
  StyledTypographyInputLabel,
  StyledTypographySmallText,
  StyledTypographyTitle,
} from "../../Styled/StyledTypography";
import { ReturnToIcon } from "../../utilities/icons/ReturnToIcon";
import {
  StyledInputTelephoneField,
  StyledInputTextField,
} from "../../Styled/StyledInput";
import { StyledButton, StyledWarningButton } from "../../Styled/StyledButton";
import { useNavigate, useParams } from "react-router-dom";
import { StyledAvatarPicker } from "../../Styled/StyledAvatarPicker";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import hrService from "../../services/hr.service";
import { useDispatch } from "react-redux";
import { setErrorMessage, setSuccessMessage } from "../../actions/message";
import { emailRegex, modalStyle } from "../../utils";
import { Loading } from "../Loading";
import { forgotPassword } from "../../actions/auth";
import { Modal, Box } from "@mui/material";
import AdminTemplate from "../../components/AdminTemplate";

const schema = yup.object().shape({
  firstname: yup.string().required("Le prénom est requis"),
  lastname: yup.string().required("Le nom de famille est requis"),
  email: yup
    .string()
    .required("L'e-mail est requis")
    .matches(emailRegex, "Adresse e-mail invalide"),
  telephone: yup
    .string()
    .required("Numéro de téléphone requis")
    .matches(
      /^\+33 \d{2} \d{2} \d{2} \d{2} \d{2}$/,
      "Format de numéro de téléphone invalide (ex: +33 (01) 23 45 67 89)"
    )
    .test(
      "is-not-generic",
      "Numéro de téléphone générique non autorisé",
      (value) => {
        let maxCount = 0;
        const digitCounts = Array.from({ length: 10 }, () => 0); // Initialize an array to store counts for each digit

        // Count the occurrences of each digit in the input number
        for (let digit of value) {
          if (/[0-9]/.test(digit)) {
            const digitValue = parseInt(digit);
            digitCounts[digitValue]++;
            maxCount = Math.max(maxCount, digitCounts[digitValue]);
          }
        }

        if (maxCount >= 9) {
          return false;
        }

        return true;
      }
    ),
});

export const EditHR = () => {
  const navigate = useNavigate();
  const { clientId, hrId } = useParams();
  const [hrData, setHRData] = useState(null);
  const dispatch = useDispatch();
  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    const hrData = {
      ...data,
      id: hrId,
      clientId,
    };

    hrService
      .updateHR(hrData)
      .then((res) => {
        dispatch(setSuccessMessage(res));

        navigate(`/manage-client/edit-client-info/${clientId}`);
      })
      .catch((err) => {
        dispatch(setErrorMessage(err));
      });
  };
  const handleRemoveHR = () => {
    hrService
      .removeHR(hrId)
      .then((res) => {
        dispatch(setSuccessMessage(res));

        navigate(`/manage-client/edit-client-info/${clientId}`);
      })
      .catch((err) => {
        dispatch(setErrorMessage(err));
      });
  };

  useEffect(() => {
    hrService
      .getHR(hrId)
      .then((res) => {
        setHRData(res.data);
      })
      .catch((err) => {
        dispatch(setErrorMessage(err));
      });
  }, [dispatch, hrId]);

  const sendPasswordResetRequest = (hrEmailAddress) => {
    dispatch(forgotPassword(hrEmailAddress));
  };

  if (hrData === null) {
    return <Loading comment="Chargement des données horaires"></Loading>;
  }

  return (
    <AdminTemplate active={2}>
      <Modal
        open={openConfirmModal}
        onClose={() => setOpenConfirmModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <p className="text-myerror font-segoeBold text-[20px]">
            Êtes-vous sûr de vouloir supprimer cet utilisateur RH ?
          </p>
          <div className="w-full sm:w-[320px] my-[20px]">
            <StyledWarningButton
              onClick={() => {
                setOpenConfirmModal(false);
                handleRemoveHR();
              }}
              type="button"
            >
              Oui
            </StyledWarningButton>
          </div>
          <div className="w-full sm:w-[320px]">
            <StyledButton
              type="button"
              onClick={() => setOpenConfirmModal(false)}
            >
              Non
            </StyledButton>
          </div>
        </Box>
      </Modal>
      <div className="h-[63px]"></div>
      <div className="px-[5vw]">
        <StyledTypographyTitle
          sx={{
            fontWeight: "300 !important",
            display: "flex",
            flexWrap: "wrap",
            textAlign: "center",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Ajouter un&nbsp;
          <span className="font-segoeSemi">administrateur</span>
        </StyledTypographyTitle>
      </div>
      <div className="flex justify-center items-center mb-16 hover:cursor-pointer mt-[78px]">
        <ReturnToIcon />
        <StyledTypographyInputLabel
          sx={{
            color: "#2574A9 !important",
            fontWeight: "700 !important",
            paddingLeft: "5px",
          }}
          onClick={() =>
            navigate(`/manage-client/edit-client-info/${clientId}`)
          }
        >
          Retour au listing
        </StyledTypographyInputLabel>
      </div>

      <div
        className={`w-full mt-[52px] max-w-[720px] m-auto p-[20px]`}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex justify-center">
            <Controller
              name="avatar"
              control={control}
              defaultValue={hrData.avatar}
              render={({ field }) => (
                <StyledAvatarPicker
                  id="avatar"
                  onChange={field.onChange}
                  value={field.value}
                />
              )}
            />
          </div>
          <div className="flex justify-center flex-wrap gap-x-4 gap-y-4">
            <div className="w-full sm:w-[320px]">
              <Controller
                name="firstname"
                control={control}
                defaultValue={hrData.firstname}
                render={({ field }) => (
                  <StyledInputTextField
                    id="firstname"
                    label="Prénom :"
                    placeholder="Votre prénom"
                    onChange={field.onChange}
                    value={field.value}
                    error={errors.firstname?.message}
                  />
                )}
              />
            </div>
            <div className="w-full sm:w-[320px]">
              <Controller
                name="lastname"
                control={control}
                defaultValue={hrData.lastname}
                render={({ field }) => (
                  <StyledInputTextField
                    id="lastname"
                    label="Nom :"
                    placeholder="Votre nom"
                    onChange={field.onChange}
                    value={field.value}
                    error={errors.lastname?.message}
                  />
                )}
              />
            </div>

            <div className="w-full sm:w-[320px]">
              <Controller
                name="telephone"
                control={control}
                defaultValue={hrData.phone_number}
                render={({ field }) => (
                  <StyledInputTelephoneField
                    id="telephone"
                    placeholder="+33 __ __ __ __ __"
                    label="Téléphone :"
                    onChange={field.onChange}
                    value={field.value}
                    error={errors.telephone?.message}
                  />
                )}
              />
            </div>

            <div className="w-full sm:w-[320px]">
              <Controller
                name="email"
                control={control}
                defaultValue={hrData.email}
                render={({ field }) => (
                  <StyledInputTextField
                    id="email"
                    label="Email :"
                    placeholder="Votre email"
                    onChange={field.onChange}
                    value={field.value}
                    error={errors.telephone?.email}
                  />
                )}
              />
            </div>
            <div className="mb-[3.6vh] w-full sm:w-[320px]">
              <div className="absolute">
                <StyledTypographySmallText
                  className="cursor-pointer"
                  onClick={() => sendPasswordResetRequest(hrData.email)}
                >
                  Envoyer un email de réinitialisation de mot de passe
                </StyledTypographySmallText>
              </div>
            </div>
            <div className="w-full sm:w-[320px]"></div>
          </div>

          <div className="mt-[5.4vh] flex flex-wrap justify-center gap-x-4 gap-y-4 w-full">
            <div className="w-full sm:w-[320px]">
              <StyledWarningButton onClick={() => setOpenConfirmModal(true)}>
                SUPPRIMER LE COMPTE
              </StyledWarningButton>
            </div>
            <div className="w-full sm:w-[320px]">
              <StyledButton type="submit">
                MODIFIER MES INFORMATIONS
              </StyledButton>
            </div>
          </div>
        </form>
      </div>
    </AdminTemplate>
  );
};
