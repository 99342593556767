import { useEffect, useState } from "react";
import {
  StyledTypographyClientList,
  StyledTypographyInputLabel,
  StyledTypographyTitle,
} from "../../Styled/StyledTypography";
import { AddCircleOutline } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import clientService from "../../services/client.service";
import { useDispatch } from "react-redux";
import { setErrorMessage } from "../../actions/message";
import { Loading } from "../Loading";
import AdminTemplate from "../../components/AdminTemplate";

export const ManageClient = () => {
  const navigate = useNavigate();
  const [clients, setClients] = useState([]);
  const [loadingList, setLoadingList] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setLoadingList(true);

    clientService
      .getClientList()
      .then((res) => {
        setClients(res.data.clients);
        setLoadingList(false);
      })
      .catch((err) => {
        dispatch(setErrorMessage(err));
        setLoadingList(false);
      });
  }, [dispatch]);

  if (loadingList === true) {
    return <Loading comment="Chargement de la liste des clients..." />;
  }

  return (
    <AdminTemplate active={2}>
      <div className="h-[63px]"></div>

      <div className="mb-[8.4vh] px-[5vw]">
        <StyledTypographyTitle
          sx={{
            fontWeight: "300 !important",
            display: "flex",
            flexWrap: "wrap",
            textAlign: "center",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Administration&nbsp;
          <span className="font-segoeSemi">Clients</span>
        </StyledTypographyTitle>
      </div>
      <div
        className="flex justify-center items-center mb-[8.6vh] hover:cursor-pointer"
        onClick={() => navigate("/manage-client/add-client")}
      >
        <AddCircleOutline
          sx={{ color: "#EB4C37", width: "25px", height: "25px" }}
        />
        <StyledTypographyInputLabel
          sx={{
            color: "#2574A9 !important",
            fontFamily: "Segoe UI Bold !important",
            paddingLeft: "5px",
          }}
        >
          Ajouter un nouveau client
        </StyledTypographyInputLabel>
      </div>
      <div className={'w-full p-[20px] m-auto'}
        style={{
          maxWidth: '600px',
        }}>
        {clients.map((client, _id) => (
          <div
            className="flex justify-between items-center border-b-2 border-[#9EACC9] h-auto gap-x-[20px]"
            key={client.id}
          >
            <StyledTypographyClientList>
              Client {_id + 1} - {client.companyName}
            </StyledTypographyClientList>
            <div className="flex">
              <StyledTypographyClientList
                sx={{
                  cursor: "pointer",
                  color: "#2574A9 !important",
                  "&:hover": {
                    color: "#1E608D !important",
                  },
                }}
                onClick={() =>
                  navigate(`/manage-client/edit-client-info/${client.id}`)
                }
              >
                ACCÉDER / MODIFIER
              </StyledTypographyClientList>
            </div>
          </div>
        ))}
      </div>
    </AdminTemplate>
  );
};
