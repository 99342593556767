import axios from "../axios-orders";

class FileService {
  async getFilesSize(obj, totalSize = 0) {
    for (const key in obj) {
      if (obj[key] instanceof File) {
        totalSize += obj[key].size;
      } else if (Array.isArray(obj[key])) {
        for (let i = 0; i < obj[key].length; i++) {
          totalSize = await this.getFilesSize(obj[key][i], totalSize);
        }
      } else if (obj[key] instanceof Object) {
        totalSize = await this.getFilesSize(obj[key], totalSize);
      }
    }
    return totalSize;
  }

  // Calculating the total size of files in the formData
  async uploadFiles(formData, onUploadProgress) {
    let totalSize = await this.getFilesSize(formData);

    if (totalSize > 10 * 1024 * 1024) {
      // 10MB in bytes
      // Throw an error
      const error = new Error(`La taille totale du fichier dépasse 10 Mo`);
      return Promise.reject(error);
    }

    return axios.post("/api/file-upload", formData, {
      onUploadProgress,
    });
  }
}

const fileService = new FileService();
export default fileService;
