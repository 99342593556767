// import axios from "axios";

// const API_BASE_URL = process.env.REACT_APP_API_URL;

// const axiosOrder = axios.create({
//   baseURL: API_BASE_URL,
// });

// // Add a request interceptor to include the token from local storage
// axiosOrder.interceptors.request.use(
//   async (config) => {
//     const token = await localStorage.getItem('token');
//     if (token) {
//       config.headers.Authorization = `Bearer ${token}`;
//     }
//     config.headers["Content-Type"] = "multipart/form-data";
//     return config;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );

// export default axiosOrder;

// axios-order.js

import axios from "axios";
import store from "../store"; // Assuming you have a Redux store initialized
import { refreshToken } from "../actions/auth";

const API_BASE_URL = process.env.REACT_APP_API_URL; // Replace with your API base URL

const axiosOrder = axios.create({
  baseURL: API_BASE_URL,
  timeout: 100000,
});

axiosOrder.interceptors.request.use(
  async (config) => {
    const { token } = store.getState().auth; // Assuming your access token is stored in the auth slice of the Redux store

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    if (config.headers['Content-Type'] === 'application/json') {
      // Set 'application/json' content type
      Object.assign(config.headers, {
        'Content-Type': 'application/json'
      });
    } else {
      // Set default 'multipart/form-data' content type
      Object.assign(config.headers, {
        'Content-Type': 'multipart/form-data'
      });
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosOrder.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const { refresh_token } = store.getState().auth; // Assuming your refresh token is stored in the auth slice of the Redux store
      if (refresh_token && originalRequest.url !== '/api/login-with-token') {
        try {
          // Dispatch Redux action to refresh token
          const response = await store.dispatch(refreshToken());
          const { token } = response.data;
          // Dispatch Redux action to update access token
          originalRequest.headers.Authorization = `Bearer ${token}`;
          return axiosOrder(originalRequest);
        } catch (error) {
          // Handle refresh token failure
          return Promise.reject(error);
        }
      } else {
        // Handle refresh token not found
        return Promise.reject(error);
      }
    }
    return Promise.reject(error);
  }
);

export default axiosOrder;
