export const ReturnToIcon = ({
  width = 22,
  height = 21,
  color = "#eb4c37",
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
  >
    <g clipPath="url(#clip0_562_869)">
      <path
        d="M7.70805 13.9226C7.33846 13.9226 6.96765 13.7932 6.67507 13.5325L0.50745 8.03295C0.19493 7.75407 0.0178768 7.36185 0.0210377 6.95148C0.023957 6.54102 0.205714 6.15099 0.52269 5.87639L6.85934 0.37641C7.48251 -0.164291 8.44366 -0.116822 9.00604 0.482413C9.56849 1.08157 9.51936 2.00568 8.89619 2.54638L3.79235 6.9758L8.74167 11.3894C9.35698 11.9384 9.39338 12.8633 8.82274 13.4549C8.52344 13.7652 8.11623 13.9226 7.70805 13.9226Z"
        fill="#EB4C37"
      />
      <path
        d="M13.9517 21.0002H1.52004C0.68049 21.0002 0 20.3461 0 19.5387C0 18.7316 0.68049 18.0772 1.52004 18.0772H13.9517C16.7131 18.0772 18.9599 15.9173 18.9599 13.2621C18.9599 10.6068 16.7131 8.44684 13.9517 8.44684H1.52004C0.68049 8.44684 0 7.79288 0 6.98538C0 6.17827 0.68049 5.52393 1.52004 5.52393H13.9517C18.3895 5.52393 22 8.99525 22 13.2621C22 17.5289 18.3895 21.0002 13.9517 21.0002Z"
        fill="#EB4C37"
      />
    </g>
    <defs>
      <clipPath id="clip0_562_869">
        <rect width={width} height={height} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
