import React, { useState, useEffect } from "react";
import {
  StyledInputTextField,
  StyledInputTelephoneField,
} from "../../Styled/StyledInput";
import { Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { emailRegex } from "../../utils";
import { StyledButton } from "../../Styled/StyledButton";
import { useDispatch, useSelector } from "react-redux";
import { StyledSelect } from "../../Styled/StyledSelect";
import adminService from "../../services/admin.service";
import { setErrorMessage } from "../../actions/message";

const schema = yup.object().shape({
  firstname: yup.string().required("Le prénom est requis"),
  lastname: yup.string().required("Le nom de famille est requis"),
  email: yup
    .string()
    .required("L'e-mail est requis")
    .matches(emailRegex, "Adresse e-mail invalide"),
  phone_number: yup
    .string()
    .required("Numéro de téléphone requis")
    .matches(
      /^\+33 \d{2} \d{2} \d{2} \d{2} \d{2}$/,
      "Format de numéro de téléphone invalide (ex: +33 (01) 23 45 67 89)"
    ),
  company_code: yup.string().required("Le code de l'entreprise est requis"),
  referralContact: yup
    .number()
    .min(1, "Doit être au moins 1")
    .required("Le contact de parrainage est requis"),
});

export const Step1 = ({ goToPrevStep, onSubmit, data }) => {
  const dispatch = useDispatch();
  const [referralContact, setReferralContact] = useState([]);

  useEffect(() => {
    adminService
      .getContactList()
      .then((res) => {
        const contactList = res.data.users.map((user) => {
          return {
            label: `${user.firstname} ${user.lastname}`,
            value: user.id,
          };
        });

        setReferralContact(contactList);
      })
      .catch((err) => {
        dispatch(setErrorMessage(err));
      });
  }, [dispatch, data]);

  const userData = useSelector((state) => state.auth?.user ?? { roles: [] });

  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      firstname: userData.firstname || "",
      lastname: userData.lastname || "",
      phone_number: userData.phone_number || "",
      company_code: userData.company_code || "",
      email: userData.email || "",
      referralContact: "",
    },
  });

  useEffect(() => {
    setValue("firstname", data.firstname || userData.firstname || "");
    setValue("lastname", data.lastname || userData.lastname || "");
    setValue("phone_number", data.phone_number || userData.phone_number || "");
    setValue("company_code", data.company_code || userData.company_code || "");
    setValue("email", data.email || userData.email || "");
    setValue("referralContact", data.referralContact || "");
  }, [data, setValue, userData]);

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex justify-center flex-wrap gap-x-4 gap-y-4">
          <div className="w-full sm:w-[320px]">
            <Controller
              name="firstname"
              control={control}
              render={({ field }) => (
                <StyledInputTextField
                  id="firstname"
                  placeholder="Votre prénom"
                  label="Prénom :"
                  value={field.value}
                  onChange={field.onChange}
                  error={errors.firstname?.message}
                  readonly
                />
              )}
            />
          </div>
          <div className="w-full sm:w-[320px]">
            <Controller
              name="lastname"
              control={control}
              render={({ field }) => (
                <StyledInputTextField
                  id="lastname"
                  placeholder="Votre nom"
                  label="Nom :"
                  value={field.value}
                  onChange={field.onChange}
                  error={errors.lastname?.message}
                  readonly
                />
              )}
            />
          </div>

          <div className="w-full sm:w-[320px]">
            <Controller
              name="phone_number"
              control={control}
              render={({ field }) => (
                <StyledInputTelephoneField
                  id="phone_number"
                  placeholder="+33 __ __ __ __ __"
                  label="Téléphone :"
                  onChange={field.onChange}
                  value={field.value}
                  error={errors.phone_number?.message}
                />
              )}
            />
          </div>

          <div className="w-full sm:w-[320px]">
            <Controller
              name="company_code"
              control={control}
              render={({ field }) => (
                <StyledInputTextField
                  id="company_code"
                  placeholder="Votre code entreprise"
                  label="Code entreprise :"
                  value={field.value}
                  onChange={field.onChange}
                  error={errors.company_code?.message}
                />
              )}
            />
          </div>

          <div className="w-full sm:w-[320px]">
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <StyledInputTextField
                  id="email"
                  placeholder="Votre email"
                  label="Email :"
                  value={field.value}
                  onChange={field.onChange}
                  error={errors.email?.message}
                  readonly
                />
              )}
            />
          </div>

          <div className="w-full sm:w-[320px]">
            <Controller
              name="referralContact"
              control={control}
              render={({ field }) => (
                <StyledSelect
                  id="referralContact"
                  label="Contact référent :"
                  placeholder="Aucune référence"
                  options={referralContact}
                  value={field.value}
                  onChange={field.onChange}
                  error={errors.referralContact?.message}
                />
              )}
            />
          </div>
        </div>

        <div className="mt-[5.4vh] flex flex-wrap justify-center gap-x-4 gap-y-4 w-full">
          <div className="w-full sm:w-[320px]">
            <StyledButton type="submit">CONTINUER</StyledButton>
          </div>
        </div>
      </form>
    </div>
  );
};
