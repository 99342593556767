// App.js
import React, { useState, useEffect, useRef, useCallback } from "react";
import { PageRouter } from "./router/PageRouter";
import { Alert, AlertTitle, Snackbar } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { clearMessage } from "./actions/message";
import { Loading } from "./mainpage/Loading";
import { logout } from "./actions/auth";
import {useTranslation} from "react-i18next";

function useAutoLogout(timeout) {
  const logoutTimer = useRef(null);
  const dispatch = useDispatch();
  const { t } = useTranslation("common");

  const resetLogoutTimer = useCallback(() => {
    if (logoutTimer.current) {
      clearTimeout(logoutTimer.current);
    }
    logoutTimer.current = setTimeout(() => {
      dispatch(logout()); // Call your logout action
    }, timeout);
  }, [timeout, dispatch]);

  useEffect(() => {
    function handleUserActivity() {
      resetLogoutTimer();
    }

    window.addEventListener("mousemove", handleUserActivity);
    window.addEventListener("keypress", handleUserActivity);

    resetLogoutTimer(); // Initial setup to start the logout timer

    return () => {
      window.removeEventListener("mousemove", handleUserActivity);
      window.removeEventListener("keypress", handleUserActivity);
      clearTimeout(logoutTimer.current);
    };
  }, [dispatch, resetLogoutTimer, timeout]);

  return resetLogoutTimer;
}

function App() {
  const dispatch = useDispatch();

  // eslint-disable-next-line
  const resetLogoutTimer = useAutoLogout(process.env.REACT_APP_LOGOUT_TIME);

  const { message, severity } = useSelector((state) => state.message);

  const [loading, setLoading] = useState(true);

  const { t } = useTranslation("common");
  const finishLoading = useCallback(() => {
    setLoading(false);
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setLoading(false);
    }, 3000); // 3 seconds in milliseconds
    window.addEventListener("load", finishLoading);

    return () => {
      window.removeEventListener("load", finishLoading);
      clearTimeout(timeoutId); // Clear the timeout
    };
  }, [finishLoading]);

  if (loading === true) {
    return <Loading comment="Chargement..."></Loading>;
  }

  return (
    <div>
      {!!message && (
        <Snackbar
          key={message}
          open={true}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          autoHideDuration={6000}
          onClose={() => dispatch(clearMessage())}
        >
          <Alert
            severity={severity}
            sx={{
              width: "100%",
              fontFamily: "Segoe UI",
              fontSize: "16px",
              "& .MuiAlert-icon": {
                fontSize: 32
              }
            }}
          >
            <AlertTitle sx={{fontSize: "20px", fontFamily: "Segoe UI Bold"}}>
              {severity === "success" ? "Succès" : "Erreur"}
            </AlertTitle>
            {t(message)}
          </Alert>
        </Snackbar>
      )}

      <PageRouter />
    </div>
  );
}

export default App;
