import { Box, IconButton, Menu, MenuItem } from "@mui/material";
import { Logo } from "../utilities/Logo";
import { DashBoardIcon } from "../utilities/icons/DashBoardIcon";
import {
  StyledTypographyMenuText,
  StyledTypographySidebarList,
} from "../Styled/StyledTypography";
import { ManageClientIcon } from "../utilities/icons/ManageClientIcon";
import { ManageAdminIcon } from "../utilities/icons/ManageAdminIcon";
import { ProfileIcon } from "../utilities/icons/ProfileIcon";
import { DisconnectionIcon } from "../utilities/icons/DisconnectionIcon";
import { useNavigate } from "react-router-dom";
import { MenuOutlined } from "@mui/icons-material";
import { useEffect, useState } from "react";

export const AdminSidebar = ({ active = 1 }) => {
  const navigate = useNavigate();
  const [scrWidth, setScrWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setScrWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);

  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      sx={{
        "& .MuiButtonBase-root.MuiMenuItem-root.MuiMenuItem-gutters": {
          minHeight: 54,
        },
      }}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem
        onClick={() => navigate("/dashboard")}
        sx={{
          "&:hover .MuiTypography-root": {
            color: "#EB4C37",
          },
        }}
      >
        <Logo />
      </MenuItem>
      <MenuItem
        onClick={() => navigate("/dashboard")}
        sx={{
          "&:hover .MuiTypography-root": {
            color: "#EB4C37",
          },
        }}
      >
        <StyledTypographyMenuText sx={{ color: "#4f7793" }}>
          Tableau de bord
        </StyledTypographyMenuText>
      </MenuItem>
      <MenuItem
        onClick={() => navigate("/manage-client")}
        sx={{
          "&:hover .MuiTypography-root": {
            color: "#EB4C37",
          },
        }}
      >
        <StyledTypographyMenuText sx={{ color: "#4f7793" }}>
          Administration Clients
        </StyledTypographyMenuText>
      </MenuItem>
      <MenuItem
        onClick={() => navigate("/manage-admin")}
        sx={{
          "&:hover .MuiTypography-root": {
            color: "#EB4C37",
          },
        }}
      >
        <StyledTypographyMenuText sx={{ color: "#4f7793" }}>
          Administration AGEO
        </StyledTypographyMenuText>
      </MenuItem>
      <MenuItem
        onClick={() => navigate("/my-profile")}
        sx={{
          "&:hover .MuiTypography-root": {
            color: "#EB4C37",
          },
        }}
      >
        <StyledTypographyMenuText sx={{ color: "#4f7793" }}>
          Mon profil
        </StyledTypographyMenuText>
      </MenuItem>
      <MenuItem
        onClick={() => navigate("/logout")}
        sx={{
          "&:hover .MuiTypography-root": {
            color: "#EB4C37",
          },
        }}
      >
        <StyledTypographyMenuText sx={{ color: "#4f7793" }}>
          Déconnexion
        </StyledTypographyMenuText>
      </MenuItem>
    </Menu>
  );
  const activeBorder = (active_id) => {
    if (active_id === active) {
      return "border-l-4 border-[#EB4C37]";
    }
    return "border-l-4 border-paperColor hover:border-sideHover"
  };
  const activeColor = (active_id) => {
    if (active_id === active) {
      return "#EB4C37";
    } else return "#4f7793";
  };
  const activeListTitle = (active_id, content) => {
    if (active_id === active) {
      return (
        <StyledTypographySidebarList sx={{ color: "#2574A9", fontFamily: 'Segoe UI Bold !important' }}>
          {content}
        </StyledTypographySidebarList>
      );
    } else {
      return (
        <StyledTypographySidebarList sx={{ color: "#4f7793", fontFamily: 'Segoe UI !important' }}>
          {content}
        </StyledTypographySidebarList>
      );
    }
  };
  return (
    <div>
      {scrWidth > 1024 ? (
        <Box
          sx={{
            background: "#fff",
            boxShadow: "0px 0px 35px 0px rgba(219, 224, 240, 0.50)",
            width: "400px",
            height: "100vh",
            position: "fixed",
            top: 0,
            left: 0,
            zIndex: 999,
          }}
        >
          <div className="pt-[3vh] mb-[7vh] flex justify-center">
            <Logo width={130} height={52} />
          </div>
          <div className="flex flex-col">
            <div
              className={`${activeBorder(
                1
              )} pl-[46px] hover:bg-sideHover cursor-pointer`}
              onClick={() => navigate("/dashboard")}
            >
              <div className="flex items-center space-x-[21px]">
                <DashBoardIcon color={activeColor(1)} />
                {activeListTitle(1, "Tableau de bord")}
              </div>
            </div>
            <div
              className={`${activeBorder(
                2
              )} pl-[46px] hover:bg-sideHover cursor-pointer`}
              onClick={() => navigate("/manage-client")}
            >
              <div className="flex items-center space-x-[21px]">
                <ManageClientIcon color={activeColor(2)} />
                {activeListTitle(2, "Administration Clients")}
              </div>
            </div>
            <div
              className={`${activeBorder(
                3
              )} pl-[46px] hover:bg-sideHover cursor-pointer`}
              onClick={() => navigate("/manage-admin")}
            >
              <div className="flex items-center space-x-[21px]">
                <ManageAdminIcon color={activeColor(3)} />
                {activeListTitle(3, "Administration AGEO")}
              </div>
            </div>
            <div
              className={`${activeBorder(
                4
              )} pl-[46px] hover:bg-sideHover cursor-pointer`}
              onClick={() => navigate("/my-profile")}
            >
              <div className="flex items-center space-x-[21px]">
                <ProfileIcon color={activeColor(4)} />
                {activeListTitle(4, "Mon profil")}
              </div>
            </div>
            <div
              className={`${activeBorder(
                5
              )} pl-[46px] hover:bg-sideHover cursor-pointer`}
              onClick={() => navigate("/logout")}
            >
              <div className="flex items-center space-x-[21px]">
                <DisconnectionIcon color={activeColor(5)} />
                {activeListTitle(5, "Déconnexion")}
              </div>
            </div>
          </div>
        </Box>
      ) : (
        <IconButton
          onClick={handleMobileMenuOpen}
          sx={{ position: "fixed", top: "3vh", left: "3vw", zIndex: 999 }}
        >
          <MenuOutlined sx={{ color: "#EB4C37" }} />
        </IconButton>
      )}
      {renderMobileMenu}
    </div>
  );
};
