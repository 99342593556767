import { StyledInputTextField } from "../../Styled/StyledInput";
import { StyledTypographyTitle } from "../../Styled/StyledTypography";
import { StyledButton } from "../../Styled/StyledButton";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { emailRegex } from "../../utils";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import WelcomeTemplate from "../../components/WelcomeTemplate";
import { Logo } from "../../utilities/Logo";

const schema = yup.object().shape({
  email: yup
    .string()
    .matches(emailRegex, "Adresse e-mail invalide")
    .required("L'e-mail est requis"),
  registration_code: yup.string().required("Mot de passe requis"),
});

function DescriptionHR({ onSubmit: onDescriptionSubmit }) {
  const [scrWidth, setScrWidth] = useState(window.innerWidth);
  const navigate = useNavigate();
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    const handleResize = () => {
      setScrWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleLogin = async (data) => {
    onDescriptionSubmit({
      email: data.email,
      registration_code: data.registration_code,
    });
  };

  return (
    <WelcomeTemplate>
      <div className="mt-[77px]">
        <Logo width={200} onClick={() => navigate("/")} />
      </div>
      <div className="mb-[5px] mt-[10px]">
        <StyledTypographyTitle>Connexion</StyledTypographyTitle>
      </div>
      <form onSubmit={handleSubmit(handleLogin)}>
        <div className={`${scrWidth > 550 ? "w-[470px]" : "w-[80vw]"}`}>
          <div className="min-h-[120px]">
            <Controller
              name="email"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <StyledInputTextField
                  id="email"
                  placeholder="Votre email"
                  label="Votre email :"
                  onChange={field.onChange}
                  value={field.value}
                  error={errors.email?.message}
                />
              )}
            />
          </div>
          <div className="">
            <Controller
              name="registration_code"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <StyledInputTextField
                  id="registration_code"
                  placeholder="Code d’activation"
                  label="Code d’activation :"
                  onChange={field.onChange}
                  value={field.value}
                  error={errors.registration_code?.message}
                />
              )}
            />
          </div>
          <div className="mb-[43px] mt-[50px]">
            <StyledButton type="submit">CONNEXION</StyledButton>
          </div>
        </div>
      </form>

      <div className="w-full flex justify-center">
        <button
          className="text-ageoNormal font-segoeSemi text-[20px] text-center cursor-pointer hover:text-linkHover rounded-[5px] h-[50px] transition-all"
          onClick={() => navigate("/")}
        >
          Se connecter
        </button>
      </div>
    </WelcomeTemplate>
  );
}

export default DescriptionHR;
