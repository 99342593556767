import {
  StyledInputTextField,
  StyledInputPasswordField,
} from "../../Styled/StyledInput";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { ROLE } from "../../router/roles";
import { emailRegex } from "../../utils";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { login } from "../../actions/auth";
import WelcomeTemplate from "../../components/WelcomeTemplate";
import { Logo } from "../../utilities/Logo";
import { useTranslation } from "react-i18next";

const schema = yup.object().shape({
  email: yup
    .string()
    .matches(emailRegex, "Adresse e-mail invalide")
    .required("L'e-mail est requis"),
  password: yup.string().required("Mot de passe requis"),
});

function Login() {
  const [scrWidth, setScrWidth] = useState(window.innerWidth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isLoggedIn, user } = useSelector((state) => state.auth);
  const [error, setError] = useState("");
  const [type, setType] = useState("password");

  const { t } = useTranslation("common");

  const {
    handleSubmit,
    formState: { errors, isValid },
    control,
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    const handleResize = () => {
      setScrWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (isLoggedIn === true && user) {
      if (user?.roles.includes(ROLE.AGEO)) {
        navigate("/dashboard");
      } else if (user?.roles.includes(ROLE.ADMIN)) {
        navigate("/dashboard");
      } else if (user?.roles.includes(ROLE.HR)) {
        navigate("/welcome");
      } else if (user?.roles.includes(ROLE.CLIENT)) {
        navigate("/welcome");
      } else if (user?.roles.includes(ROLE.USER)) {
        navigate("/welcome");
      }
    }
  }, [isLoggedIn, navigate, user]);

  const handleLogin = (data) => {
    setError("");
    dispatch(login(data.email, data.password))
      .then((res) => {})
      .catch((err) => {
        const message =
          (err.response && err.response.data && err.response.data.message) ||
          err.message ||
          err.toString();

        setError(message);
        setType(err.response?.data?.type || "password");
      });
  };

  return (
    <WelcomeTemplate displayScroll={false}>
      <div className="mt-[77px]">
        <Logo width={200} onClick={() => navigate("/")} />
      </div>
      <div className="mb-[5px] mt-[10px]">
        <span className="text-[40px] font-segoeLight text-ageoTitle leading-[50px]">
          Connexion
        </span>
      </div>

      <form onSubmit={handleSubmit(handleLogin)}>
        <div className={`${scrWidth > 550 ? "w-[470px]" : "w-[80vw]"}`}>
          <div className="min-h-[120px]">
            <Controller
              name="email"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <StyledInputTextField
                  id="email"
                  placeholder="Votre email"
                  label="Votre email :"
                  onChange={field.onChange}
                  value={field.value}
                  error={errors.email?.message}
                />
              )}
            />
            {type === "email" && (
              <p className="text-myerror font-segoeSemi">{t(error)}</p>
            )}
          </div>
          <div className="">
            <Controller
              name="password"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <StyledInputPasswordField
                  id="password"
                  placeholder="Mot de passe"
                  label="Mot de passe :"
                  onChange={field.onChange}
                  value={field.value}
                  error={errors.password?.message}
                />
              )}
            />
            {type === "password" && (
              <p className="text-myerror font-segoeSemi">{t(error)}</p>
            )}
          </div>
          <div
            className="my-[12px] text-ageoNormal font-segoeSemi text-[18px] text-right cursor-pointer hover:text-linkHover rounded-[5px] transition-all"
            onClick={() => navigate("/forgot-password")}
          >
            Mot de passe oublié ?
          </div>
          <div className="mb-[28px] mt-[21px]">
            <button
              type="submit"
              className="w-full font-segoeBold h-[50px] bg-ageoNormal rounded-[8px] text-paperColor text-[16px] hover:bg-ageoHover transition-all disabled:bg-ageoDisable disabled:text-ageoHover disabled:cursor-not-allowed"
              disabled={!isValid}
            >
              CONNEXION
            </button>
          </div>
          <div className="w-full flex justify-center">
            <button
              className="text-ageoNormal font-segoeSemi text-[20px] text-center cursor-pointer hover:text-linkHover rounded-[5px] h-[50px] transition-all"
              onClick={() => navigate("/activation")}
            >
              Créer mon compte
            </button>
          </div>
        </div>
      </form>
    </WelcomeTemplate>
  );
}

export default Login;
