import { IconButton, InputAdornment, SvgIcon, TextField } from "@mui/material";
import { IMaskInput } from "react-imask";
import {
  StyledTypographyInputLabel,
  StyledTypographySmallText,
} from "./StyledTypography";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";

import { forwardRef, useState } from "react";

const PhoneInput = forwardRef((props, ref) => (
  <IMaskInput {...props} inputRef={ref} />
));

export const StyledInputPercentageField = ({
  placeholder = "",
  label = "",
  readonly = false,
  defaultvalue = "",
  fontFamily = "Segoe UI Bold",
  color = "#2574A9",
  error = "",
  ...fields
}) => (
  <div className="w-full flex flex-col space-y-2">
    {label === "" ? (
      []
    ) : (
      <StyledTypographyInputLabel
        sx={{
          textAlign: "left",
          color: error === "" ? "#4f7793 !important" : "#EB4C37 !important",
        }}
      >
        {label}
      </StyledTypographyInputLabel>
    )}

    <TextField
      helperText={error}
      type="number"
      placeholder={placeholder}
      sx={{
        width: "100%",
        "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
          {
            display: "none",
          },
        "&:hover .MuiOutlinedInput-notchedOutline": {
          border:
            error === ""
              ? "2px solid #4f7793 !important"
              : "2px solid red !important",
        },
        "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
          border:
            error === ""
              ? "2px solid #4f7793 !important"
              : "2px solid red !important",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          border:
            error === "" ? "2px solid #e6e8ec" : "2px solid #EB4C37 !important",
          borderRadius: "8px",
        },
        "& .MuiInputBase-input.MuiOutlinedInput-input": {
          color: color,
          fontFamily: fontFamily,
          borderRight: "2px solid #E6E8EC",
          marginRight: "10px",
          background: "#fff",
          paddingLeft: "20px",
          fontSize: "18px",
        },
        "& .MuiInputBase-root.MuiOutlinedInput-root": {
          maxWidth: "233px",
        },
        "& .MuiFormHelperText-root": {
          fontSize: "1rem !important",
          color: "#EB4C37 !important",
          fontFamily: "Segoe UI Light",
        },
      }}
      value={defaultvalue}
      InputProps={{
        readOnly: readonly,
        endAdornment: (
          <InputAdornment position="end" className="mr-[10px]">
            <StyledTypographySmallText
              sx={{
                color:
                  error === "" ? "#4f7793 !important" : "#EB4C37 !important",
              }}
            >
              %
            </StyledTypographySmallText>
          </InputAdornment>
        ),
        inputProps: {
          sx: {
            "&::placeholder": {
              color: "#4f7793",
              fontFamily: "Segoe UI Light",
              opacity: 1,
              fontSize: "16px",
            },
            paddingLeft: "20px",
          },
        },
      }}
      {...fields}
    />
  </div>
);
export const StyledInputTextField = ({
  placeholder = "",
  label = "",
  readonly = false,
  defaultvalue = "",
  fontFamily = "Segoe UI Bold",
  color = "#2574a9",
  multiline = false,
  error = "",
  ...fields
}) => (
  <div className="w-full flex flex-col space-y-2">
    {label === "" ? (
      []
    ) : (
      <StyledTypographyInputLabel
        sx={{
          textAlign: "left",
          color: error === "" ? `#${color} !important` : "#EB4C37 !important",
        }}
      >
        {label}
      </StyledTypographyInputLabel>
    )}
    <TextField
      multiline={multiline}
      placeholder={placeholder}
      error={error !== ""}
      helperText={error}
      rows={4}
      sx={{
        width: "100%",
        minHeight: multiline ? "170px" : "auto",
        "&:hover .MuiOutlinedInput-notchedOutline": {
          border:
            error === ""
              ? "2px solid #4f7793 !important"
              : "2px solid red !important",
        },
        "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
          border:
            error === ""
              ? "2px solid #4f7793 !important"
              : "2px solid red !important",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          border:
            error === "" ? "2px solid #e6e8ec" : "2px solid #EB4C37 !important",
          borderRadius: "8px",
          minHeight: multiline ? "170px" : "auto",
        },
        "& .MuiInputBase-input.MuiOutlinedInput-input": {
          color: color,
          fontFamily: fontFamily,
          background: "#fff",
          borderRadius: "8px",
          fontSize: "18px",
        },
        "& .MuiFormHelperText-root": {
          fontSize: "1rem !important",
          color: "#EB4C37 !important",
          fontFamily: "Segoe UI Light",
        },
        "& .MuiInputBase-root.MuiOutlinedInput-root": {
          // maxWidth: "320px",
        },
      }}
      value={defaultvalue}
      InputProps={{
        readOnly: readonly,
        inputProps: {
          sx: {
            "&::placeholder": {
              color: "#4f7793",
              fontFamily: "Segoe UI Light",
              opacity: 1,
              fontSize: "16px",
            },
            paddingLeft: "20px",
          },
        },
      }}
      {...fields}
    />
  </div>
);

export const StyledMultilineInput = ({
  placeholder = "",
  label = "",
  readonly = false,
  fontWeight = 300,
  color = "#4f7793",
  error = "",
  ...fields
}) => (
  <div className="w-full flex flex-col space-y-2">
    {label === "" ? (
      []
    ) : (
      <StyledTypographyInputLabel
        sx={{
          textAlign: "left",
          color: error === "" ? "#4f7793 !important" : "#EB4C37 !important",
        }}
      >
        {label}
      </StyledTypographyInputLabel>
    )}
    <TextField
      id="standard-multiline-static"
      multiline
      rows={4}
      helperText={error}
      placeholder={placeholder}
      sx={{
        "& .MuiInputBase-root.MuiOutlinedInput-root": {
          backgroundColor: "white",
          color: "#2574A9",
          fontFamily: "Segoe UI Bold",
          fontSize: "18px",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          border:
            error === "" ? "2px solid #e6e8ec" : "2px solid #EB4C37 !important",
        },
        "& .MuiFormHelperText-root": {
          color: error === "" ? "#e6e8ec" : "#EB4C37",
          fontSize: "1rem !important",
          fontFamily: "Segoe UI Light",
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
          border:
            error === ""
              ? "2px solid #4f7793 !important"
              : "2px solid red !important",
        },
      }}
      InputProps={{
        readOnly: readonly,
        inputProps: {
          sx: {
            "&::placeholder": {
              color: "#4f7793",
              fontFamily: "Segoe UI Light",
              opacity: 1,
              fontSize: "16px",
            },
            paddingLeft: "10px",
          },
        },
      }}
      {...fields}
    />
  </div>
);

export const StyledInputPasswordField = ({
  placeholder = "",
  label = "",
  readonly = false,
  defaultvalue = "",
  fontFamily = "Segoe UI Bold",
  color = "#2574a9",
  error = "",
  ...fields
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <div className="w-full flex flex-col space-y-2">
      <StyledTypographyInputLabel
        sx={{
          textAlign: "left",
          color: error === "" ? `#${color} !important` : "#EB4C37 !important",
        }}
      >
        {label}
      </StyledTypographyInputLabel>
      <TextField
        error={error !== ""}
        helperText={error}
        placeholder={placeholder}
        sx={{
          width: "100%",
          "&:hover .MuiOutlinedInput-notchedOutline": {
            border:
              error === ""
                ? "2px solid #4f7793 !important"
                : "2px solid red !important",
          },
          "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
            border:
              error === ""
                ? "2px solid #4f7793 !important"
                : "2px solid red !important",
          },
          "& .MuiOutlinedInput-notchedOutline": {
            border:
              error === ""
                ? "2px solid #e6e8ec"
                : "2px solid #EB4C37 !important",
            borderRadius: "8px",
          },
          "& .MuiInputBase-input.MuiOutlinedInput-input": {
            color: color,
            fontFamily: fontFamily,
            background: "#fff",
            borderRadius: "8px",
            fontSize: "18px",
          },
          "& .MuiFormHelperText-root": {
            fontSize: "1rem !important",
            color: "#EB4C37 !important",
            fontFamily: "Segoe UI Light",
          },
          "& .MuiInputBase-root.MuiOutlinedInput-root": {
            // maxWidth: "320px",
          },
        }}
        value={defaultvalue}
        type={showPassword ? "text" : "password"}
        InputProps={{
          readOnly: readonly,
          endAdornment: (
            <InputAdornment position="start">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword ? (
                  <SvgIcon>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="23"
                      height="18"
                      viewBox="0 0 23 18"
                      fill="none"
                    >
                      <path
                        d="M14.3554 9.20212L15.6618 7.83912C15.7568 8.20994 15.8125 8.59787 15.8125 9C15.8125 11.4814 13.878 13.5 11.5 13.5C11.1146 13.5 10.7429 13.4419 10.3875 13.3427L11.6937 11.9795C13.1221 11.8779 14.258 10.6926 14.3554 9.20212ZM22.9094 8.63575C22.821 8.46975 21.5672 6.17725 19.1161 4.23437L18.0703 5.32569C19.8573 6.697 20.9898 8.29613 21.4357 9.00125C20.5838 10.3547 17.2418 15 11.5 15C10.639 15 9.83837 14.885 9.08476 14.7017L7.90367 15.9341C8.98952 16.2815 10.183 16.5 11.5 16.5C19.0239 16.5 22.7543 9.6555 22.9094 9.36425C23.0302 9.13769 23.0302 8.86231 22.9094 8.63575ZM19.9144 1.28025L4.1019 17.7803C3.96157 17.9268 3.77763 18 3.59375 18C3.40987 18 3.22593 17.9268 3.08559 17.7803C2.8048 17.4873 2.8048 17.0127 3.08559 16.7197L5.09743 14.6206C1.84509 12.5717 0.192565 9.55569 0.090562 9.36425C-0.0301874 9.13769 -0.0301874 8.86231 0.090562 8.6355C0.245692 8.3445 3.97606 1.5 11.5 1.5C13.4454 1.5 15.1305 1.96313 16.571 2.64794L18.8981 0.21975C19.1788 -0.0732498 19.6337 -0.0732498 19.9144 0.21975C20.1952 0.512688 20.1952 0.987312 19.9144 1.28025ZM6.16927 13.5019L7.98885 11.6032C7.48626 10.8674 7.1875 9.97019 7.1875 9C7.1875 6.51856 9.12195 4.5 11.5 4.5C12.4298 4.5 13.2896 4.81175 13.9948 5.33619L15.4662 3.80056C14.3037 3.31469 12.9827 3 11.5 3C5.75821 3 2.4162 7.64525 1.56454 8.99875C2.10546 9.85425 3.65993 12.0195 6.16927 13.5019ZM9.03235 10.5142L12.9511 6.42506C12.5236 6.16162 12.0316 6 11.5 6C9.91486 6 8.625 7.34594 8.625 9C8.625 9.55469 8.77989 10.0681 9.03235 10.5142Z"
                        fill="#4F7793"
                      />
                    </svg>
                  </SvgIcon>
                ) : (
                  <SvgIcon>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="23"
                      height="15"
                      viewBox="0 0 23 15"
                      fill="none"
                    >
                      <path
                        d="M11.5 0C3.97607 0 0.245692 6.8445 0.090562 7.13575C-0.0301874 7.36231 -0.0301874 7.63769 0.090562 7.8645C0.245692 8.1555 3.97607 15 11.5 15C19.0239 15 22.7543 8.1555 22.9094 7.86425C23.0302 7.63769 23.0302 7.36231 22.9094 7.1355C22.7543 6.8445 19.0239 0 11.5 0ZM1.56454 7.50125C2.41949 6.14919 5.77623 1.5 11.5 1.5C17.2418 1.5 20.5838 6.14525 21.4355 7.49875C20.5805 8.85081 17.2238 13.5 11.5 13.5C5.7582 13.5 2.4162 8.85475 1.56454 7.50125ZM11.5 3C9.12195 3 7.1875 5.01856 7.1875 7.5C7.1875 9.98143 9.12195 12 11.5 12C13.878 12 15.8125 9.98144 15.8125 7.5C15.8125 5.01856 13.878 3 11.5 3ZM11.5 10.5C9.91486 10.5 8.625 9.15406 8.625 7.5C8.625 5.84593 9.91486 4.5 11.5 4.5C13.0851 4.5 14.375 5.84594 14.375 7.5C14.375 9.15406 13.0851 10.5 11.5 10.5Z"
                        fill="#4F7793"
                      />
                    </svg>
                  </SvgIcon>
                )}
              </IconButton>
            </InputAdornment>
          ),
          inputProps: {
            sx: {
              "&::placeholder": {
                fontFamily: "Segoe UI Light",
                color: "#4f7793",
                opacity: 1,
                fontSize: "16px",
              },
              paddingLeft: "20px",
            },
          },
        }}
        {...fields}
      />
    </div>
  );
};

export const StyledInputTelephoneField = ({
  placeholder = "",
  label = "",
  readonly = false,
  defaultvalue = "",
  fontFamily = "Segoe UI Bold",
  color = "#2574a9",
  error = "",
  ...fields
}) => {
  return (
    <div className="w-full flex flex-col space-y-2">
      <StyledTypographyInputLabel
        sx={{
          textAlign: "left",
          color: error === "" ? `#${color} !important` : "#EB4C37 !important",
        }}
      >
        {label}
      </StyledTypographyInputLabel>
      <TextField
        error={error !== ""}
        helperText={error}
        placeholder={placeholder}
        sx={{
          width: "100%",
          "&:hover .MuiOutlinedInput-notchedOutline": {
            border:
              error === ""
                ? "2px solid #4f7793 !important"
                : "2px solid red !important",
          },
          "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
            border:
              error === ""
                ? "2px solid #4f7793 !important"
                : "2px solid red !important",
          },
          "& .MuiOutlinedInput-notchedOutline": {
            border:
              error === ""
                ? "2px solid #e6e8ec"
                : "2px solid #EB4C37 !important",
            borderRadius: "8px",
          },
          "& .MuiInputBase-input.MuiOutlinedInput-input": {
            color: color,
            fontFamily: fontFamily,
            background: "#fff",
            borderRadius: "8px",
            fontSize: "18px",
          },
          "& .MuiFormHelperText-root": {
            fontSize: "1rem !important",
            color: "#EB4C37 !important",
            fontFamily: "Segoe UI Light",
          },
          "& .MuiInputBase-root.MuiOutlinedInput-root": {
            // maxWidth: "320px",
          },
        }}
        value={defaultvalue}
        InputProps={{
          readOnly: readonly,
          inputComponent: PhoneInput,
          inputProps: {
            mask: "+{33} 00 00 00 00 00",
            sx: {
              "&::placeholder": {
                color: "#4f7793",
                fontFamily: "Segoe UI Light",
                opacity: 1,
                fontSize: "16px",
              },
              paddingLeft: "20px",
            },
          },
        }}
        {...fields}
      />
    </div>
  );
};
export const StyledInputNumberField = ({ value, setValue }) => {
  const handleIncrease = () => {
    setValue(value + 1);
  };

  const handleDecrease = () => {
    if (value > 0) {
      setValue(value - 1);
    }
  };
  return (
    <TextField
      value={value}
      type="number"
      onChange={(e) => setValue(e.target.value)}
      sx={{
        height: "50px",
        "&:hover .MuiOutlinedInput-notchedOutline": {
          border: "2px solid #4f7793 !important",
        },
        "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
          border: "2px solid #4f7793 !important",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          borderRadius: "8px",
          border: "2px solid #E6E8EC",
        },
        "& .MuiInputBase-input.MuiOutlinedInput-input": {
          height: "unset",
        },
        "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
          {
            display: "none",
          },
        "& .MuiButtonBase-root": {
          padding: 0,
        },
      }}
      InputProps={{
        readOnly: false,
        inputProps: {
          style: {
            textAlign: "center",
            fontSize: "18px",
            fontWeight: "700",
            color: "#2574A9",
          },
        },
        endAdornment: (
          <div className="flex flex-col">
            <IconButton onClick={handleIncrease}>
              <KeyboardArrowUp sx={{ color: "#2574A9" }} />
            </IconButton>

            <IconButton onClick={handleDecrease}>
              <KeyboardArrowDown sx={{ color: "#2574A9" }} />
            </IconButton>
          </div>
        ),
      }}
    />
  );
};

export const StyledInputYearField = ({
  placeholder = "",
  label = "",
  readonly = false,
  defaultvalue = "",
  fontFamily = "Segoe UI Bold",
  color = "#2574A9",
  error = "",
  ...fields
}) => {
  return (
    <div className="w-full flex flex-col space-y-2">
      <StyledTypographyInputLabel
        sx={{
          textAlign: "left",
          color: error === "" ? `${color} !important` : "#EB4C37 !important",
        }}
      >
        {label}
      </StyledTypographyInputLabel>
      <TextField
        error={error !== ""}
        helperText={error}
        placeholder={placeholder}
        sx={{
          width: "100%",
          "&:hover .MuiOutlinedInput-notchedOutline": {
            border:
              error === ""
                ? "2px solid #4f7793 !important"
                : "2px solid red !important",
          },
          "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
            border:
              error === ""
                ? "2px solid #4f7793 !important"
                : "2px solid red !important",
          },
          "& .MuiOutlinedInput-notchedOutline": {
            border:
              error === ""
                ? "2px solid #e6e8ec"
                : "2px solid #EB4C37 !important",
            borderRadius: "8px",
          },
          "& .MuiInputBase-input.MuiOutlinedInput-input": {
            color: color,
            fontFamily: fontFamily,
            fontSize: "18px",
            background: "#fff",
            borderRadius: "8px",
          },
          "& .MuiFormHelperText-root": {
            fontSize: "1rem !important",
            color: "#EB4C37 !important",
            fontFamily: "Segoe UI Light",
          },
          "& .MuiInputBase-root.MuiOutlinedInput-root": {
            // maxWidth: "320px",
          },
        }}
        value={defaultvalue}
        InputProps={{
          readOnly: readonly,
          inputComponent: PhoneInput,
          inputProps: {
            mask: "0000",
            sx: {
              "&::placeholder": {
                color: "#4f7793",
                fontFamily: "Segoe UI Light",
                opacity: 1,
                fontSize: "16px",
              },
              paddingLeft: "20px",
            },
          },
        }}
        {...fields}
      />
    </div>
  );
};
