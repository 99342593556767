import React, { useEffect } from "react";
import { StyledMultilineInput } from "../../Styled/StyledInput";
import { StyledTypographyInputLabel } from "../../Styled/StyledTypography";
import { StyledDropzone } from "../../Styled/StyledDropzone";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { StyledButton, StyledWarningButton } from "../../Styled/StyledButton";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
} from "@mui/material";

const schema = yup.object().shape({
  file: yup.mixed().required("Fichier requis"),
  file1: yup.mixed().required("Fichier requis"),
  file2: yup.mixed().required("Fichier requis"),
  agreement: yup
    .boolean()
    .oneOf([true], "Vous devez accepter les termes et conditions"),
  comment: yup.string(),
});

const PolicyPopup = ({ onClick }) => {
  return (
    <Box
      sx={{
        color: "#2574A9",
        fontFamily: "Segoe UI Semibold",
        fontSize: "16px",
        fontStyle: "normal",
        lineHeight: "normal",
        maxWidth: "685px",
        overflow: "auto",
        marginLeft: "30px",
        transform: "translateY(-36px)",
        background: "#F7F9FA",
        cursor: "pointer",
      }}
      onClick={onClick}
    >
      <p>
        En cochant cette case, je consens expressément au recueil et au
        traitement des données concernant ma vie professionnelle et familiale,
        nécessaires à l’élaboration d’un plan de transition emploi-retraite.
      </p>
      <p>
        Ces données sont traitées dans le respect des dispositions de la loi
        n°78-17 du 6 janvier 1978 relative à l’informatique, aux fichiers et aux
        libertés modifiées dite « Loi Informatique et Libertés » et du Règlement
        Général sur la Protection des Données n°2016-679 du 27 avril 2016 sur la
        Protection des Données (RGPD).
      </p>
      <p>
        Elles sont exclusivement destinées aux personnes dûment habilitées qui
        pourront être d'une part, les collaborateurs de ma mutuelle et d'autre
        part, tout organisme professionnel habilité.
      </p>
      <br />
      <p>
        Je dispose à l’égard du traitement de mes données et auprès de AGEO dont
        le siège social est situé 7 rue de Turbigo paris 75001 :
      </p>
      <p>- Du droit d'accès</p>
      <p>- Du droit de rectification</p>
      <p>- Du droit d’opposition</p>
      <p>- Du droit à la limitation des données à caractère personnel</p>
      <p>
        - Du droit à l’effacement (ou à l’oubli) des données à caractère
        personnel
      </p>
      <p> - Du droit à la portabilité des données à caractère personnel</p>
      <p> - Du droit de retirer àtout moment son consentement</p>
      <p>
        - Du droit de définir des directives relatives au sort des données à
        caractère personnel après son décès.
      </p>
    </Box>
  );
};

export const Step7 = ({ goToPrevStep, onSubmit, data }) => {
  const {
    handleSubmit,
    formState: { errors },
    control,
    getValues,
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      file: data.file || [],
      file1: data.file1 || [],
      file2: data.file2 || [],
      comment: data.comment || "",
      agreement: data.agreement || false,
    },
  });

  useEffect(() => {
    setValue("file", data.file || []);
    setValue("file1", data.file1 || []);
    setValue("file2", data.file2 || []);
    setValue("comment", data.comment || "");
    setValue("agreement", data.agreement || false);
  }, [data, setValue]);

  const { agreement } = watch();

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="">
          <StyledTypographyInputLabel>
            Merci de nous transmettre les justificatifs complémentaires suivant
            :
          </StyledTypographyInputLabel>
        </div>
        <div className="mt-8">
          <div className="flex flex-col gap-y-4">
            <StyledTypographyInputLabel>
              Relevé de Situation Individuelle (RIS / Estimation Indicative
              Globale (EIG)
            </StyledTypographyInputLabel>
            <Controller
              name="file"
              control={control}
              render={({ field }) => (
                <StyledDropzone
                  id="file"
                  onChange={field.onChange}
                  value={field.value}
                  error={errors.file?.message}
                />
              )}
            />
          </div>
        </div>
        <div className="mt-8">
          <div className="flex flex-col gap-y-4">
            <StyledTypographyInputLabel>
              Copie de votre dernier bulletin de salaire :
            </StyledTypographyInputLabel>
            <Controller
              name="file1"
              control={control}
              render={({ field }) => (
                <StyledDropzone
                  id="file1"
                  onChange={field.onChange}
                  value={field.value}
                  error={errors.file1?.message}
                />
              )}
            />
          </div>
        </div>
        <div className="mt-8">
          <div className="flex flex-col gap-y-4">
            <StyledTypographyInputLabel>
              Copie de votre bulletin de salaire de décembre dernier :
            </StyledTypographyInputLabel>
            <Controller
              name="file2"
              control={control}
              render={({ field }) => (
                <StyledDropzone
                  id="file2"
                  onChange={field.onChange}
                  value={field.value}
                  error={errors.file2?.message}
                />
              )}
            />
          </div>
        </div>
        <div className="mt-8">
          <div className="flex flex-col gap-y-4">
            <StyledTypographyInputLabel>
              Souhaitez-vous nous indiquer d’autres éléments ?
            </StyledTypographyInputLabel>
            <Controller
              name="comment"
              control={control}
              render={({ field }) => (
                <StyledMultilineInput
                  id="comment"
                  placeholder="Ecrire un commentaire complémentaire"
                  onChange={field.onChange}
                  value={field.value}
                  error={errors.comment?.message}
                />
              )}
            />
          </div>
        </div>
        <div className="mt-4">
          <Controller
            name="agreement"
            control={control}
            render={({ field }) => (
              <FormControl>
                <FormControlLabel
                  id="agreement"
                  control={
                    <Checkbox
                      checked={field.value}
                      onChange={field.onChange}
                      error={errors.agreement?.message}
                      sx={{
                        "&.Mui-checked .MuiSvgIcon-root": {
                          fill: "#4F7793",
                        },
                        "& .MuiSvgIcon-root": {
                          fill: "#E6E8EC",
                        },
                      }}
                    />
                  }
                  label={
                    <StyledTypographyInputLabel>
                      En cochant cette case, je consens...
                    </StyledTypographyInputLabel>
                  }
                />
              </FormControl>
            )}
          />
        </div>
        <PolicyPopup onClick={() => setValue("agreement", !agreement)} />
        <div className="text-myerror ml-[30px]">{errors.agreement?.message}</div>
        <div className="mt-[5.4vh] flex flex-wrap justify-center gap-x-4 gap-y-4 w-full">
          <div className="w-full sm:w-[320px]">
            <StyledWarningButton
              onClick={() => {
                goToPrevStep(getValues());
              }}
              type="button"
            >
              Précédent
            </StyledWarningButton>
          </div>
          <div className="w-full sm:w-[320px]">
            <StyledButton type="submit">CONTINUER</StyledButton>
          </div>
        </div>
      </form>
    </div>
  );
};
